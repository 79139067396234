<template>
  <div
    class="d-flex flex-column"
    :style="{
      width: fluid || fluidX ? '100%' : width,
      height: fluid || fluidY ? '100%' : height,
    }"
  >
    <div
      class="d-flex align-items-center justify-content-between"
      style="gap: .5rem"
    >
      <div
        class="d-flex align-items-center"
        style="gap: .34rem"
      >
        <label
          v-if="label"
          :for="uid"
        >
          {{ label }}
        </label>

        <sw-icon
          v-if="info"
          v-b-tooltip.hover.v-primary
          :title="info"
          icon="InfoIcon"
          class="mt-n50"
        />
      </div>

      <b-form-checkbox
        v-if="switchEnabled"
        v-model="isEnabled"
        switch
        size="sm"
        class="fix d-flex mr-n25"
      />
    </div>

    <div
      class="swFormInputImage position-relative w-100 h-100"
      :class="!isEnabled && 'disabled'"
    >
      <div
        class="input-container d-flex align-items-center justify-content-center border rounded-lg position-relative overflow-hidden w-100 h-100"
        :style="{ background: background }"
      >
        <b-img-lazy
          v-if="preview"
          :src="preview"
          style="object-fit: contain; position: absolute; width: 100%; height: 100%;"
          :style="`padding: ${p}`"
        />

        <sw-icon
          v-else
          :icon="placeholder"
          :size="placeholderSize"
          style="opacity: .5"
        />

        <b-form-file
          :id="uid"
          plain
          :accept="accept"
          class="position-absolute w-100 h-100 position-top-0 position-left-0"
          :class="isEnabled ? 'cursor-pointer' : 'cursor-not-allowed'"
          :disabled="!isEnabled"
          style="opacity: 0; z-index: 1"
          @input="$event && loadImage($event)"
        />
      </div>

      <b-button
        v-if="clearable && preview && isEnabled"
        variant="danger"
        size="sm"
        class="position-absolute btn-icon p-25"
        style="top: -.34rem; right: -.64rem; z-index: 2;"
        @click="clearImage"
      >
        <sw-icon icon="TrashIcon" />
      </b-button>
    </div>
  </div>
</template>

<script>
import { BFormFile, BImgLazy, VBTooltip } from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'SwFormInputImage',
  components: {
    BFormFile,
    BImgLazy,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    value: {
      type: [Object, String],
      default: '',
    },
    label: {
      type: [String, Object],
      required: false,
      default: '',
    },
    background: {
      type: String,
      required: false,
      default: '#EEE',
    },
    placeholder: {
      type: String,
      required: false,
      default: 'ImageIcon',
    },
    accept: {
      type: String,
      required: false,
      default: '.jpg, .jpeg, .png, .webp',
    },
    placeholderSize: {
      type: String,
      required: false,
      default: '60',
    },
    width: {
      type: String,
      required: false,
      default: '120px',
    },
    height: {
      type: String,
      required: false,
      default: '120px',
    },
    p: {
      type: String,
      required: false,
      default: '1rem',
    },
    maxSize: {
      type: [Number, String],
      required: false,
      default: 1280,
    },
    fluid: {
      type: Boolean,
      required: false,
      default: false,
    },
    fluidX: {
      type: Boolean,
      required: false,
      default: false,
    },
    fluidY: {
      type: Boolean,
      required: false,
      default: false,
    },
    info: {
      type: [Object, String],
      required: false,
      default: '',
    },
    clearable: {
      type: Boolean,
      required: false,
      default: true,
    },
    enabled: {
      type: Boolean,
      required: false,
      default: true,
    },
    switchEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['input', 'changeEnabled'],
  data: () => ({
    uid: '0',
    preview: '',
    isEnabled: true,
  }),
  watch: {
    value(n) {
      if (n) this.preview = typeof n === 'string' ? n : n.base64 || `data:image/webp;base64,${n.preview}`
    },
    isEnabled(n) {
      this.$emit('change-enabled', n)
    },
    enabled(n) {
      this.isEnabled = n
    },
  },
  mounted() {
    if (this.value) this.preview = typeof this.value === 'string' ? this.value : this.value.base64 || `data:image/webp;base64,${this.value.preview}`

    this.isEnabled = this.enabled

    this.isEnabled = this.enabled

    this.uid = String(uuidv4())
  },
  methods: {
    loadImage(file) {
      if (!file) {
        this.$emit('input', null)
        this.setPreview(file)
        return
      }

      const fr = new FileReader()

      if (file.size > this.maxSize * 1000) {
        this.showToast('danger', this.$i18n.tc('MoreThanMb', 1, { size: this.maxSize }))
        return
      }

      fr.onload = () => this.$emit('input', { file, fr, base64: fr.result })

      fr.readAsDataURL(file)

      this.setPreview(file)
    },
    setPreview(file) {
      this.preview = file ? URL.createObjectURL(file) : ''
    },
    clearImage() {
      this.loadImage()
    },
  },
}
</script>
