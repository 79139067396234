// eslint-disable-next-line import/prefer-default-export
export const contactPositionTypes = [
  { value: 'CEO', label: 'ContactPositionTypes.Ceo' },
  { value: 'EXECUTIVE_VICE_PRESIDENT', label: 'ContactPositionTypes.ExecutiveVicePresident' },
  { value: 'GENERAL_MANAGER', label: 'ContactPositionTypes.GeneralManager' },
  { value: 'ATTORNEY', label: 'ContactPositionTypes.Attorney' },
  { value: 'PROXY', label: 'ContactPositionTypes.Proxy' },
  { value: 'OWNER', label: 'ContactPositionTypes.Owner' },
  { value: 'PARTNER', label: 'ContactPositionTypes.Partner' },
  { value: 'ACCOUNTANT', label: 'ContactPositionTypes.Accountant' },

  { value: 'EXECUTIVE_ASSISTANT', label: 'ContactPositionTypes.ExecutiveAssistant' },
  { value: 'SALES_DIRECTOR', label: 'ContactPositionTypes.SalesDirector' },
  { value: 'SALES_MANAGER', label: 'ContactPositionTypes.SalesManager' },
  { value: 'DEPARTMENT_MANAGER', label: 'ContactPositionTypes.DepartmentManager' },
  { value: 'SALESPERSON', label: 'ContactPositionTypes.Salesperson' },
  { value: 'MARKETING_DIRECTOR', label: 'ContactPositionTypes.MarketingDirector' },
  { value: 'EMPLOYEE', label: 'ContactPositionTypes.Employee' },

  { value: 'IT_DIRECTOR', label: 'ContactPositionTypes.ITDirector' },
  { value: 'IT_SPECIALIST', label: 'ContactPositionTypes.ITSpecialist' },
  { value: 'COMMERCIAL_DIRECTOR', label: 'ContactPositionTypes.CommercialDirector' },
  { value: 'OPERATIONAL_DIRECTOR', label: 'ContactPositionTypes.OperationalDirector' },
  { value: 'MANAGING_DIRECTOR', label: 'ContactPositionTypes.ManagingDirector' },

]

export const contactPositionTypeByValue = value => contactPositionTypes.find(type => type.value === value)?.label || ''
