import {
  DELETE_APPLICATION, GET_APPLICATIONS, TEST_APPLICATION, UPDATE_APPLICATION,
} from '@/@constants/mutations'
// eslint-disable-next-line import/no-cycle
import axiosIns from '@/libs/axios'
import * as fields from '@/@constants/fields'
import moment from 'moment/moment'

export default {
  namespaced: true,
  state: {},
  actions: {
    // eslint-disable-next-line no-unused-vars,no-async-promise-executor
    [GET_APPLICATIONS]: ({ commit }, { pagination = null, filters = null, contact = null }) => new Promise(async (resolve, reject) => {
      const params = {
        fields_load: fields.APPLICATIONS,
        orderBy: 'createdAt.desc',
      }

      if (pagination) {
        params.page = pagination.currentPage
        params.limit = pagination.perPage
      }

      if (contact) {
        if (contact.mailAddresses?.length) {
          params['eqArr_1_mailAddress-mail'] = JSON.stringify(contact.mailAddresses.map(email => email?.mail))
          params.eqArr_1_status = 'NEW'
        }

        if (contact.phoneNumbers?.length) {
          params['eqArr_2_phoneNumber-phone'] = JSON.stringify(contact.phoneNumbers.map(phone => phone?.phone))
          params.eqArr_2_status = 'NEW'
        }

        params.eqArr_3_contactThread = JSON.stringify(contact.contactThreads.map(thread => thread.id))
      }

      if (filters?.status) {
        params.eq_status = filters.status
      }

      if (filters?.source) {
        if (filters.type === 'FACEBOOK') {
          params['eq_integrationFacebookWebhook-id'] = filters.source
        } else if (filters.type === 'LEAD_GENERATOR_WIDGET') {
          params['eq_clientChatMessage-clientChat-browserSession-widgetChat-id'] = filters.source
        } else if (filters.type === 'LEAD_GENERATOR_WHATSAPP') {
          params['eq_clientChatMessage-clientChat-integrationWhatsAppSubscriptionPhoneNumber-id'] = filters.source
        } else {
          params['eq_contactApplicationIntegration-id'] = filters.source
        }
        // clientChatMessage.clientChat.chatClient
        // clientChatMessage.clientChat.integrationWhatsAppSubscriptionPhoneNumber
      } else if (filters?.type) {
        if (filters.type === 'FACEBOOK') {
          params.isNotNull_integrationFacebookWebhook = 1
        } else if (filters.type === 'LEAD_GENERATOR_WIDGET') {
          params['isNotNull_clientChatMessage-clientChat-browserSession'] = 1
        } else if (filters.type === 'LEAD_GENERATOR_WHATSAPP') {
          params['isNotNull_clientChatMessage-clientChat-integrationWhatsAppSubscriptionPhoneNumber'] = 1
        } else if (filters.type === 'LEAD_GENERATOR_MOBILE') {
          params['isNotNull_clientChatMessage-clientChat-chatClient'] = 1
        } else {
          params['eq_contactApplicationIntegration-type'] = filters.type
        }
      }

      if (filters?.createdAt) {
        const value = filters.createdAt.dateRange()
        if (value[0]) {
          // eslint-disable-next-line prefer-destructuring
          params.gte_createdAt = moment(value[0], 'DD-MM-YYYY hh:mm:ss').format('YYYY-MM-DD hh:mm:ss')
        }
        if (value[1]) {
          // eslint-disable-next-line prefer-destructuring
          params.lte_createdAt = moment(value[1], 'DD-MM-YYYY hh:mm:ss').format('YYYY-MM-DD hh:mm:ss')
        }
      }

      if (filters?.closed) {
        params.isNotNull_contactApplicationStatusReason = ''
      }

      const statsPayload = JSON.parse(JSON.stringify(params))
      delete statsPayload.page
      delete statsPayload.limit
      const stats = await axiosIns.get('1/contacts/applications/getStatistics', { statsPayload })

      axiosIns.get('1/contacts/applications', { params })
        .then(res => {
          resolve({ ...res.data, stats: stats.data.data })
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [DELETE_APPLICATION]: ({ commit }, id) => new Promise((resolve, reject) => {
      axiosIns.delete('1/contacts/applications', { data: [id] })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),

    // eslint-disable-next-line no-unused-vars
    [UPDATE_APPLICATION]: ({ commit }, data) => new Promise((resolve, reject) => {
      let payload = ''
      if (!Array.isArray(data)) {
        const {
          id, status, contactThread, note, contactApplicationStatusReason = null,
        } = data
        payload = { id }
        if (status) payload.status = status
        if (note) payload.note = note
        if (contactThread) payload.contactThread = contactThread
        if (contactApplicationStatusReason) payload.contactApplicationStatusReason = contactApplicationStatusReason
      } else {
        payload = data.map(({
          id, status, contactThread, note, contactApplicationStatusReason,
        }) => {
          const item = { id }
          if (status) item.status = status
          if (note) item.note = note
          if (contactThread) item.contactThread = contactThread
          if (contactApplicationStatusReason) item.contactApplicationStatusReason = contactApplicationStatusReason

          return item
        })
      }
/* eslint-disable */
      axiosIns.patch('1/contacts/applications', payload)
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),

    [TEST_APPLICATION]: (_, level = 0) => new Promise((resolve, reject) => {
      const params = {}
      params.fields_info = level

      axiosIns.get('1/contacts/applications', { params })
        .then(res => resolve(res.data.data))
        .catch(err => reject(err))
    }),
  },
}

// Test
