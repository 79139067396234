<template>
  <b-card
    no-body
    :class="{'open': visible}"
    @mouseenter="collapseOpen"
    @mouseleave="collapseClose"
  >
    <b-card-header
      :class="{'collapsed': !visible, 'py-0': !isPadding}"
      :aria-expanded="visible ? 'true' : 'false'"
      :aria-controls="collapseItemID"
      role="tab"
      :data-toggle="!arrowLeft ? 'collapse' : ''"
      @click="updateVisible(!visible)"
    >
      <slot name="header">
        <div class="w-100 d-flex flex-wrap align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <feather-icon
              v-if="arrowLeft && !off"
              icon="ChevronDownIcon"
              size="14"
              class="mr-50 text-secondary"
              style="transition: transform .14s linear"
              :style="visible ? 'transform: rotateZ(180deg)' : ''"
            />

            <div class="lead collapse-title">
              <template v-if="isTitle">
                <div v-if="!$slots.title">
                  {{ title }}
                </div>

                <div
                  v-else-if="$slots.title"
                  class="w-100"
                  :class="{ 'ml-1': off && arrowLeft }"
                >
                  <slot name="title" />
                </div>
              </template>

              <small
                v-if="subTitle || $slots.subTitle"
                class="w-100"
              >
                <slot name="subTitle" />
              </small>
            </div>
          </div>

          <div v-if="showActions">
            <b-button
              v-if="showDownload"
              class="mr-25 mt-25 btn-icon"
              variant="flat-primary"
              @click.stop="$emit('download')"
            >
              <feather-icon icon="DownloadIcon" />
            </b-button>

            <b-button
              v-if="showEdit"
              class="mr-25 mt-25 btn-icon"
              variant="flat-warning"
              @click.stop="$emit('edit')"
            >
              <feather-icon icon="EditIcon" />
            </b-button>

            <b-button
              v-if="showCopy"
              class="mr-25 mt-25 btn-icon"
              variant="flat-primary"
              @click.stop="$emit('copy')"
            >
              <feather-icon icon="CopyIcon" />
            </b-button>

            <b-button
              v-if="showDelete"
              class="mt-25 btn-icon"
              variant="flat-danger"
              @click.stop="$emit('delete')"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </div>
        </div>
      </slot>
    </b-card-header>
    <b-collapse
      :id="collapseItemID"
      v-model="visible"
      :accordion="accordion"
      role="tabpanel"
      :lazy="lazy"
      :class="{ 'py-25': !isPadding }"
    >
      <b-card-body>
        <div v-show="visible || forceShow">
          <slot />
        </div>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCollapse, VBTooltip,
} from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCollapse,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    lazy: {
      type: Boolean,
      default: false,
    },
    forceShow: {
      type: Boolean,
      default: false,
    },
    showActions: {
      type: Boolean,
      default: false,
    },
    showEdit: {
      type: Boolean,
      default: true,
    },
    showCopy: {
      type: Boolean,
      default: true,
    },
    showDownload: {
      type: Boolean,
      default: false,
    },
    showDelete: {
      type: Boolean,
      default: true,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    subTitle: {
      type: Boolean,
      default: false,
      required: false,
    },
    arrowLeft: {
      type: Boolean,
      required: false,
      default: false,
    },
    forceState: {
      type: Boolean,
      required: false,
      default: false,
    },
    off: {
      type: Boolean,
      required: false,
      default: false,
    },
    isPadding: {
      type: Boolean,
      required: false,
      default: true,
    },
    isTitle: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      visible: false,
      collapseItemID: '',
      openOnHover: this.$parent.hover,
    }
  },
  computed: {
    accordion() {
      return this.$parent.accordion ? `accordion-${this.$parent.collapseID}` : null
    },
  },
  watch: {
    forceState(n) {
      this.updateVisible(n)
    },
  },
  created() {
    this.collapseItemID = uuidv4()
    this.visible = this.isVisible
  },
  methods: {
    updateVisible(val = true) {
      this.visible = val

      if (this.off) this.visible = false

      this.$emit('visible', val)
    },
    collapseOpen() {
      if (this.openOnHover) this.updateVisible(true)
    },
    collapseClose() {
      if (this.openOnHover) this.updateVisible(false)
    },
  },
}
</script>
