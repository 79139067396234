// eslint-disable-next-line no-unused-vars,import/no-cycle
import axiosIns from '@/libs/axios'
import {
  CLOSE_MODAL,
  CREATE_MAIL,
  CREATE_NOTE,
  CREATE_ORGANIZATION,
  CREATE_REPORT,
  CREATE_SMS,
  CREATE_TASK,
  HIDE_MODAL,
  OPEN_MODAL,
  RELOAD_CONTENT,
  SET_DEFAULT_STATE,
  SET_THREAD_ID,
  SET_TOAST_ITEM_PROGRESS,
  SHOW_UPLOAD_TOAST,
  UPDATE_ORGANIZATION,
} from '../../@constants/mutations'

const defaultState = {
  contactId: null,
  threadId: null,
  thread: null,
  searchId: null,
  isModalOpen: false,
  isAssignedContactsEdit: false,
  modalType: '',
  data: null,
  editedData: null,
  config: {
    title: '',
    okButtonTitle: '',
    size: '',
    bodyclass: '',
    modalClass: '',
  },
  callback: () => {},
  reloadContent: false,
}

export default {
  namespaced: true,
  state: { ...defaultState },
  getters: {
    getModalState: state => state,
    getModalData: state => state.data,
    getReloadContent: state => state.reloadContent,
  },
  mutations: {
    [RELOAD_CONTENT](state, payload) {
      state.reloadContent = payload
    },
    SET_DATA(state, payload) {
      state.data = payload
    },
    [SET_DEFAULT_STATE](state) {
      Object.assign(state, defaultState)
    },
    [SET_THREAD_ID](state, threadId) {
      state.threadId = threadId
    },
    [OPEN_MODAL](state, payload) {
      const {
        id, modalType, modalTitle, okButtonTitle, threadFor, data, editedData, thread, modalClass, size, contactId, allowThreads = [], callback, isAssignedContactsEdit,
      } = payload

      if (id) state.searchId = id
      if (data) state.data = data
      if (editedData) state.editedData = editedData
      if (contactId) state.contactId = contactId
      if (threadFor) state.threadId = threadFor
      if (thread) state.thread = thread
      if (callback) state.callback = callback
      if (allowThreads.length) state.allowThreads = allowThreads
      if (isAssignedContactsEdit) state.isAssignedContactsEdit = isAssignedContactsEdit

      state.modalType = modalType
      state.config = {
        title: modalTitle,
        okButtonTitle,
        size: modalType === 'shortcodes' || size === 'lg' ? 'lg' : 'md',
        bodyclass: modalType === 'mail' ? 'p-0' : (modalType || ''),
        modalClass: modalClass || '',
      }
      state.isModalOpen = true
    },
    [CLOSE_MODAL](state) {
      state.searchId = null
      state.isModalOpen = false
      state.modalType = null
      state.data = null
      state.callback = () => {}
      state.editedData = null
      state.config = {
        title: '',
        okButtonTitle: '',
        size: '',
        bodyclass: '',
      }
      state.allowThreads = []
      state.reloadContent = false
      state.contactId = ''
      state.threadId = ''
      state.thread = ''
    },
    [HIDE_MODAL](state) {
      state.isModalOpen = false
    },
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    [CREATE_ORGANIZATION]: ({ commit }, payload) => new Promise((resolve, reject) => {
      axiosIns.post('1/organizations', payload)
        .then(res => {
          const data = { id: res.data.data.items[0].id, ...payload }
          commit('RELOAD_CONTENT', true)
          commit('SET_DATA', data)
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
        .finally(() => commit(SET_DEFAULT_STATE))
    }),
    // eslint-disable-next-line no-unused-vars
    [UPDATE_ORGANIZATION]: ({ commit }, payload) => new Promise((resolve, reject) => {
      axiosIns.patch('1/organizations', payload)
        .then(res => {
          const data = { ...payload }
          commit('RELOAD_CONTENT', true)
          commit('SET_DATA', data)
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
        .finally(() => commit(SET_DEFAULT_STATE))
    }),
    [CREATE_NOTE]: ({ commit }, payload) => new Promise((resolve, reject) => {
      const { threadId, noteData } = payload
      if (noteData.id) {
        axiosIns.patch(`1/contacts/threads/${threadId}/notes`, noteData)
          .then(() => {
            commit('RELOAD_CONTENT', true)
            resolve(threadId)
          })
          .catch(err => reject(err))
          .finally(() => commit(SET_DEFAULT_STATE))
      } else {
        axiosIns.post(`1/contacts/threads/${threadId}/notes`, noteData)
          .then(() => {
            commit('RELOAD_CONTENT', true)
            resolve(threadId)
          })
          .catch(err => reject(err))
          .finally(() => commit(SET_DEFAULT_STATE))
      }
    }),
    [CREATE_TASK]: ({ commit }, payload) => new Promise((resolve, reject) => {
      const { threadId, taskData } = payload
      const task = taskData
      task.status = 'O'
      if (task.id) {
        axiosIns.patch(threadId ? `1/contacts/threads/${threadId}/tasks` : '1/contacts/threads/tasks', task)
          .then(() => {
            commit('RELOAD_CONTENT', true)
          })
          .catch(err => reject(err))
          .finally(() => commit(SET_DEFAULT_STATE))
      } else {
        axiosIns.post(threadId ? `1/contacts/threads/${threadId}/tasks` : '1/contacts/threads/tasks', task)
          .then(() => {
            commit('RELOAD_CONTENT', true)
            resolve(threadId)
          })
          .catch(err => reject(err))
          .finally(() => commit(SET_DEFAULT_STATE))
      }
    }),
    // eslint-disable-next-line no-unused-vars
    [CREATE_REPORT]: ({ commit }, payload) => new Promise((resolve, reject) => {
      const { report, threadId, data } = payload
      const {
        id, statusValue,
      } = data

      const task = {
        id,
        status: statusValue,
        contactThreadTaskReport: { content: report.content, files: report.files },
      }

      axiosIns.patch(threadId ? `1/contacts/threads/${threadId}/tasks/changeStatus` : '1/contacts/threads/tasks/changeStatus', task)
        .then(() => {
          commit('RELOAD_CONTENT', true)
          resolve()
        })
        .catch(err => {
          reject(err)
        })
        .finally(() => commit(SET_DEFAULT_STATE))
    }),
    [CREATE_SMS]: ({ commit }, payload) => new Promise((resolve, reject) => {
      const { threadId, smsData, contactId } = payload
      axiosIns.post(`1/contacts/${contactId}/threads/${threadId}/smses`, smsData)
        .then(() => {
          commit('RELOAD_CONTENT', true)
          resolve(threadId)
        })
        .catch(err => {
          reject(err)
        })
        .finally(() => commit(SET_DEFAULT_STATE))
    }),
    // eslint-disable-next-line no-unused-vars
    [CREATE_MAIL]: ({ commit, dispatch, getters }, payload) => new Promise((resolve, reject) => {
      // eslint-disable-next-line no-unused-vars
      const { threadId, emailData } = payload

      const formData = new FormData()
      // eslint-disable-next-line
      for (const field in emailData) {
        if (field !== 'files' && !Array.isArray(emailData[field])) formData.append(field, emailData[field])
        else if (Array.isArray(emailData[field])) {
          // eslint-disable-next-line
          for (const u of emailData[field]) {
            formData.append(`${field}[]`, u)
          }
        }
      }

      // eslint-disable-next-line no-unreachable
      let config = {}
      if (emailData.files.length) {
        const controlIndex = `${emailData.files.length}_${emailData.files[0].size}_email_${Math.round(Math.random() * 10)}`
        dispatch(`uploader/${SHOW_UPLOAD_TOAST}`, { files: emailData.files, controlIndex }, { root: true })

        config = {
          onUploadProgress(progressEvent) {
            const uploaderPayload = {
              progress: Math.round((progressEvent.loaded * 100) / progressEvent.total),
              controlIndex,
            }
            dispatch(`uploader/${SET_TOAST_ITEM_PROGRESS}`, uploaderPayload, { root: true })
          },
        }
        // eslint-disable-next-line no-unreachable
        // emailData.files.forEach(file => {
        //   formData.append('files[]', file, file.name)
        // })
      }

      // eslint-disable-next-line no-unreachable
      axiosIns.post(`1/contacts/threads/${threadId}/conversations/mails`, formData, config)
        .then(() => {
          commit('RELOAD_CONTENT', true)
          resolve(threadId)
        })
        .catch(err => reject(err))
        .finally(() => {
          commit(SET_DEFAULT_STATE)
          commit(`uploader/${SET_DEFAULT_STATE}`, {}, { root: true })
        })
    }),
  },
}
