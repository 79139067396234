<!-- TODO:Card Info -->
<template>
  <b-card
    no-body
    footer-class="px-75 py-50 d-flex align-items-center justify-content-end"
  >
    <b-card-header
      class="text-white px-75 py-25"
      :style="`background-image: linear-gradient(to right, var(--primary) 40%, ${color === 'default' ? 'var(--primary)' : color})`"
    >
      <div
        class="d-flex align-items-center"
        style="gap: .5rem"
        :style="{ opacity: enabled ? 1 : .6 }"
      >
        {{ title | truncate(24) }}

        <!--          <sw-icon-->
        <!--            v-if="!item.connected"-->
        <!--            class="mt-n25"-->
        <!--            icon="AlertTriangleIcon"-->
        <!--          />-->
      </div>

      <!--    START::Toolbar    -->
      <div
        class="d-flex align-items-center"
        style="gap: .34rem"
      >
        <b-button-group>
          <b-button
            v-b-tooltip.hover.v-primary
            variant="flat-light"
            size="sm"
            class="p-25 btn-icon"
            :title="$t('ConnectedDomains')"
            :disabled="loading"
          >
            <sw-icon icon="ListIcon" />
          </b-button>

          <b-button
            v-b-tooltip.hover.v-primary
            variant="flat-light"
            size="sm"
            class="p-25 btn-icon"
            :title="$t('CheckConnection')"
            :disabled="loading"
          >
            <sw-icon icon="RefreshCwIcon" />
          </b-button>
        </b-button-group>

        <b-button-group>
          <b-button
            v-b-tooltip.hover.v-success
            variant="flat-light"
            size="sm"
            class="p-25 btn-icon"
            :title="$t('Edit')"
            :disabled="loading"
          >
            <sw-icon icon="Edit2Icon" />
          </b-button>

          <b-button
            v-b-tooltip.hover.v-danger
            variant="flat-light"
            size="sm"
            class="p-25 btn-icon"
            :title="$t('Delete')"
            :disabled="loading"
          >
            <sw-icon icon="TrashIcon" />
          </b-button>
        </b-button-group>
      </div>
      <!--    END::Toolbar    -->
    </b-card-header>

    <slot />

    <template #footer>
      <div class="d-flex align-items-center justify-content-between w-100">
        <div>
          <b-spinner
            v-if="reload"
            small
            variant="primary"
          />
        </div>

        <div
          v-if="item.createdAt"
          v-b-tooltip.hover.v-primary
          class="d-flex align-items-center"
          style="gap: .34rem"
          :title="$t('CreatedTime')"
        >
          <sw-icon
            icon="ClockIcon"
            style="margin-top: -.1rem"
          />

          {{ item.createdAt.date | dateFormat }}
        </div>
      </div>
    </template>
  </b-card>
</template>

<script>
import { BButtonGroup, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: { BButtonGroup },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    enabled: {
      type: Boolean,
      required: false,
      default: true,
    },
    color: {
      type: String,
      required: false,
      default: 'default',
    },
    item: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    reload: false,
  }),
}
</script>
