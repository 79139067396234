/* eslint-disable */

import Vue from 'vue'
// eslint-disable-next-line import/no-cycle
import axiosIns from '@/libs/axios'
import {
  CREATE_THREAD_OFFER,
  GET_OFFERS,
  GET_THREAD_OFFER,
  GET_THREAD_OFFERS,
  GET_USER_CART, SAVE_OFFER_AGREEMENT, UPDATE_OFFER, GET_OFFER, GET_THREAD_STATISTICS,
  SAVE_CONTACT_OFFER_AGREEMENT,
  GET_CONTACT_OFFER_AGREEMENT,
  DELETE_CONTACT_OFFER_AGREEMENT, GET_THREAD_ORDERS, GET_ORDERS
} from '@/@constants/mutations'

import * as fields from '@/@constants/fields'
import {isNumber} from "@vueuse/core";

const prepareDataFields = (date, params, field) => {
  const value = date.dateRange()
  if (value[0]) {
    params[`gte_${field}`] = `${value[0]} 00:00:00`
  }
  if (value[1]) {
    params[`lte_${field}`] = `${value[1]} 23:59:59`
  }
}

const resolveParams = (params, search, pagination, filters = {}, sorter = null) => {
  if (pagination) {
    params.page = pagination.currentPage
    params.limit = pagination.perPage
  }

  if (sorter) {
    const sortDir = sorter?.sortDesc ? 'desc' : 'asc'
    const sortBy = sorter?.sortBy || 'id'
    params.orderBy = `${sortBy}.${sortDir}`
  }

  if (filters) {
    if (filters.createdBy) params.eq_createdBy = typeof filters.createdBy === 'object' ? filters.createdBy.id : filters.createdBy
    if (filters.createdAt) {
      prepareDataFields(filters.createdAt, params, 'createdAt')
    }
    if (filters.orderDate) {
      prepareDataFields(filters.orderDate, params, 'orderDate')
    }
    if (filters.sendTime) {
      prepareDataFields(filters.sendTime, params, 'contactThreadCartOfferMails-lastMail-createdAt')
      // prepareDataFields(filters.sendTime)
    }
    if (filters.agreementSignedAt) {
      prepareDataFields(filters.agreementSignedAt, params, 'contactThreadCartBox-contactThreadAgreements-signedAt')
      // prepareDataFields(filters.sendTime)
    }
    if (filters.paymentMethod) {
      if(filters.paymentMethod === 'loyaltyPlan') {
        params['eq_contactThreadCartBox-loyaltyPlanPoints-status'] = 'POINTS_PURCHASE'
      } else {
        params['eq_contactThreadCartBox-paymentType'] = filters.paymentMethod.toUpperCase()
      }
    }

    if (filters.status) {
      params.eq_status = filters.status

      if (filters.status === 'EXPIRED') {
        params.eq_status = 'NEW'
        params['lt_expiredAt'] = new Date()
      }
    }

    if (filters.assignedUsers && filters.assignedUsers.length) {
      params['eqArr_contactThreadCartBox-contactThread-assignedUsers-id'] = JSON.stringify(
        filters.assignedUsers.map(user => (typeof user === 'object' ? user.id : user)),
      )
    }

    if (filters.withoutMessages === 'sent') {
      params['isNotNull_contactThreadCartOfferMails-id'] = ''
    } else if (filters.withoutMessages === 'not_sent') {
      params['isNull_contactThreadCartOfferMails-id'] = ''
    }

    if (filters?.contacts && filters.contacts.length) {
      params['eqArr_contactThreadCartBox-contactThread-contact-id'] = JSON.stringify(filters.contacts)
    }

    if (filters?.funnelPoint) {
      params['eq_contactThreadCartBox-contactThread-contactThreadFunnelProcesses-contactThreadFunnelTypePoint-id'] = filters.funnelPoint
    }
    if (filters?.funnel) {
      params['eq_contactThreadCartBox-contactThread-contactThreadFunnelProcesses-contactThreadFunnelTypePoint-contactThreadFunnelType-id'] = filters.funnel
    }
    if (filters?.thread) {
      params['eq_contactThreadCartBox-contactThread-id'] = filters.thread
    }
  }


  if (search && search.length) {
    const searchString = search.replaceAll(' ', '%')
    params.search = `%${searchString}%`
    params.search_fields = 'contactThreadCartBox-name,number,contactThreadCartBox-contactThread-contact-firstName,contactThreadCartBox-contactThread-contact-lastName'
  }
}

export default {
  namespaced: true,
  state: () => ({}),
  getters: {},
  mutations: {},
  actions: {
    [CREATE_THREAD_OFFER]: ({ commit, dispatch }, { cart, priceGrossEnd, status = 'NEW' }) => new Promise((resolve, reject) => {
      const payload = {
        contactThreadCart: cart?.id,
        // priceGrossEnd: parseFloat(priceGrossEnd),
        status,
      }
      axiosIns.post(`1/contacts/threads/carts/offers`, payload)
        .then(res => {
          dispatch(`cart/${GET_USER_CART}`, null, { root: true })
          resolve({ data: res.data, thread: cart.contactThread })
        })
        .catch(err => {
          reject(err)
        })
    }),
    [GET_OFFER]: ({ commit }, id) => new Promise((resolve, reject) => {
      const params = {
        fields_load: fields.OFFERS,
        orderBy: 'createdAt.desc',
      }

      axiosIns.get(`1/contacts/threads/carts/offers/${id}`, { params })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    GET_ORDER: ({ commit }, id) => new Promise((resolve, reject) => {
      const params = {
        fields_load: fields.ORDERS,
        orderBy: 'createdAt.desc',
      }

      axiosIns.get(`1/contacts/threads/carts/orders/${id}`, { params })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    [GET_ORDERS]: ({ commit }, { pagination, search, filters, sorter }) => new Promise((resolve, reject) => {
      const params = {
        fields_load: fields.ORDERS,
        orderBy: 'createdAt.desc',
      }

      resolveParams(params, search, pagination, filters, sorter)

      axiosIns.get(`1/contacts/threads/carts/orders`, { params })
        .then(res => {
          // console.log(res.data.data.items, 'offers')
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    [GET_THREAD_ORDERS]: ({ commit }, { threadId, pagination, search, filters, sorter }) => new Promise((resolve, reject) => {
      const params = {
        fields_load: fields.ORDERS,
        orderBy: 'createdAt.desc',
        'eq_contactThreadCartBox-contactThread-id': threadId,
      }

      resolveParams(params, search, pagination, filters, sorter)

      axiosIns.get(`1/contacts/threads/carts/orders`, { params })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    [GET_OFFERS]: ({ commit }, { pagination, search, filters, sorter }) => new Promise(async (resolve, reject) => {
      const params = {
        fields_load: fields.OFFERS,
        orderBy: 'createdAt.desc',
      }

      resolveParams(params, search, pagination, filters, sorter)

      axiosIns.get(`1/contacts/threads/carts/offers`, { params })
        .then(res => {
          // console.log(res.data.data.items, 'offers')
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    [GET_THREAD_OFFERS]: ({ commit }, { threadId, pagination, search, filters, sorter }) => new Promise(async (resolve, reject) => {
      const params = {
        fields_load: fields.OFFERS,
        orderBy: 'createdAt.desc',
        'eq_contactThreadCartBox-contactThread-id': threadId,
      }

      resolveParams(params, search, pagination, filters, sorter)

      axiosIns.get(`1/contacts/threads/carts/offers`, { params })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    [GET_THREAD_STATISTICS]: ({ commit }, { pagination, search, filters }) => new Promise((resolve, reject) => {
      const params = {
        fields_load: fields.OFFERS,
        orderBy: 'createdAt.desc',
      }

      resolveParams(params, search, pagination, filters)

      axiosIns.get(`1/contacts/threads/carts/offers/getStatisticsByStatuses`, { params })
        .then(res => res.data)
        .then(res => res.data)
        .then(res => {
          res.map(el => {
            let sum = 0

            el.totalGrossPrice.forEach(price => {
              sum += Number(price['1'])
            })

            el.totalGrossPrice = sum
          })

          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    }),
    [UPDATE_OFFER]: ({ commit }, offer) => new Promise((resolve, reject) => {
      axiosIns.patch(`1/contacts/threads/carts/offers`, offer)
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    [GET_THREAD_OFFER]: ({ commit }, { threadId, offerId }) => new Promise((resolve, reject) => {
      const params = {
        fields_load: fields.OFFERS,
      }
      axiosIns.get(`1/contacts/threads/${threadId}/carts/offers/${offerId}`, { params })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    [SAVE_OFFER_AGREEMENT]: ({ commit }, { threadId, contactId, offerId, agreementId, signerUser, anonymised, contactThreadAgreementPaymentScheduleItems, allowElectronicSignature }) => new Promise((resolve, reject) => {
      // const payload = agreementId.map(id => ({ agreementTemplate: id }))
      const payload = { agreementTemplate: agreementId, signerUser, anonymised, contactThreadAgreementPaymentScheduleItems, allowElectronicSignature }

      axiosIns.post(`1/contacts/${contactId}/threads/${threadId}/carts/offers/${offerId}/agreements`, payload)
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    [SAVE_CONTACT_OFFER_AGREEMENT]: ({ commit }, { threadId, value, contactThreadAgreement, contactId, agreementId, signerUser, anonymised, contactThreadAgreementPaymentScheduleItems, allowElectronicSignature, status }) => new Promise((resolve, reject) => {
      const payload = { agreementTemplate: agreementId, signerUser, anonymised, contactThreadAgreementPaymentScheduleItems, allowElectronicSignature }

      if (contactThreadAgreement)
        payload.contactThreadAgreement = contactThreadAgreement

      if (status) payload.status = status

      payload.value = parseFloat(value || 0)

      axiosIns.post(`1/contacts/${contactId}/threads/${threadId}/agreements`, payload)
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    [GET_CONTACT_OFFER_AGREEMENT]: ({ commit }, { threadId, contactId, pagination = null, filters = null  }) => new Promise((resolve, reject) => {
      const params = {
        fields_load: fields.AGREEMENTS,
        orderBy: 'createdAt.desc',
      }

      resolveParams(params, pagination, filters)

      axiosIns.get(`1/contacts/${contactId}/threads/${threadId}/agreements`, { params })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    [DELETE_CONTACT_OFFER_AGREEMENT]: ({ commit }, { threadId, contactId, agreementId }) => new Promise((resolve, reject) => {
      axiosIns.delete(`1/contacts/${contactId}/threads/${threadId}/agreements`, { data: JSON.stringify([agreementId]) })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
}
