var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('div',{staticClass:"d-flex align-items-center"},[_c('h4',[_vm._v(_vm._s(_vm.$t('DeliveryAddresses')))]),(!_vm.isList)?_c('b-button',{staticClass:"mb-50 ml-50",attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){_vm.editedItem = { city: '', country: '', number: '', postalCode: '', street: '', index: -1 }; _vm.isModalOpen = true}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}}),_vm._v(" "+_vm._s(_vm.$t('Add'))+" ")],1):_vm._e()],1),(!_vm.isPrimaryCopied || !_vm.isCorrespondenceCopied)?_c('b-alert',{attrs:{"show":"","variant":"primary"}},[_c('div',{staticClass:"alert-body"},[_c('div',{staticClass:"d-flex flex-column",staticStyle:{"row-gap":".5rem"}},[_c('div',{staticClass:"mb-25 font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.$t('UsebasicContAddressesesesasDelivereddress'))+" ")]),_c('div',{staticClass:"d-flex flex-column flex-md-row",staticStyle:{"row-gap":".5rem","column-gap":".5rem"}},[_c('b-button',{attrs:{"variant":"outline-primary","size":"sm","disabled":_vm.isPrimaryCopied},on:{"click":function($event){return _vm.copyAddress('default')}}},[_vm._v(" "+_vm._s(_vm.$t('PrimaryAddress'))+" ")]),_c('b-button',{attrs:{"variant":"outline-primary","size":"sm","disabled":_vm.isCorrespondenceCopied},on:{"click":function($event){return _vm.copyAddress('correspondence')}}},[_vm._v(" "+_vm._s(_vm.$t('AddressForCorrespondence'))+" ")])],1)])])]):_vm._e()],1),_c('b-table',{staticStyle:{"min-height":"unset !important"},attrs:{"items":_vm.addresses,"fields":_vm.fields,"striped":"","show-empty":"","responsive":"","hover":""},scopedSlots:_vm._u([{key:"head()",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(("" + label)))+" ")]}},{key:"cell(type)",fn:function(ref){
var value = ref.value;
return [_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.right",modifiers:{"right":true}}],staticStyle:{"max-width":"25px"},attrs:{"title":_vm.$t(_vm.typeLabel(value))}},[_c('feather-icon',{staticClass:"text-primary",attrs:{"icon":_vm.typeIcon(value)}})],1)]}},{key:"cell(action)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('b-button',{attrs:{"size":"sm","variant":"warning"},on:{"click":function($event){_vm.editedItem = Object.assign({}, item, {index: index}); _vm.isModalOpen = true}}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}}),_vm._v(" "+_vm._s(_vm.$t('Edit'))+" ")],1),_c('b-button',{staticClass:"ml-25",attrs:{"size":"sm","variant":"danger"},on:{"click":function($event){return _vm.removeAddress(index)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}}),_vm._v(" "+_vm._s(_vm.$t('Delete'))+" ")],1)]}},{key:"empty",fn:function(){return [_c('div',{staticClass:"text-center py-1 text-primary font-weight-bold"},[_c('feather-icon',{attrs:{"size":"17","icon":"XCircleIcon"}}),_vm._v(" "+_vm._s(_vm.$t('NoData'))+" ")],1)]},proxy:true}])}),_c('b-modal',{attrs:{"visible":_vm.isModalOpen,"hide-footer":"","title":_vm.$t('DeliveryAddresses')},on:{"hidden":function($event){_vm.isModalOpen = false; _vm.editedItem = null}}},[(_vm.editedItem && _vm.isModalOpen)?_c('validation-observer',{ref:"edit-address"},[_c('validation-provider',{attrs:{"name":_vm.$t('Country'),"rules":'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Country')}},[_c('b-form-input',{attrs:{"id":"basicInput","state":errors.length > 0 ? false:null},model:{value:(_vm.editedItem.country),callback:function ($$v) {_vm.$set(_vm.editedItem, "country", $$v)},expression:"editedItem.country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3795461709)}),_c('validation-provider',{attrs:{"name":_vm.$t('City'),"rules":'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('City')}},[_c('b-form-input',{attrs:{"id":"basicInput","state":errors.length > 0 ? false:null},model:{value:(_vm.editedItem.city),callback:function ($$v) {_vm.$set(_vm.editedItem, "city", $$v)},expression:"editedItem.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3785548045)}),_c('validation-provider',{attrs:{"name":_vm.$t('contact.postalCode'),"rules":'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('contact.postalCode'),"state":errors.length > 0 ? false:null}},[_c('b-form-input',{staticClass:"form-control",attrs:{"id":"basicInput","state":errors.length > 0 ? false:null},model:{value:(_vm.editedItem.postalCode),callback:function ($$v) {_vm.$set(_vm.editedItem, "postalCode", $$v)},expression:"editedItem.postalCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3320807114)}),_c('validation-provider',{attrs:{"name":_vm.$t('Street'),"rules":'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Street')}},[_c('b-form-input',{attrs:{"id":"basicInput","state":errors.length > 0 ? false:null},model:{value:(_vm.editedItem.street),callback:function ($$v) {_vm.$set(_vm.editedItem, "street", $$v)},expression:"editedItem.street"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,597206925)}),_c('validation-provider',{attrs:{"name":_vm.$t('BuildingNumber'),"rules":'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('BuildingNumber')}},[_c('b-form-input',{attrs:{"id":"basicInput","state":errors.length > 0 ? false:null},model:{value:(_vm.editedItem.number),callback:function ($$v) {_vm.$set(_vm.editedItem, "number", $$v)},expression:"editedItem.number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,967824219)})],1):_vm._e(),_c('b-button',{attrs:{"size":"sm","variant":"primary"},on:{"click":_vm.editAddress}},[_vm._v(" "+_vm._s(_vm.$t('Confirm'))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }