<template>
  <div
    class="d-flex align-items-center flex-wrap"
    style="gap: .34rem"
  >
    <div
      class="d-flex align-items-center flex-nowrap"
      style="gap: .5rem"
    >
      <sw-icon icon="fa-align-center" />

      <strong>
        {{ $t('FilterBy') }}:
      </strong>
    </div>

    <template v-for="(filter, i) in value">
      <b-button
        v-if="filter.type === 'SERACH'"
        :key="i"
        variant="flat-primary"
        size="sm"
        class="p-0 px-25"
        style="cursor: default"
      >
        <b-input-group class="filters-search:modern">
          <b-input-group-prepend>
            <sw-icon icon="SearchIcon" />
          </b-input-group-prepend>

          <b-form-input
            v-model="search"
            :placeholder="`${$t('Search')}…`"
            @change="$forceUpdate(); $emit('input', value); $emit('search', search)"
          />
        </b-input-group>
      </b-button>

      <template v-else-if="filter.type === 'CHECK'">
        <div :key="i">
          <b-button
            :id="`filter-${i}`"
            variant="flat-primary"
            size="sm"
            class="p-25"
          >
            {{
              $t(
                filter.options
                  .filter(option => !option.requiredViews || checkRequiredViewPermissions(option.requiredViews))
                  .filter(option => !option.requiredModule || checkRequiredModule(option.requiredModule))
                  .filter(option => !option.requiredPermissions || checkRequiredPermissions(option.requiredPermissions))
                  .find(({ active }) => !active)
                  ? 'Selected' : 'All'
              )
            }}

            <sw-icon icon="ChevronDownIcon" />
          </b-button>

          <b-popover
            :target="`filter-${i}`"
            triggers="focus"
            custom-class="p-0"
            placement="bottom"
          >
            <b-list-group
              flush
              class="max-h-24 overflow-auto"
            >
              <b-list-group-item
                v-for="(option, j) in filter.options
                  .filter(option => !option.requiredViews || checkRequiredViewPermissions(option.requiredViews))
                  .filter(option => !option.requiredModule || checkRequiredModule(option.requiredModule))
                  .filter(option => !option.requiredPermissions || checkRequiredPermissions(option.requiredPermissions))"
                :key="j"
                class="d-flex align-items-center justify-content-between cursor-pointer"
                @click.prevent="option.active = !option.active; $forceUpdate(); $emit('input', value); $emit('change', filter)"
              >
                <b-form-checkbox
                  :checked="option.active"
                  :readonly="option.readonly"
                >
                  <span class="cursor-pointer">
                    {{ option.title }}
                  </span>
                </b-form-checkbox>
              </b-list-group-item>
            </b-list-group>
          </b-popover>
        </div>
      </template>

      <b-dropdown
        v-else
        :key="i"
        :text="`${filter.options.find(({ active }) => active).title}`"
        size="sm"
        right
        variant="flat-primary"
        toggle-class="p-25 pr-75"
        menu-class="max-h-24 overflow-auto"
      >
        <template v-if="filter.type === 'RADIO'">
          <b-dropdown-item
            v-for="(option, j) in filter.options"
            :key="j"
            :disabled="option.active"
            link-class="d-flex align-items-center justify-content-between"
            @click="filter.options.find(({ active }) => active).active = false; option.active = true; $forceUpdate(); $emit('input', value); $emit('change', filter)"
          >
            {{ option.title }}
          </b-dropdown-item>
        </template>
      </b-dropdown>
    </template>
  </div>
</template>

<script>
import { BPopover } from 'bootstrap-vue'

export default {
  components: { BPopover },
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
  emits: ['input', 'change', 'search'],
  data: () => ({
    search: '',
  }),
}
</script>
