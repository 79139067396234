<template>
  <validation-observer
    ref="newContactFieldForm"
    v-slot="{ invalid }"
    tag="form"
  >
    <!--      New Filed: Name      -->
    <validation-provider
      v-slot="{ errors }"
      rules="required"
      :name="$t('Name')"
    >
      <b-form-group class="mb-0">
        <sw-select :name="$t('Name')">
          <v-select
            v-model="contactAddonFieldType"
            :placeholder="$t('Name')"
            :options="addonFieldTypes"
            :filterable="false"
            label="name"
            item-value="id"
            item-text="name"
            :state="errors.length > 0 ? false:null"
            @input="clearField"
          >
            <template #no-options>
              {{ $t('NoOptions') }}
            </template>
          </v-select>
        </sw-select>

        <small class="text-danger">{{ errors[0] }}</small>
      </b-form-group>
    </validation-provider>

    <template v-if="contactAddonFieldType">
      <!--      New Filed: Value -> DIGITAL & PERCENTAGE      -->
      <validation-provider
        v-if="['DIGITAL', 'PERCENTAGE'].includes(contactAddonFieldType.type)"
        v-slot="{ errors }"
        rules="required"
        :name="$t('Value')"
      >
        <b-form-group :label="$t('Value')">
          <b-form-input
            v-model="value"
            :placeholder="$t('Value')"
            :disabled="!contactAddonFieldType"
            :state="errors.length > 0 ? false:null"
            type="number"
          />

          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>

      <!--      New Filed: Value -> DATE      -->
      <validation-provider
        v-if="['DATE'].includes(contactAddonFieldType.type)"
        v-slot="{ errors }"
        rules="required"
        :name="$t('Value')"
      >
        <b-form-group :label="$t('Value')">
          <sw-select-fpr @clear="value = ''">
            <flat-pickr
              v-model="value"
              class="form-control form-control-sm"
              :config="{ ...flatPickerConfig, locale: getCalendarLocale($i18n.locale), ...timeConfig('DATE'), defaultDate: value }"
            />
          </sw-select-fpr>

          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>

      <!--      New Filed: Value -> TIME      -->
      <validation-provider
        v-if="['TIME'].includes(contactAddonFieldType.type)"
        v-slot="{ errors }"
        rules="required"
        :name="$t('Value')"
      >
        <b-form-group :label="$t('Value')">
          <sw-select-fpr @clear="value = ''">
            <flat-pickr
              v-model="value"
              class="form-control"
              :config="{ ...flatPickerConfig, locale: getCalendarLocale($i18n.locale), ...timeConfig('TIME'), defaultDate: value }"
              :placeholder="$t('Value')"
            />
          </sw-select-fpr>

          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>

      <!--      New Filed: Value -> CHECKBOX      -->
      <validation-provider
        v-if="['CHECKBOX'].includes(contactAddonFieldType.type)"
        v-slot="{ errors }"
        :name="$t('Value')"
      >
        <b-form-group :label="$t('Value')">
          <b-form-checkbox
            v-model="value"
            :disabled="!contactAddonFieldType"
            :state="errors.length > 0 ? false:null"
          />

          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>

      <!--      New Filed: Value -> EMAIL      -->
      <validation-provider
        v-if="['EMAIL'].includes(contactAddonFieldType.type)"
        v-slot="{ errors }"
        rules="required|email"
        :name="$t('Value')"
      >
        <b-form-group :label="$t('Value')">
          <b-form-input
            v-model="value"
            :placeholder="$t('Value')"
            :disabled="!contactAddonFieldType"
            :state="errors.length > 0 ? false:null"
          />

          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>

      <!--      New Filed: Value -> TEXT_MULTIPLE_ARRAY      -->
      <validation-provider
        v-if="['TEXT_MULTIPLE_ARRAY'].includes(contactAddonFieldType.type)"
        v-slot="{ errors }"
        rules="required"
        :name="$t('Value')"
      >
        <b-form-group class="mb-0">
          <sw-select :name="$t('Value')">
            <v-select
              v-model="contactAddonFieldTypeOptions"
              :placeholder="$t('Value')"
              :options="contactAddonFieldType.contactAddonFieldTypeOptions"
              :filterable="false"
              label="name"
              item-value="id"
              item-text="name"
              :state="errors.length > 0 ? false:null"
              multiple
            >
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>
            </v-select>
          </sw-select>

          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>

      <!--      New Filed: Value -> TEXT_ARRAY      -->
      <validation-provider
        v-if="['TEXT_ARRAY'].includes(contactAddonFieldType.type)"
        v-slot="{ errors }"
        rules="required"
        :name="$t('Value')"
      >
        <b-form-group class="mb-0">
          <sw-select :name="$t('Value')">
            <v-select
              v-model="contactAddonFieldTypeOptions"
              :placeholder="$t('Value')"
              :options="contactAddonFieldType.contactAddonFieldTypeOptions"
              :filterable="false"
              label="name"
              item-value="id"
              item-text="name"
              :state="errors.length > 0 ? false:null"
            >
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>
            </v-select>
          </sw-select>

          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>

      <!--      New Filed: Value -> RADIAL      -->
      <validation-provider
        v-if="['RADIAL'].includes(contactAddonFieldType.type)"
        :name="$t('Value')"
      >
        <b-form-group
          v-slot="{ ariaDescribedby }"
          :label="$t('offer.attribute.types.RADIAL')"
        >
          <b-form-radio
            v-for="(option, i) in contactAddonFieldType.contactAddonFieldTypeOptions"
            :key="i"
            v-model="value"
            :aria-describedby="ariaDescribedby"
            :value="option"
          >
            {{ option.name }}
          </b-form-radio>
        </b-form-group>
      </validation-provider>

      <!--      New Filed: Value -> TEXT      -->
      <validation-provider
        v-if="['TOOLTIP'].includes(contactAddonFieldType.type)"
        :name="$t('Value')"
      >
        <b-form-group :label="$t('Value')">
          <b-input-group>
            <b-form-input
              v-model="newValue"
              :placeholder="$t('Value')"
            />

            <b-input-group-append>
              <b-button
                variant="outline-primary"
                class="btn-icon"
                @click="() => {
                  if (newValue.length) {
                    contactAddonFieldTextOptions.push(newValue)
                    newValue = ''
                  }
                }"
              >
                <feather-icon icon="PlusIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>

        <b-form-group
          v-for="(option, i) in contactAddonFieldTextOptions"
          :key="i"
        >
          <b-input-group>
            <b-form-input
              v-model="contactAddonFieldTextOptions[i]"
              :placeholder="$t('Value')"
            />

            <b-input-group-append>
              <b-button
                variant="outline-danger"
                class="btn-icon"
                @click="contactAddonFieldTextOptions.splice(i, 1)"
              >
                <feather-icon icon="MinusIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </validation-provider>
    </template>

    <!--      New Filed: Value -> TEXT      -->
    <validation-provider
      v-if="!contactAddonFieldType || ['TEXT'].includes(contactAddonFieldType.type)"
      v-slot="{ errors }"
      rules="required"
      :name="$t('Value')"
    >
      <b-form-group :label="$t('Value')">
        <b-form-input
          v-model="value"
          :placeholder="$t('Value')"
          :disabled="!contactAddonFieldType"
          :state="errors.length > 0 ? false:null"
        />

        <small class="text-danger">{{ errors[0] }}</small>
      </b-form-group>
    </validation-provider>

    <!--      New Filed: Desc      -->
    <b-alert
      variant="primary"
      show
    >
      <div class="alert-heading">
        {{ $t('Description') }}
      </div>

      <div class="alert-body">
        <template v-if="contactAddonFieldType">
          <span v-if="contactAddonFieldType.description">{{ contactAddonFieldType.description }}</span>

          <feather-icon
            v-else
            icon="MinusIcon"
          />
        </template>

        <div
          v-else
          class="text-center py-50 text-primary font-weight-bold"
        >
          <feather-icon
            size="17"
            icon="XCircleIcon"
          />

          {{ $t('ChooseTypeFirst') }}
        </div>
      </div>
    </b-alert>

    <!--      Button: Save      -->
    <b-button
      size="sm"
      variant="primary"
      :disabled="!contactAddonFieldType || (invalid && !['RADIAL'].includes(contactAddonFieldType.type) && !['TOOLTIP'].includes(contactAddonFieldType.type))"
      @click="save"
    >
      {{ $t('Save') }}
    </b-button>

    <b-overlay
      no-wrap
      :show="isLoading"
      spinner-variant="primary"
    />
  </validation-observer>
</template>

<script>
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { BFormInput, BInputGroup, BInputGroupAppend } from 'bootstrap-vue'
import {
  CLOSE_MODAL,
  CREATE_CONTACT_ADDON_FIELD,
  GET_CONTACT_ADDON_FIELD_TYPES,
  RELOAD_CONTENT,
  UPDATE_CONTACT_ADDON_FIELD,
} from '@/@constants/mutations'
import { mapGetters } from 'vuex'
import { Polish as pl } from '@/libs/i18n/locales/flatpickr/pl'
import { english as en } from '@/libs/i18n/locales/flatpickr/en'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    vSelect,
    ValidationObserver,
    ValidationProvider,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    flatPickr,
  },

  data: () => ({
    isLoading: false,

    id: null,

    contactAddonFieldType: '',
    addonFieldTypes: [],

    newValue: '',

    value: '',
    contactAddonFieldTypeOptions: [],
    contactAddonFieldTextOptions: [],

    flatPickerConfig: {
      locale: [pl, en],
      dateFormat: 'Y-m-d',
    },
  }),

  computed: {
    ...mapGetters({
      modalConfig: 'modal/getModalState',
    }),
    locales() {
      return this.$i18n.locale
    },
  },

  mounted() { this.ini() },

  methods: {
    async ini() {
      await this.loadTypes()

      this.loadField()
    },

    async loadTypes() {
      this.isLoading = true

      this.$store.dispatch(`addonFieldTypes/${GET_CONTACT_ADDON_FIELD_TYPES}`)
        .then(res => {
          this.addonFieldTypes = this.getOnlyFreeTypes(res)
        })

        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err.response.data.message}`))
        })

        .finally(() => {
          this.isLoading = false

          return true
        })
    },

    loadField() {
      this.isLoading = true

      const { field } = JSON.parse(JSON.stringify(this.modalConfig?.data))

      if (field?.id) {
        this.id = field.id
        this.newValue = field.newValue
        this.value = field.value
        this.contactAddonFieldType = field.contactAddonFieldType
        this.contactAddonFieldTypeOptions = field.contactAddonFieldTypeOptions
        this.contactAddonFieldTextOptions = field.contactAddonFieldTextOptions
      }
    },

    getOnlyFreeTypes(arr) {
      if (!this.modalConfig?.data?.fieldList) return arr

      // const toDelete = new Set(this.modalConfig.data.fieldList.map(el => el.contactAddonFieldType.name))

      // const newArr = arr.filter(({ name }) => !toDelete.has(name))

      return arr
    },

    save() {
      if (this.validationForm()) {
        if (this.modalConfig.contactId) {
          const payload = this.getPayload()

          if (payload) this.send(payload)
        } else {
          this.showToast('danger', this.$i18n.t('errors.errors.SOMETHING_WRONG'))
        }
      }
    },

    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.newContactFieldForm.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },

    getPayload() {
      const {
        id,
        contactAddonFieldType,
        value,
        contactAddonFieldTypeOptions,
        contactAddonFieldTextOptions,
      } = this

      if (['RADIAL'].includes(contactAddonFieldType.type) && !value) return null

      const payload = {
        contactAddonFieldType: contactAddonFieldType.id,
        value,
        contactAddonFieldTypeOptions: [],
      }

      if (['CHECKBOX'].includes(contactAddonFieldType.type)) payload.value = String(Boolean(value))
      if (['RADIAL'].includes(contactAddonFieldType.type)) payload.value = String(value.name)
      if (['TEXT_ARRAY'].includes(contactAddonFieldType.type)) payload.contactAddonFieldTypeOptions = [contactAddonFieldTypeOptions.id]
      if (['TEXT_MULTIPLE_ARRAY'].includes(contactAddonFieldType.type)) payload.contactAddonFieldTypeOptions = contactAddonFieldTypeOptions.map(o => o.id)
      if (['TOOLTIP'].includes(contactAddonFieldType.type)) payload.contactAddonFieldTextOptions = contactAddonFieldTextOptions

      if (id) payload.id = id

      return payload
    },

    send(payload) {
      this.isLoading = true

      const type = !payload.id ? `addonFields/${CREATE_CONTACT_ADDON_FIELD}` : `addonFields/${UPDATE_CONTACT_ADDON_FIELD}`

      this.$store.dispatch(type, {
        contactId: this.modalConfig.contactId,
        payload,
      })
        .then(res => {
          const {
            value, contactAddonFieldType, contactAddonFieldTypeOptions, contactAddonFieldTextOptions,
          } = this

          const newPayload = {
            id: res,
            contactAddonFieldType,
            contactAddonFieldTypeOptions,
            contactAddonFieldTextOptions,
            value: value.name || value,
          }

          if (['TEXT_ARRAY'].includes(contactAddonFieldType.type)) newPayload.contactAddonFieldTypeOptions = [{ name: contactAddonFieldTypeOptions.name }]
          if (['TEXT_MULTIPLE_ARRAY'].includes(contactAddonFieldType.type)) newPayload.contactAddonFieldTypeOptions = contactAddonFieldTypeOptions.map(o => ({ name: o.name }))

          // console.log(newPayload)

          this.$store.commit(`modal/${RELOAD_CONTENT}`, newPayload)

          this.$nextTick(() => this.close())
        })

        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err.response.data.message}`))
        })

        .finally(() => { this.isLoading = false })
    },

    close() { this.$store.commit(`modal/${CLOSE_MODAL}`) },

    timeConfig(type) {
      return type === 'TIME' ? {
        enableTime: true, noCalendar: true, dateFormat: 'H:i', time_24hr: true,
      } : {}
    },

    clearField() {
      this.newValue = ''
      this.value = ''
      this.contactAddonFieldTypeOptions = []
      this.contactAddonFieldTextOptions = []
    },
  },
}
</script>
