<template>
  <div>
    <app-collapse
      ref="filters"
      :class="{
        'mt-50': mt,
        'mb-1': mb,
      }"
    >
      <app-collapse-item
        :title="title || $t('Offer.Filters')"
        class="border"
      >
        <template #header>
          <div
            class="d-flex flex-wrap flex-column flex-md-row align-items-md-center justify-content-between w-100"
            style="row-gap: .5rem; column-gap: .5rem"
          >
            <div
              class="d-flex flex-wrap flex-column flex-md-row align-items-md-center"
              style="row-gap: .5rem; column-gap: .5rem"
            >
              <div
                class="d-flex align-items-center collapse-title"
                style="column-gap: .34rem"
              >
                <feather-icon
                  icon="FilterIcon"
                  class="text-secondary"
                />

                {{ title || $t('Offer.Filters') }}
              </div>

              <div
                class="d-flex flex-wrap"
                style="row-gap: .34rem; column-gap: .34rem"
              >
                <template v-if="shorts">
                  <div
                    v-for="(short, k) in shorts.filter(el => el.value())"
                    :key="k"
                    v-b-tooltip.hover
                    :title="short.title"
                    @click.stop="short.clear ? short.clear() : null"
                  >
                    <b-avatar-group
                      v-if="short.type === 'avatar'"
                      size="sm"
                    >
                      <avatar
                        v-for="(user, i) in short.value().slice(0, 3)"
                        :key="i"
                        :variant="short.variant || 'light-primary'"
                        :user="user"
                        :show-name="false"
                      />
                    </b-avatar-group>
                    <b-badge
                      v-else-if="Array.isArray(short.value())"
                      :variant="short.variant || 'light-primary'"
                      style="white-space: break-spaces"
                    >
                      {{ short.value().join(' ' + $t('To') + ' ') }}
                    </b-badge>
                    <b-badge
                      v-else
                      :variant="short.variant || 'light-primary'"
                      style="white-space: break-spaces"
                    >
                      {{ short.value() }}
                    </b-badge>
                  </div>
                </template>
              </div>
            </div>

            <div
              class="d-flex flex-wrap align-items-center"
              style="row-gap: .5rem; column-gap: .5rem"
            >
              <b-badge
                v-for="(info, k) in res"
                :key="k"
                v-b-tooltip.hover.v-primary
                class="d-flex flex-wrap align-items-center"
                style="row-gap: .5rem; column-gap: .5rem"
                :variant="info.variant || 'primary'"
                :title="info.title"
              >
                {{ info.value() || 0 }}
              </b-badge>
            </div>
          </div>
        </template>

        <slot />
      </app-collapse-item>
    </app-collapse>

    <!--    <transition name="fade">-->
    <!--      <div-->
    <!--        v-if="scrollPos.y.value > 200"-->
    <!--        class="btn position-fixed mr-25"-->
    <!--        style="z-index: 999; bottom: 4.4rem; right: 0; transition-delay: .14s;"-->
    <!--      >-->
    <!--        <b-button-->
    <!--          v-b-toggle.filters-item-->
    <!--          variant="info"-->
    <!--          class="btn-icon rounded-circle"-->
    <!--          @click.prevent.stop="scrollToComponent"-->
    <!--        >-->
    <!--          <feather-icon-->
    <!--            icon="FilterIcon"-->
    <!--            size="16"-->
    <!--          />-->
    <!--        </b-button>-->
    <!--      </div>-->
    <!--    </transition>-->
  </div>
</template>

<script>
import { VBToggle, VBTooltip } from 'bootstrap-vue'
import { useWindowScroll } from '@vueuse/core'

export default {
  name: 'SwFilters',
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    shorts: {
      type: Array,
      required: false,
      default: null,
    },
    res: {
      type: Array,
      required: false,
      default: null,
    },
    mb: {
      type: Boolean,
      required: false,
      default: true,
    },
    mt: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({ scrollPos: { x: 0, y: 0 } }),
  mounted() { this.ini() },
  methods: {
    ini() {
      if (!this.$slots.default) throw new Error('The Filters component requires a slot')

      this.scrollPos = useWindowScroll()
    },

    scrollToComponent() {
      const component = this.$refs.filters
      const offset = 100
      const options = {
        top: component.offsetTop - offset,
        behavior: 'smooth',
      }
      window.scrollTo(options)
    },

    openFilters() {
      this.$refs['filters-item'].collapseOpen()
    },
  },
}
</script>
