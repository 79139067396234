/* eslint-disable */

import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import QrcodeVue from 'qrcode.vue'

import {
  BAlert,
  BAvatar,
  BAvatarGroup,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCol,
  BDropdown, BDropdownDivider,
  BDropdownItem,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormRadio, BFormTextarea,
  BImg,
  BInputGroup,
  BInputGroupPrepend, BLink,
  BListGroup,
  BListGroupItem,
  BOverlay,
  BPagination,
  BProgress,
  BRow,
  BSpinner, BTab,
  BTableSimple, BTabs,
  BTbody,
  BTd,
  BTh,
  BThead,
  BToast,
  BTr,
} from 'bootstrap-vue'

import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Table from '@/views/offers/category/Table.vue'
import Avatar from '@core/components/avatars/Avatar.vue'
import AvatarGroup from '@core/components/avatars/AvatarGroup.vue'
import TableContainer from '@core/components/@sw/Table.vue'
import Icon from '@core/components/@sw/Icon.vue'
import Structure from '@core/components/@sw/Structure.vue'
import HiddenContent from '@core/components/@sw/HiddenContent.vue'
import TransitionCollapse from '@core/components/@sw/TransitionCollapse.vue'
import MySelect from '@/components/my-select/MySelect.vue'
import SwInfoAlert from '@/components/utils/info/SwInfoAlert.vue'
import SwInfoOverlay from '@/components/utils/info/SwInfoOverlay.vue'
import SwInfoTip from '@/components/utils/info/SwInfoTip.vue'
import SwModal from '@/components/utils/root/SwModal.vue'
import SwTabs from '@/components/utils/root/SwTabs.vue'
import Filters from '@/components/Filters.vue'
import TopPageBar from '@/components/page/TopBar.vue'
import SwTabsSearch from '@/components/page/TabsSearch.vue'
import MySelectFpr from '@/components/my-select/MySelectFpr.vue'
import MultilevelStructure from '@core/components/@sw/MultilevelStructure.vue'
import HelpTooltip from '@core/components/@sw/HelpTooltip.vue'
import DropdownItemLabel from "@/components/DropdownItemLabel.vue";
import EmptyContent from "@core/components/@sw/EmptyContent.vue";
import TableDefaultCell from "@core/components/@sw/TableDefaultCell.vue";
import FormInputImage from "@core/components/@sw/input/FormInputImage.vue";
import FormInputColor from "@core/components/@sw/input/FormInputColor.vue";
import SwAvatarGroup from "@core/components/@sw/AvatarGroup.vue";
import TabsModern from "@core/components/@sw/modern/TabsModern.vue";
import Breadcrumbs from "@core/components/@sw/Breadcrumbs.vue";
import UserInfo from "@core/components/@sw/UserInfo.vue";
import SwCardInfo from "@core/components/@sw/modern/CardInfo.vue";
import SwFilters from "@core/components/@sw/modern/Filters.vue";
import ExpandWindow from "@core/components/@sw/ExpandWindow.vue";

Vue.component(FeatherIcon.name, FeatherIcon)

// Test
Vue.component('CategoryTable', Table)

Vue.component('BTab', BTab)
Vue.component('BTabs', BTabs)
Vue.component('BLink', BLink)
Vue.component('BPagination', BPagination)
Vue.component('BFormCheckbox', BFormCheckbox)
Vue.component('BFormInput', BFormInput)
Vue.component('BFormGroup', BFormGroup)
Vue.component('BCard', BCard)
Vue.component('BAvatar', BAvatar)
Vue.component('BAvatarGroup', BAvatarGroup)
Vue.component('BCol', BCol)
Vue.component('BRow', BRow)
Vue.component('BButton', BButton)
Vue.component('BBadge', BBadge)
Vue.component('BInputGroup', BInputGroup)
Vue.component('BDropdownItem', BDropdownItem)
Vue.component('BDropdown', BDropdown)
Vue.component('BDropdownDivider', BDropdownDivider)
Vue.component('BListGroup', BListGroup)
Vue.component('BListGroupItem', BListGroupItem)
Vue.component('BCardHeader', BCardHeader)
Vue.component('BCardBody', BCardBody)
Vue.component('BFormTextarea', BFormTextarea)
Vue.component('BTableSimple', BTableSimple)
Vue.component('BThead', BThead)
Vue.component('BTbody', BTbody)
Vue.component('BTd', BTd)
Vue.component('BTr', BTr)
Vue.component('BTh', BTh)
Vue.component('BOverlay', BOverlay)
Vue.component('BInputGroupPrepend', BInputGroupPrepend)
Vue.component('BSpinner', BSpinner)
Vue.component('BToast', BToast)
Vue.component('BProgress', BProgress)
Vue.component('BCardActions', BCardActions)
Vue.component('BImg', BImg)
Vue.component('BAlert', BAlert)
Vue.component('BFormRadio', BFormRadio)

// Vuexy
Vue.component('AppCollapseItem', AppCollapseItem)
Vue.component('AppCollapse', AppCollapse)

// Shared
Vue.component('TableDefaultCell', TableDefaultCell)
Vue.component('EmptyContent', EmptyContent)
Vue.component('DropdownItemLabel', DropdownItemLabel)
Vue.component('Avatar', Avatar)
Vue.component('AvatarGroup', AvatarGroup)

// Sw
Vue.component('SwIcon', Icon)
Vue.component('SwTabsSearch', SwTabsSearch)
Vue.component('SwInfoAlert', SwInfoAlert)
Vue.component('SwInfoOverlay', SwInfoOverlay)
Vue.component('SwInfoTip', SwInfoTip)
Vue.component('SwModal', SwModal)
Vue.component('SwTabs', SwTabs)
Vue.component('SwSelect', MySelect)
Vue.component('SwFilters', Filters)
Vue.component('SwTopPageBar', TopPageBar)
Vue.component('SwSelectFpr', MySelectFpr)
Vue.component('SwTransitionCollapse', TransitionCollapse)
Vue.component('SwHiddenContent', HiddenContent)
Vue.component('SwHelpTooltip', HelpTooltip)
Vue.component('SwTable', TableContainer)
Vue.component('SwStructure', Structure)
Vue.component('SwMultilevelStructure', MultilevelStructure)
Vue.component('SwFormInputImage', FormInputImage)
Vue.component('SwFormInputColor', FormInputColor)
Vue.component('SwAvatarGroup', SwAvatarGroup)
Vue.component('SwTabsModern', TabsModern)
Vue.component('SwBreadcrumbs', Breadcrumbs)
Vue.component('SwUserInfo', UserInfo)
Vue.component('SwCardInfo', SwCardInfo)
Vue.component('SwFiltersModern', SwFilters)
Vue.component('SwExpandWindow', ExpandWindow)

// Other
Vue.component('QrCode', QrcodeVue)
Vue.component('FontAwesomeIcon', FontAwesomeIcon)
