<template>
  <ul
    v-if="items.length"
    class="sw-breadcrumbs d-flex flex-row flex-wrap m-0 p-0 overflow-hidden cursor-default"
    style="margin-top: 0 !important;"
  >
    <template v-for="(item, i) in items">
      <li
        :id="`item-${i}`"
        :key="i"
        :class="{
          'active': i === activeItemIndex,
          'before-active': i < activeItemIndex,
          'cursor-pointer': i !== activeItemIndex,
        }"
        @click="changeActive(item, i)"
      >
        <div
          class="d-flex align-items-center flex-nowrap text-nowrap font-small-2"
          style="column-gap: .4rem;"
        >
          <strong>
            {{ item.name | truncate(34) }}
          </strong>

          <sw-icon
            v-if="thread.contactThreadFunnelProcessSelected && thread.contactThreadFunnelProcessSelected.contactThreadFunnelTypePointDelayedAt && i === activeItemIndex"
            v-b-tooltip.hover.html="tooltipContent($t('funnel.ThreadAboveNorm'), thread.contactThreadFunnelProcessSelected.contactThreadFunnelTypePointDelayedAt)"
            style="margin-top: -.14rem"
            icon="AlertCircleIcon"
          />

        <!--        <span style="letter-spacing: .1rem; margin-left: .16rem">-->
        <!--          0/0-->
        <!--        </span>-->
        </div>

        <svg
          v-if="i < items.length - 1"
          width="15"
          height="50"
          viewBox="0 0 15 50"
          class="ic-chevron"
        >
          <polygon
            points="0,0 10,25 0,50"
            class="ic-chevron-icon-bg"
          />

          <polygon
            points="0,0 5,0 15,25 5,50 0,50 10,25"
            class="ic-chevron-icon"
          />
        </svg>
      </li>

      <b-tooltip
        :key="`tip-${i}`"
        :target="`item-${i}`"
        placement="top"
        :variant="activeItemIndex ? 'success' : 'primary'"
        triggers="hover"
      >
        <div style="text-align: start">
          <strong>{{ item.name }}</strong>

          <template v-if="item.description">
            <br>
            {{ item.description }}
          </template>
        </div>
      </b-tooltip>
    </template>
  </ul>
</template>

<script>
import { BTooltip, VBTooltip } from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  name: 'SwBreadcrumbs',
  components: {
    BTooltip,
  },
  computed: {
    ...mapGetters({
      thread: 'contact/thread',
    }),
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    change: {
      type: Function,
      required: false,
      default: null,
    },
  },
  emits: ['change'],
  data: () => ({
    activeItemIndex: 0,
  }),
  watch: {
    items(n) { this.setActive(n) },
  },
  mounted() {
    this.setActive(this.items)
  },
  methods: {
    setActive(list) {
      this.activeItemIndex = list.findIndex(({ active }) => active)
    },
    tooltipContent(title, date) {
      return `
          <span>${title}</span>
          <small>${this.$i18n.t('From').toLowerCase()}: ${date.date.split('.')[0]}</small>
        `
    },
    async changeActive(item, i) {
      try {
        if (this.change) await this.change(item)

        this.activeItemIndex = i
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";

$border-radius: .6rem;

$background: $gray-300;
$background-dark: $gray-800;
$active: lighten($success, 20%);
$active-dark: $success;

.sw-breadcrumbs {
  border-radius: $border-radius;

  row-gap: .18rem;

  list-style: none;
}

.sw-breadcrumbs li {
  position: relative;

  padding: .34rem 1.34rem;

  background: $background;

  color: rgb(var(--theme-color-dark));
}
.sw-breadcrumbs li.before-active { background: lighten($active, 14%) }

.sw-breadcrumbs li:last-of-type {
  border-top-right-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
}

.sw-breadcrumbs li.active { background: $active }

.sw-breadcrumbs li:not(.active):hover { background: lighten($active, 18%) }

.sw-breadcrumbs svg.ic-chevron {
  position: absolute;

  top: 0;
  right: -.76rem;

  height: 100%;

  z-index: 1;
}

.sw-breadcrumbs svg.ic-chevron .ic-chevron-icon { fill: rgb(var(--theme-color-background)) }
.sw-breadcrumbs svg.ic-chevron .ic-chevron-icon-bg { fill: $background }
.sw-breadcrumbs li.active svg.ic-chevron .ic-chevron-icon-bg { fill: $active }
.sw-breadcrumbs li.before-active svg.ic-chevron .ic-chevron-icon-bg { fill: lighten($active, 14%) }
.sw-breadcrumbs li:not(.active):hover svg.ic-chevron .ic-chevron-icon-bg { fill: lighten($active, 18%) }

.dark-layout .sw-breadcrumbs li {
  color: rgb(var(--theme-color-light));

  background: $background-dark;
}
.dark-layout .sw-breadcrumbs li.before-active { background: $active-dark }
.dark-layout .sw-breadcrumbs li.active { background: lighten($active-dark, 14%) }
.dark-layout .sw-breadcrumbs li:not(.active):hover { background: lighten($active-dark, 24%) }

.dark-layout .sw-breadcrumbs svg.ic-chevron .ic-chevron-icon { fill: #161d31 }
.dark-layout .sw-breadcrumbs svg.ic-chevron .ic-chevron-icon-bg { fill: $background-dark }
.dark-layout .sw-breadcrumbs li.active svg.ic-chevron .ic-chevron-icon-bg { fill: lighten($active-dark, 14%) }
.dark-layout .sw-breadcrumbs li.before-active svg.ic-chevron .ic-chevron-icon-bg { fill: $active-dark }
.dark-layout .sw-breadcrumbs li:not(.active):hover svg.ic-chevron .ic-chevron-icon-bg { fill: lighten($active-dark, 24%) }
</style>
