<template>
  <div>
    <div>
      <div class="d-flex align-items-center">
        <h4>{{ $t('DeliveryAddresses') }}</h4>
        <b-button
          v-if="!isList"
          size="sm"
          variant="primary"
          class="mb-50 ml-50"
          @click="editedItem = { city: '', country: '', number: '', postalCode: '', street: '', index: -1 }; isModalOpen = true"
        >
          <feather-icon icon="PlusIcon" />
          {{ $t('Add') }}
        </b-button>
      </div>

      <b-alert
        v-if="!isPrimaryCopied || !isCorrespondenceCopied"
        show
        variant="primary"
      >
        <div class="alert-body">
          <div
            class="d-flex flex-column"
            style="row-gap: .5rem"
          >
            <div class="mb-25 font-weight-bolder">
              {{ $t('UsebasicContAddressesesesasDelivereddress') }}
            </div>

            <div
              class="d-flex flex-column flex-md-row"
              style="row-gap: .5rem; column-gap: .5rem"
            >
              <b-button
                variant="outline-primary"
                size="sm"
                :disabled="isPrimaryCopied"
                @click="copyAddress('default')"
              >
                {{ $t('PrimaryAddress') }}
              </b-button>
              <b-button
                variant="outline-primary"
                size="sm"
                :disabled="isCorrespondenceCopied"
                @click="copyAddress('correspondence')"
              >
                {{ $t('AddressForCorrespondence') }}
              </b-button>
            </div>
          </div>
        </div>
      </b-alert>

    </div>

    <b-table
      :items="addresses"
      :fields="fields"
      striped
      show-empty
      responsive
      hover
      style="min-height: unset !important;"
    >
      <!--    Table Head    -->
      <template #head()="{ label }">
        {{ $t(`${label}`) }}
      </template>

      <template #cell(type)="{ value }">
        <div
          v-b-tooltip.right
          style="max-width: 25px"
          :title="$t(typeLabel(value))"
        >
          <feather-icon
            class="text-primary"
            :icon="typeIcon(value)"
          />
        </div>
      </template>

      <template #cell(action)="{ item, index }">
        <b-button
          size="sm"
          variant="warning"
          @click="editedItem = { ...item, index }; isModalOpen = true"
        >
          <feather-icon icon="EditIcon" />
          {{ $t('Edit') }}
        </b-button>
        <b-button
          size="sm"
          variant="danger"
          class="ml-25"
          @click="removeAddress(index)"
        >
          <feather-icon icon="TrashIcon" />
          {{ $t('Delete') }}
        </b-button>
      </template>

      <template #empty>
        <div class="text-center py-1 text-primary font-weight-bold">
          <feather-icon
            size="17"
            icon="XCircleIcon"
          />
          {{ $t('NoData') }}
        </div>
      </template>
    </b-table>

    <b-modal
      :visible="isModalOpen"
      hide-footer
      :title="$t('DeliveryAddresses')"
      @hidden="isModalOpen = false; editedItem = null"
    >
      <validation-observer
        v-if="editedItem && isModalOpen"
        ref="edit-address"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="$t('Country')"
          :rules="'required'"
        >
          <b-form-group :label="$t('Country')">
            <b-form-input
              id="basicInput"
              v-model="editedItem.country"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
        <validation-provider
          v-slot="{ errors }"
          :name="$t('City')"
          :rules="'required'"
        >
          <b-form-group :label="$t('City')">
            <b-form-input
              id="basicInput"
              v-model="editedItem.city"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
        <validation-provider
          v-slot="{ errors }"
          :name="$t('contact.postalCode')"
          :rules="'required'"
        >
          <b-form-group
            :label="$t('contact.postalCode')"
            :state="errors.length > 0 ? false:null"
          >
            <b-form-input
              id="basicInput"
              v-model="editedItem.postalCode"
              class="form-control"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <validation-provider
          v-slot="{ errors }"
          :name="$t('Street')"
          :rules="'required'"
        >
          <b-form-group :label="$t('Street')">
            <b-form-input
              id="basicInput"
              v-model="editedItem.street"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
        <validation-provider
          v-slot="{ errors }"
          :name="$t('BuildingNumber')"
          :rules="'required'"
        >
          <b-form-group :label="$t('BuildingNumber')">
            <b-form-input
              id="basicInput"
              v-model="editedItem.number"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </validation-observer>

      <b-button
        size="sm"
        variant="primary"
        @click="editAddress"
      >
        {{ $t('Confirm') }}
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { BTable, VBTooltip } from 'bootstrap-vue'

export default {
  name: 'DeliveryAddresses',
  components: { ValidationProvider, ValidationObserver, BTable },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    addresses: {
      type: Array,
      default: () => ([]),
    },
    contact: {
      type: Object,
      default: null,
    },
    isList: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['edit-address', 'remove-address'],
  data: () => ({
    editedItem: null,
    isModalOpen: false,
    deliveryFields: [
      { key: 'type', label: 'task.Type' },
      { key: 'city', label: 'OrganizationDetails.addressCity' },
      { key: 'country', label: 'OrganizationDetails.addressCountry' },
      { key: 'number', label: 'OrganizationDetails.addressNumber' },
      { key: 'postalCode', label: 'OrganizationDetails.addressPostalCode' },
      { key: 'street', label: 'OrganizationDetails.addressStreet' },
      { key: 'action', label: 'Action' },
    ],
  }),
  computed: {
    fields() {
      if (!this.isList) return this.deliveryFields

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.deliveryFields.slice(0, -1)
    },
    isPrimaryCopied() {
      return !!this.addresses.find(address => address.type === 'CONTACT_PRIMARY')
    },
    isCorrespondenceCopied() {
      return !!this.addresses.find(address => address.type === 'CONTACT_CORRESPONDENCE')
    },
  },
  /* eslint-disable */
  methods: {
    typeIcon(type) {
      if (type === 'CONTACT_PRIMARY') return 'HomeIcon'
      if (type === 'CONTACT_CORRESPONDENCE') return 'MailIcon'

      return 'MinusIcon'
    },
    typeLabel(type) {
      if (type === 'CONTACT_PRIMARY') return 'PrimaryAddress'
      if (type === 'CONTACT_CORRESPONDENCE') return 'AddressForCorrespondence'

      return ''
    },
    // copy contact address
    copyAddress(type = 'default') {
      if (!this.contact) return

      const addressFields = {
        default: [
          'addressCountry',
          'addressCity',
          'addressPostalCode',
          'addressStreet',
          'addressNumber',
        ],
        correspondence: [
          'addressCorrespondenceCountry',
          'addressCorrespondenceCity',
          'addressCorrespondencePostalCode',
          'addressCorrespondenceStreet',
          'addressCorrespondenceNumber',
        ],
      }

      this.showAlert('error', this.$i18n.t('alerts.AreYouSure'))
        .then(() => {
          let copiedAddress = { index: -1 }
          if (this.contact.isOrganization) {
            copiedAddress = { ...copiedAddress,
              country: this.contact.organization[addressFields[type][0]],
              city: this.contact.organization[addressFields[type][1]],
              postalCode: this.contact.organization[addressFields[type][2]],
              street: this.contact.organization[addressFields[type][3]],
              number: this.contact.organization[addressFields[type][4]],
            }
          } else {
            copiedAddress = { ...copiedAddress,
              country: this.contact[addressFields[type][0]],
              city: this.contact[addressFields[type][1]],
              postalCode: this.contact[addressFields[type][2]],
              street: this.contact[addressFields[type][3]],
              number: this.contact[addressFields[type][4]],
            }
          }

          copiedAddress.type = type === 'default' ? 'CONTACT_PRIMARY' : 'CONTACT_CORRESPONDENCE'

          this.$emit('edit-address', { ...copiedAddress })
        })
    },
    removeAddress(index) {
      this.$emit('remove-address', index)
    },
    async editAddress() {
      const isValid = await this.$refs['edit-address'].validate()
      if (!isValid) return false

      this.$emit('edit-address', this.editedItem)
      this.isModalOpen = false
      this.$nextTick(() => {
        this.editedItem = null
      })
    },
  },
}
</script>

<style scoped>

</style>
