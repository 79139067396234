// eslint-disable-next-line no-unused-vars,import/no-cycle
import axiosIns from '@/libs/axios'
import { GET_FUNNEL_POINTS, GET_FUNNELS } from '@/@constants/mutations'
import Vue from 'vue'
import * as fields from '@/@constants/fields'

export default {
  namespaced: true,
  state: {
    filters: {
      closeStatuses: [],
      tags: [],
      limit: 100,
      status: '',
      assignedUsers: [],
      contactStructure: false,
      delayedContactStatuses: false,
      contactsOverdueTasks: false,
      delayedContactThreadStatuses: false,
      withOrganization: false,
      tagsSum: false,
      contactCloseStatus: '',
      createdAt: '',
    },
    selectedUsers: [],
  },
  getters: {
    getFilters: state => state.filters,
  },
  mutations: {
    SET_FILTER(state, payload) {
      Vue.set(state.filters, payload.field, payload.value)
    },
    CLEAR_FILTERS(state) {
      Vue.set(state, 'filters', {
        status: '',
        assignedUsers: [],
        contactStructure: false,
        delayedContactStatuses: false,
        delayedContactThreadStatuses: false,
        contactsOverdueTasks: false,
        limit: 50,
      })
    },
  },
  actions: {
    [GET_FUNNELS]: () => new Promise((resolve, reject) => {
      axiosIns.get('1/settings/contactThreadFunnelTypes', { params: { fields_load: fields.SETTINGS_FUNNEL_TYPE, orderBy: 'position.asc' } })
        .then(res => {
          resolve(res.data.data.items.filter(item => item.contactThreadFunnelTypePoints.length))
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [GET_FUNNEL_POINTS]: ({ state }, payload) => new Promise((resolve, reject) => {
      const { funnelId, search } = payload

      if (!Number.isNaN(Number(funnelId))) {
        const params = {
          fields_load: fields.SETTINGS_FUNNEL_TYPE,
          ...search,
        }
        axiosIns.get(`1/settings/contactThreadFunnelTypes/${funnelId}`, { params })
          .then(res => {
            resolve((res.data.data?.item?.contactThreadFunnelTypePoints || []).sort((a, b) => a.position - b.position))
          })
          .catch(err => {
            reject(err)
          })
      }
    }),
  },
}
