/* eslint-disable */

import { GET_TIMELINE, GET_TIMELINE_STATS } from '@/@constants/mutations'
// eslint-disable-next-line import/no-cycle
import axiosIns from '@/libs/axios'
import axios from "axios";
import moment from "moment";

let source;
export default {
  namespaced: true,
  state: () => ({}),
  getters: {},
  mutations: {},
  actions: {
    [GET_TIMELINE_STATS]: ({ commit }, { contactId = null, threadId = null, filters = null, search }) => new Promise((resolve, reject) => {
      let path = '1/contacts/threads/timeline/getStatistics'
      if (contactId && threadId) path = `1/contacts/${contactId}/threads/${threadId}/timeline/getStatistics`

      const params = {}
      if (filters?.timeRange) {
        const value = filters.timeRange.dateRange()
        if (value[0]) {
          params[`gte_createdAt`] = moment(value[0], 'DD-MM-YYYY hh:mm').format('YYYY-MM-DD hh:mm')
        }
        if (value[1]) {
          params[`lte_createdAt`] = moment(value[1], 'DD-MM-YYYY hh:mm').format('YYYY-MM-DD hh:mm')
        }
      }
      if (filters?.contacts && filters.contacts.length) {
        params['eqArr_contactThread-contact-id'] = JSON.stringify(filters.contacts)
      }
      if (filters?.funnelPoint) {
        params['eq_contactThread-contactThreadFunnelProcesses-contactThreadFunnelTypePoint-id'] = filters.funnelPoint
      }
      if (filters?.funnel) {
        params['eq_contactThread-contactThreadFunnelProcesses-contactThreadFunnelTypePoint-contactThreadFunnelType-id'] = filters.funnel
      }
      if (filters?.thread) {
        params['eq_contactThread-id'] = filters.thread
      }

      if (search?.length) {
        const _search = search.replaceAll(' ', '%')
        params.search = `%${_search}%`
        params.search_fields = [
          'contactThread-contact-firstName',
          'contactThread-contact-lastName',
          'contactThreadNote-content',
          'contactThreadTaskLog-contactThreadTask-content',
          'contactThreadSms-content',
          'mail-subject',
          'mail-contentHtml',
          'contactThreadChecklistPointDatumLog-contactThreadChecklistPointDatum-contactThreadChecklistTypePoint-name',
        ].join(',')
      }

      axiosIns.get(path, { params })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    [GET_TIMELINE]: ({ commit }, { filters, pagination, search, types = [], addonFilters }) => new Promise((resolve, reject) => {
      if (typeof source !== typeof undefined) source.cancel()

      source = axios.CancelToken.source()

      const params = {
        orderBy: 'createdAt.desc',
        fields_load: 'GLOBAL_TIMELINE',
      }

      if (types && types.length) {
        params.eqArr_type = JSON.stringify(types)
      }

      if (types.includes('contactThreadTask') && types.length === 1) {
       if (addonFilters.contactThreadTask.type !== -1) params['eq_contactThreadTaskLog-contactThreadTask-contactThreadTaskType'] = addonFilters.contactThreadTask.type
       if (addonFilters.contactThreadTask.status !== '') params['eq_contactThreadTaskLog-status'] = addonFilters.contactThreadTask.status
      }

      if (filters?.timeRange) {
        const value = filters.timeRange.dateRange()
        if (value[0]) {
          params[`gte_createdAt`] = moment(value[0], 'DD-MM-YYYY hh:mm').format('YYYY-MM-DD hh:mm')
        }
        if (value[1]) {
          params[`lte_createdAt`] = moment(value[1], 'DD-MM-YYYY hh:mm').format('YYYY-MM-DD hh:mm')
        }
      }

      if (filters?.contacts && filters.contacts.length) {
        params['eqArr_contactThread-contact-id'] = JSON.stringify(filters.contacts)
      }

      if (filters?.funnelPoint) {
        params['eq_contactThread-contactThreadFunnelProcesses-contactThreadFunnelTypePoint-id'] = filters.funnelPoint
      }
      if (filters?.funnel) {
        params['eq_contactThread-contactThreadFunnelProcesses-contactThreadFunnelTypePoint-contactThreadFunnelType-id'] = filters.funnel
      }
      if (filters?.thread) {
        params['eq_contactThread-id'] = filters.thread
      }

      if (search.length) {
        const _search = search.replaceAll(' ', '%')
        params.search = `%${_search}%`
        params.search_fields = [
          'contactThread-contact-firstName',
          'contactThread-contact-lastName',
          'contactThreadNote-content',
          'contactThreadTaskLog-contactThreadTask-content',
          'contactThreadSms-content',
          'mail-subject',
          'mail-contentHtml',
          'contactThreadChecklistPointDatumLog-contactThreadChecklistPointDatum-contactThreadChecklistTypePoint-name',
        ].join(',')
      }

      if (pagination) {
        params.page = pagination.currentPage
        params.limit = pagination.limit
      }

      // console.log(
      //     axiosIns.get(`1/contacts/threads/timeline`, { params: {
      //         fields_info: 2
      //       } })
      // )

      axiosIns.get(`1/contacts/threads/timeline`, { params, cancelToken: source.token })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
  },
}
