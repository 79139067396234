<template>
  <div>
    <!--      Filters      -->
    <div
      v-if="showRefresh ||
        showPagination ||
        showSearch ||
        showColumns"
      class="d-flex flex-column flex-md-row mb-1 mb-md-75 search-bar align-items-between align-content-center"
      style="row-gap: .34rem"
    >
      <!--      Table Edit Pagination     -->
      <div class="d-flex mr-md-25 flex-row">
        <b-button
          v-if="showRefresh"
          class="btn-icon"
          variant="outline-secondary"
          @click="reloadContent"
        >
          <feather-icon icon="RefreshCwIcon" />
        </b-button>

        <b-button v-if="showSorter"
                  :id="`show_sorter_${tableId}`"
                  variant="outline-secondary"
                  size="sm"
                  class="ml-50 mb-0 mt-0"
        >
          <span v-if="fields.find(e => e.sortBy === sorter.sortBy)"
                variant="light-secondary"
          >
            <sw-icon icon="hi-solid-sort-ascending"
                     size="12"
            />
          </span>
        </b-button>

        <b-popover
          :target="`show_sorter_${tableId}`"
          placement="left"
          triggers="hover"
          custom-class="toggle-columns"
        >
          <template #title>
            <span>
              {{ $t('Sort') }}
            </span>
          </template>

          <b-form-checkbox switch
                           variant="primary"
                           :value="true"
                           :unchecked-value="false"
                           :checked="sorter.sortDesc"
                           @change="$emit('sort-changed', { ...sorter, sortDesc: $event })"
          >
            {{ $t('Sort') }}: {{ $t(!sorter.sortDesc ? 'Ascending' : 'Descending') }}
          </b-form-checkbox>

          <b-form-radio-group :checked="sorter.sortBy"
                              @change="$emit('sort-changed', { ...sorter, sortBy: $event })"
          >
            <b-list-group>
              <b-list-group-item
                v-for="(sortField, index) in fields.filter(e => e.sortBy)"
                :key="`visible_sort_field_${sortField.label}_${index}`"
                tag="label"
              >
                <b-form-radio :value="sortField.sortBy"
                              :checked="sortField.sortBy === sorter.sortBy"
                >
                  <span class="text-capitalize">{{ $t(`${sortField.label === '#' ? 'ID' : sortField.label}`) }}</span>
                </b-form-radio>
              </b-list-group-item>
            </b-list-group>
          </b-form-radio-group>
        </b-popover>

        <sw-select
          v-if="showPagination"
          class="ml-50 mb-0 mt-0"
        >
          <v-select
            :value="pagination.perPage"
            :options="[25, 50, 100, 250]"
            style="min-width: 100px"
            :clearable="false"
            class="per-page-selector w-100"
            @input="changePagination('perPage', $event)"
          />
        </sw-select>
      </div>

      <!--      Table Edit Search     -->
      <div class="flex-grow-1 mr-1 order-2">
        <b-input-group
          v-if="showSearch"
          class="input-group-merge search-input-group mx-25 mb-0"
          style="min-width: 150px"
        >
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input
            :placeholder="$t('Search')"
            class="input-search"
            @change="changeSearchQuery"
          />
        </b-input-group>
      </div>

      <!--      Table Edit Dropdown     -->
      <div class="order-md-3 order-1 mb-0 pb-0">
        <b-button v-if="showColumns"
                  :id="`show_columns_${tableId}`"
                  variant="outline-secondary"
                  size="sm"
        >
          {{ $t('ShowColumns') }}
          <b-badge variant="light-secondary">
            {{ fields.filter(f => f.visible).length }} / {{ fields.length }}
          </b-badge>
        </b-button>

        <portal-target
          name="full-screen-button"
          slim
          class="ml-25"
        />

        <b-popover
          :target="`show_columns_${tableId}`"
          placement="left"
          triggers="hover, focus"
          custom-class="toggle-columns"
        >
          <template #title>
            <span>
              {{ $t('ShowColumns') }}
            </span>
          </template>

          <vue-perfect-scrollbar :options="{ maxScrollbarLength: 60, }"
                                 style="max-height: 300px"
          >
            <b-list-group>
              <b-list-group-item v-for="(field, index) in fields"
                                 :key="`visible_field_${field.label}_${index}`"
                                 tag="label"
                                 class="mb-0 pb-0"
                                 :for="`visible_field_${field.label}_${index}`"
                                 @click="changeFieldVisibility(index)"
              >
                <div class="d-flex align-items-center mb-0 pb-50">
                  <b-form-checkbox id="`visible_field_${field.label}_${index}`"
                                   variant="primary"
                                   :checked="field.visible"
                                   :readonly="field.label === '#'"
                  />
                  <span class="text-capitalize">{{ $t(`${field.label}`) }}</span>
                </div>
              </b-list-group-item>
            </b-list-group>
          </vue-perfect-scrollbar>

          <div class="d-flex mt-1">
            <b-button variant="outline-secondary"
                      size="sm"
                      class="mr-50"
                      style="min-width: 100px"
                      @click="$root.$emit('bv::hide::popover')"
            >
              {{ $t('Cancel') }}
            </b-button>
            <b-button variant="primary"
                      size="sm"
                      class="flex-grow-1"
                      @click="saveConfig"
            >
              {{ $t('Save') }}
            </b-button>
          </div>

          <!--          <div class="d-flex flex-wrap">-->
          <!--            <b-badge-->
          <!--              v-for="(field, index) in fields"-->
          <!--              :key="`visible_field_${field.label}_${index}`"-->
          <!--              :variant="field.label === '#' ? 'secondary' : (field.visible ? 'primary' : 'light-primary')"-->
          <!--              class="m-25 flex-grow-1 cursor-pointer"-->
          <!--              @click="changeFieldVisibility(index)"-->
          <!--            >-->
          <!--              <span class="text-capitalize">{{ $t(`${field.label}`) }}</span>-->
          <!--            </b-badge>-->
          <!--          </div>-->

          <!--          <div class="d-flex mt-50">-->
          <!--            <b-button variant="outline-primary"-->
          <!--                      size="sm"-->
          <!--                      class="flex-grow-1 mr-25"-->
          <!--                      @click="setAllShowVisability(true)"-->
          <!--            >-->
          <!--              <sw-icon icon="EyeIcon" />-->
          <!--              {{ $t('ShowAll') }}-->
          <!--            </b-button>-->
          <!--            <b-button variant="outline-primary"-->
          <!--                      size="sm"-->
          <!--                      class="flex-grow-1"-->
          <!--                      @click="setAllShowVisability(false)"-->
          <!--            >-->
          <!--              <sw-icon icon="EyeOffIcon" />-->
          <!--              {{ $t('HideAll') }}-->
          <!--            </b-button>-->
          <!--          </div>-->
        </b-popover>

        <!--        <sw-select class=" mb-0 pb-0">-->
        <!--          <b-dropdown-->
        <!--            v-if="showColumns"-->
        <!--            variant="outline-secondary"-->
        <!--            size="sm"-->
        <!--            no-caret-->
        <!--            dropleft-->
        <!--            class="w-100 mb-0 pb-0"-->
        <!--          >-->
        <!--            &lt;!&ndash; eslint-disable &ndash;&gt;-->
        <!--            <template-->
        <!--              #button-content-->
        <!--              class="sr-only"-->
        <!--            >-->
        <!--              {{ $t('ShowColumns') }}-->
        <!--              <b-badge variant="light-secondary">-->
        <!--                {{ fields.filter(f => f.visible).length }} / {{ fields.length }}-->
        <!--              </b-badge>-->
        <!--            </template>-->
        <!--            <b-dropdown-item-->
        <!--              v-for="(field, index) in fields"-->
        <!--              :key="`visible_field_${field.label}`"-->
        <!--              :class="{'active': field.visible}"-->
        <!--              @click.native.capture.stop="changeFieldVisibility(index)"-->
        <!--            >-->
        <!--              <span class="text-capitalize">{{ $t(`${field.label}`) }}</span>-->
        <!--            </b-dropdown-item>-->
        <!--          </b-dropdown>-->
        <!--        </sw-select>-->
      </div>
    </div>

    <!--      Table      -->
    <div class="w-100 overflow-hidden position-relative">
      <slot name="table" />
    </div>

    <slot name="loading" />

    <slot name="empty" />

    <!-- Pagination -->
    <b-pagination
      v-if="showPagination"
      :value="pagination.currentPage"
      class="pt-1"
      :total-rows="pagination.totalRecords"
      :per-page="pagination.perPage"
      align="center"
      @change="changePagination('currentPage', $event)"
    />
  </div>
</template>

<script>
/* eslint-disable */

import vSelect from 'vue-select'
import {mapGetters} from "vuex";
import axiosIns from "@/libs/axios";
import {BPopover, BFormRadioGroup} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
  name: 'Table',
  components: {
    BPopover,
    vSelect,
    VuePerfectScrollbar,
    BFormRadioGroup,
  },
  props: {
    tableId: {
      type: String,
      default: null,
    },
    showRefresh: {
      default: true,
      type: Boolean,
    },
    showColumns: {
      default: false,
      type: Boolean,
    },
    showSorter: {
      default: false,
      type: Boolean,
    },
    showSearch: {
      default: false,
      type: Boolean,
    },

    showPagination: {
      default: true,
      type: Boolean,
    },
    pagination: {
      default: () => ({
        perPage: 25,
        currentPage: 1,
        totalRecords: 100,
      }),
      type: Object,
    },
    sorter: {
      default: () => ({
        sortDesc: false,
        sortBy: 'id',
      }),
      type: Object,
    },
    fields: {
      default: () => [],
      type: Array,
    },
    // requestConfig: {
    //   default: () => ({
    //     url: '',
    //     fieldsLoad: '',
    //     filters: {},
    //   }),
    // },
  },
  data: () => ({
    tableKey: '',
  }),
    computed: {
        ...mapGetters({
            currentUser: 'auth/getCurrentUser',
            systemSettings: 'system/getSettings',
        }),
    },
  emits: ['change-pagination', 'change-search-query', 'reload-content', 'set-fields', 'sort-changed'],
  async mounted() {
      const name = `${this.$route.name.replaceAll('/', '_').replaceAll('-', '_')}_table_columns_`

    if (this.tableId && this.showColumns) {
        if (this.$route.params?.tab) {
            this.tableKey = `${name}${this.$route.params?.tab}_${this.tableId}`
        } else {
            this.tableKey = `${name}_${this.tableId}`
        }

        if (this.currentUser && (this.currentUser.configTables === null || !this.currentUser.configTables[this.tableKey])) {
            if (this.systemSettings?.configTables !== null && this.systemSettings.configTables[this.tableKey]) {
                this.$emit('set-fields', this.getMergedFields(JSON.parse(this.systemSettings.configTables[this.tableKey])))
                const newConfig = { ...(this.currentUser.configTables || {}), [this.tableKey]: JSON.parse(this.systemSettings.configTables[this.tableKey]) }
                this.currentUser.configTables = newConfig
                await axiosIns.patch(`1/users/${this.currentUser.id}/profile`, { id: this.currentUser.id, configTables: newConfig })
            }
            // else {
            //     const newConfig = { ...(this.currentUser.configTables || {}), [this.tableKey]: JSON.stringify(this.fields.map(({ label, key, visible }) => ({ label, key, visible }))) }
            //     this.currentUser.configTables = newConfig
            //     await axiosIns.patch('1/users', { id: this.currentUser.id, configTables: newConfig })
            // }

        } else if (this.currentUser.configTables !== null && this.currentUser.configTables[this.tableKey]) {
            this.$emit('set-fields', this.getMergedFields(JSON.parse(this.currentUser.configTables[this.tableKey])))
        }
    }

    // this.tableKey = `${this.$route.path.replaceAll('/', '_')}_table_columns_${this.fields.map(({ label }) => (label[0] + label[2])).join('')}`
    // if (localStorage.getItem(this.tableKey)) {
    //   this.$emit('set-fields', JSON.parse(localStorage.getItem(this.tableKey)))
    // }
  },
  methods: {
      async saveConfig() {
        try {
            const newConfig = { ...(this.currentUser.configTables || {}), [this.tableKey]: JSON.stringify(this.fields.map(({ label, key, visible }) => ({ label, key, visible }))) }
            this.currentUser.configTables = newConfig
            await axiosIns.patch(`1/users/${this.currentUser.id}/profile`, { id: this.currentUser.id, configTables: newConfig })

            this.$root.$emit('bv::hide::popover')
            this.showToast('success', this.$i18n.t('success.contactUpdated'))
        } catch (err) {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        }
      },
      getMergedFields(newFields = []) {
        return this.fields.map(field => {
            const isExistInNewList = newFields.find(e => e.key === field.key)
            if (isExistInNewList) {
                return { ...field, ...isExistInNewList }
            }

            return field
        })
      },
    async changeFieldVisibility(index) {
      if (this.fields[index].label === '#') return
      this.fields[index].visible = !this.fields[index].visible

      await axiosIns.patch(`1/users/${this.currentUser.id}/profile`, { id: this.currentUser.id, configTables: { ...(this.currentUser.configTables || {}), [this.tableKey]: JSON.stringify(this.fields.map(({ label, key, visible }) => ({ label, key, visible }))) } })
      this.$store.commit('auth/SET_USER_FIELD', { value: { ...(this.currentUser.configTables || {}), [this.tableKey]: JSON.stringify(this.fields.map(({ label, key, visible }) => ({ label, key, visible }))) }, field: 'configTables' })

      localStorage.setItem(this.tableKey, JSON.stringify(this.fields))
    },
      async setAllShowVisability(visible) {
          const newFields = this.fields.map(e => ({ label: e.label, key: e.key, visible: e.label !== '#' ? visible : true }))
          this.$emit('set-fields', this.getMergedFields(newFields))
          this.$store.commit('auth/SET_USER_FIELD', { value: { ...(this.currentUser.configTables || {}), [this.tableKey]: JSON.stringify(newFields) }, field: 'configTables' })

          await axiosIns.patch('1/users', { id: this.currentUser.id, configTables: { ...(this.currentUser.configTables || {}), [this.tableKey]: JSON.stringify(newFields) } })

          localStorage.setItem(this.tableKey, JSON.stringify(this.fields))
      },
    changePagination(field, value) {
      if (field === 'perPage') {
        this.$emit('change-pagination', { 'currentPage': 1 })
      }
      this.$emit('change-pagination', { [field]: value })
      this.reloadContent()
    },
    changeSearchQuery(value) {
      this.$emit('change-search-query', value)
      this.reloadContent()
    },
    reloadContent() {
      this.$emit('reload-content')
    },
  },
  // setup() {
  //   const {
  //     getRequest, pagination, loading, data,
  //   } = useFetch(this.requestConfig.url, this.requestConfig.fieldsLoad, this.requestConfig.filters)
  //
  //   watch(this.requestConfig.filters, () => {
  //     getRequest()
  //   })
  //
  //   onMounted(() => {
  //     getRequest()
  //   })
  //
  //   return {
  //     getRequest,
  //     pagination,
  //     loading,
  //     data,
  //   }
  // },
}
</script>

<style lang="scss">
.toggle-columns {
  width: 350px !important;
  max-width: 350px !important;
}
</style>
