import Vue from 'vue'
import VueRouter from 'vue-router'
import { offers } from '@/router/offers/offers'
// import { projects } from '@/router/projects/projects'
import { emails } from '@/router/email/email'
import campaigns from '@/router/campaigns/campaigns'
import { projects } from '@/router/projects/projects' // Bardzo proszę więcej nie zakomentowywać mi Projektów! Ehrr
import { news } from '@/router/news/news' // Bardzo proszę więcej nie zakomentowywać mi Projektów! Ehrr
// import { negotiations } from '@/router/negotiations/negotiations'
import * as fields from '@/@constants/fields'
import { AUTO_MODAL_REMOVE_ALL } from '@/@constants/mutations'
// eslint-disable-next-line camelcase,import/no-cycle
import contact_new from '@/router/contact/contact_new'
// import { contacts } from './contact/contact'
import { settings } from './settings/settings'
import { reports } from './reports/reports'
import { user } from './user/user'
import marketingPlans from './plans/plans'
import organizations from './organizations/organizations'
import commissions from './commissions/commissions'
import orders from './orders/orders'
import ranking from './ranking/ranking'
import structure from './structure/structure'
import booking from './booking/booking'
// eslint-disable-next-line import/no-cycle
import store from '../store/index'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  // scrollBehavior() {
  //   return { x: 0, y: 0 }
  // },
  routes: [
    // DASHBOARD
    {
      path: '/',
      name: 'dashboard',
      component: () => import('@/views/core/dashboard/Dashboard.vue'),
      meta: {
        autorize: true,
        pageTitle: 'Dashboard',
      },
    },
    // CONTACTS
    {
      path: '/contacts',
      name: 'contacts',
      component: () => import('@/pages/contact.old/List.vue'),
      props: true,
      meta: {
        autorize: true,
        pageTitle: 'Contacts',
      },
    },
    {
      path: '/mobile-clients',
      name: 'clients',
      component: () => import('@/pages/MobileClients.vue'),
      props: true,
      meta: {
        autorize: true,
        pageTitle: 'MobileClients',
      },
    },
    // ...contacts,
    // eslint-disable-next-line camelcase
    ...contact_new,
    // SALES FUNNEL
    {
      path: '/sales-funnel/:id',
      name: 'sales-funnel',
      props: true,
      component: () => import('@/pages/SalesFunnel.vue'),
      meta: {
        autorize: true,
        pageTitle: 'Funnel',
        breadcrumb: [
          {
            active: true,
            request: {
              routeParam: 'id', endpoint: '/settings/contactThreadFunnelTypes/{id}', fields: ['name'], fields_load: fields.BASE_NAME,
            },
          },
        ],
      },
    },
    // GLOBAL TIMELINE
    {
      path: '/global-timeline',
      name: 'timeline',
      props: true,
      component: () => import('@/pages/GlobalTimeline.vue'),
      meta: {
        autorize: true,
        pageTitle: 'contactTabs.Timeline',
      },
    },
    {
      path: '/change-logs',
      name: 'change-logs',
      component: () => import('@/pages/changeLogs/ChangeLogs.vue'),
      meta: {
        autorize: true,
        pageTitle: 'Aktualizacje',
      },
    },
    // Applications
    {
      path: '/applications',
      name: 'applications',
      component: () => import('@/pages/applications/Applications.vue'),
      meta: {
        autorize: true,
        pageTitle: 'Applications',
      },
    },
    // {
    //   path: '/settings/applications',
    //   name: 'settingApplications',
    //   component: () => import('@/pages/settings/Applications.vue'),
    //   meta: {
    //     autorize: true,
    //     pageTitle: 'Applications',
    //   },
    // },
    // GLOBAL TASKS
    {
      path: '/tasks',
      name: 'tasks',
      props: true,
      component: () => import('@/pages/Tasks.vue'),
      meta: {
        autorize: true,
        pageTitle: 'Tasks',
      },
    },
    {
      path: '/calendar',
      name: 'calendar',
      props: true,
      component: () => import('@/pages/Calendar.vue'),
      meta: {
        autorize: true,
        pageTitle: 'Calendar',
      },
    },
    {
      path: '/tasks',
      name: 'tasks:isToday',
      component: () => import('@/pages/Tasks.vue'),
      meta: {
        autorize: true,
        pageTitle: 'Tasks',
      },
    },
    // GLOBAL PAYMENTS
    {
      path: '/payments',
      name: 'schedules',
      component: () => import('@/pages/Schedules.vue'),
      meta: {
        autorize: true,
        pageTitle: 'Schedules',
      },
    },
    // SHOP
    {
      path: '/shop',
      name: 'shop',
      component: () => import('@/pages/shop/Container.vue'),
      meta: {
        autorize: true,
        pageTitle: 'Shop',
      },
    },
    // NOTIFICATIONS
    {
      path: '/notifications',
      name: 'notifications',
      component: () => import('@/pages/Notifications.vue'),
      meta: {
        autorize: true,
        pageTitle: 'Notifications',
      },
    },
    // CHAT
    {
      path: '/chat',
      name: 'chat',
      component: () => import('@/pages/Chat.vue'),
      meta: {
        autorize: true,
        contentRenderer: 'sidebar-left',
        contentClass: 'chat-application',
        title: 'Chat',
      },
    },
    {
      path: '/client-chat/:openConversationId?',
      name: 'client-chat',
      props: true,
      component: () => import('@/pages/ClientChat.vue'),
      meta: {
        autorize: true,
        contentRenderer: 'sidebar-left',
        contentClass: 'chat-application',
        title: 'Chat',
      },
    },
    // CALENDAR
    {
      path: '/calendar',
      name: 'calendar',
      component: () => import('@/pages/Calendar.vue'),
      meta: {
        autorize: true,
        title: 'Calendar',
      },
    },
    // DOCUMENTS
    {
      path: '/documents/:folderId?',
      name: 'documents',
      component: () => import('@/pages/Documents.vue'),
      meta: {
        autorize: true,
        pageTitle: 'Documents',
        breadcrumb: [
          {
            text: 'Documents',
            active: false,
            to: '/documents',
          },
        ],
      },
    },
    {
      path: '/videos/:folderId?',
      name: 'videos',
      component: () => import('@/pages/Documents.vue'),
      meta: {
        autorize: true,
        pageTitle: 'Videos',
        breadcrumb: [
          {
            text: 'Videos',
            active: false,
            to: '/videos',
          },
        ],
      },
    },
    // USERS
    {
      path: '/users',
      name: 'users',
      component: () => import('@/pages/user/List.vue'),
      meta: {
        autorize: true,
        pageTitle: 'Users',
      },
    },
    ...user,
    // AUTH
    {
      path: '/login',
      name: 'login',
      component: () => import('@/pages/auth/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/set-password/:token',
      name: 'passwordSetter',
      component: () => import('@/pages/auth/SetPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/reset-password/:token?',
      name: 'passwordReset',
      component: () => import('@/pages/auth/ResetPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/system-blocked',
      name: 'system-blocked',
      component: () => import('@/views/error/Error403.vue'),
      meta: {
        layout: 'full',
      },
    },
    // CALCULATORS
    {
      path: '/calculators',
      name: 'calculators',
      component: () => import('@/pages/calculator/Calculator.vue'),
      props: true,
      meta: {
        autorize: true,
        pageTitle: 'Calculators',
      },
    },
    {
      path: '/calculator/new',
      name: 'newCalculator',
      component: () => import('@/pages/calculator/New.vue'),
      props: true,
      meta: {
        autorize: true,
        pageTitle: 'Calculator',
        breadcrumb: [
          {
            text: 'Calculators',
            active: false,
            to: '/calculators',
          },
          {
            text: 'calculators.New',
            active: true,
          },
        ],
      },
    },
    {
      path: '/calculator/:id',
      name: 'newCalculator',
      component: () => import('@/pages/calculator/New.vue'),
      props: true,
      meta: {
        autorize: true,
        pageTitle: 'Calculator',
        breadcrumb: [
          {
            text: 'Calculators',
            active: false,
            to: '/calculators',
          },
          {
            text: 'new',
            active: true,
          },
        ],
      },
    },
    ...settings,
    ...reports,
    ...offers,
    // PROJECTS
    ...projects,
    ...news,
    // ...projects,
    ...emails,
    ...campaigns,
    // NEGOTIATIONS
    // ...negotiations,
    ...organizations,
    ...marketingPlans,
    ...commissions,
    ...orders,
    ...ranking,
    ...structure,
    ...booking,
    {
      path: '/client/confirm-email/:token',
      name: 'confirm-email',
      component: () => import('@/views/ConfirmEmail.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/system-not-exists',
      name: 'system-not-exists',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/newer-system-available',
      name: 'newer-system-available',
      component: () => import('@/views/error/SystemNewerVersion.vue'),
      meta: {
        layout: 'full',
      },
    },

    {
      path: '/complaints',
      name: 'complaints',
      component: () => import('@/pages/complaints/List.vue'),
      meta: {
        autorize: true,
        pageTitle: 'Complaints',
      },
    },

    {
      path: '/onboarding',
      name: 'onboarding',
      component: () => import('@/pages/onboarding/List.vue'),
      meta: {
        autorize: true,
        pageTitle: 'Onboarding',
      },
    },

    {
      path: '/trainings',
      name: 'trainings',
      component: () => import('@/pages/trainings/List.vue'),
      meta: {
        autorize: true,
        pageTitle: 'Trainings',
      },
    },

    {
      path: '/copier',
      name: 'copier',
      component: () => import('@/pages/copier/Copier.vue'),
      meta: {
        autorize: true,
        pageTitle: 'Kopiarka',
      },
    },
    {
      path: '/change-logs',
      name: 'change-logs',
      component: () => import('@/pages/changeLogs/ChangeLogs.vue'),
      meta: {
        autorize: true,
        pageTitle: 'Updates',
      },
    },
    {
      path: '/app-module',
      name: 'app-module',
      component: () => import('@/pages/modules/List.vue'),
      meta: {
        autorize: true,
        pageTitle: 'Modules',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },

    {
      path: '/calls',
      name: 'calls',
      component: () => import('@/pages/calls/List.vue'),
      meta: {
        autorize: true,
        pageTitle: 'Calls',
      },
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')

  if (appLoading) appLoading.style.display = 'none'
})

router.onError(error => {
  if (/loading chunk \d* failed./i.test(error.message)) {
    window.location.reload(true)
  }
})

const ifAuthenticated = (to, from, next) => {
  if (to.name === 'login' && (localStorage.getItem('token'))) {
    const path = from.fullPath || '/'
    next(path)
  }

  if (to.matched.some(record => record.meta.autorize)) {
    if (localStorage.getItem('token')) {
      next()
    } else {
      next({
        path: '/login',
        query: {
          nextUrl: to.fullPath,
        },
      })
    }
  } else {
    next()
  }
}

router.beforeEach((to, from, next) => store.dispatch(`swModal/${AUTO_MODAL_REMOVE_ALL}`).then(() => ifAuthenticated(to, from, next)))

export default router
