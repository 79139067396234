var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.activeTab !== null)?_c('b-tabs',{attrs:{"fill":_vm.fill,"justified":_vm.justified,"align":_vm.align,"pills":_vm.pills,"nav-class":"gap-0.34"},on:{"activate-tab":function($event){_vm.$emit('change', $event); _vm.changeUrl(_vm.showTabs.filter(function (ref) {
            var show = ref.show;

            return show;
})[$event].key)}},scopedSlots:_vm._u([{key:"tabs-end",fn:function(){return [_c('b-nav-item',{ref:"button",attrs:{"id":"addView","role":"presentation","link-classes":_vm.pills && 'btn btn-flat-primary btn-sm font-small-2 px-75 py-50'}},[_c('sw-icon',{staticClass:"mt-n25",class:_vm.pills && 'mr-n25',attrs:{"icon":"PlusIcon","size":_vm.pills && '12rem'}}),_c('span',[_vm._v(_vm._s(_vm.$t('View')))])],1)]},proxy:true},{key:"empty",fn:function(){return [_c('h4',{staticClass:"text-center text-secondary font-weight-normal"},[_vm._v(" "+_vm._s(_vm.$t('NoTabs'))+" ")])]},proxy:true}],null,false,3682148257),model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_vm._l((_vm.showTabs.filter(function (ref) {
            var show = ref.show;

            return show;
})),function(tab,i){return [_c('b-tab',{key:i,staticClass:"position-relative",attrs:{"title":_vm.$t(tab.title),"lazy":tab.lazy || _vm.lazy,"disabled":tab.disabled,"title-link-class":_vm.pills
            ? _vm.activeTab !== null && _vm.showTabs.filter(function (ref) {
                        var show = ref.show;

                        return show;
})[_vm.activeTab] && _vm.showTabs.filter(function (ref) {
                        var show = ref.show;

                        return show;
})[_vm.activeTab].key === tab.key
              ? 'btn btn-primary btn-sm font-small-2 px-75 py-50'
              : 'btn btn-outline-primary btn-sm font-small-2 px-75 py-50'
            : ''},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('sw-icon',{class:{
              'mr-n25': _vm.pills,
              'mt-n25': !tab.icon.startsWith('hi-')
            },attrs:{"icon":tab.icon,"size":_vm.pills ? '12' : '',"scale":_vm.pills ? '0.6' : ''}}),_c('span',[_vm._v(_vm._s(_vm.$t(tab.title)))]),(tab.loading)?_c('b-spinner',{staticClass:"ml-50",attrs:{"type":"border","small":""}}):_vm._e()]},proxy:true}],null,true)},[(tab.component)?_c(_vm.card ? 'card' : 'div',{tag:"component",staticClass:"h-100",attrs:{"no-body":_vm.noBody}},[_c(tab.component,_vm._b({tag:"component"},'component',Object.assign({}, {thread: _vm.thread}, (tab.meta.componentProps || {})),false))],1):_c('h4',{staticClass:"py-2 text-center text-primary"},[_vm._v(" "+_vm._s(_vm.$t('NoData'))+" 😔 ")])],1)]})],2):_vm._e(),_c('b-popover',{attrs:{"target":"addView","triggers":"focus","placement":"bottom","variant":"flat-primary","custom-class":"max-w-max"}},[_c('b-row',{staticClass:"overflow-auto",staticStyle:{"width":"40rem","max-width":"84vw","max-height":"60vh","row-gap":".1rem"}},_vm._l((_vm.showTabs),function(tab,j){return _c('b-col',{key:j,staticClass:"d-flex",attrs:{"cols":"12","md":"6"}},[_c('b-button',{staticClass:"d-flex flex-row align-items-center px-75 py-1 flex-grow-1",staticStyle:{"column-gap":".84rem"},attrs:{"variant":tab.show ? 'flat-primary' : 'flat-secondary'},on:{"click":function($event){return _vm.$emit('change-show', { tab: tab, value: !tab.show })}}},[_c('b-avatar',{attrs:{"variant":tab.show ? 'light-primary' : 'light-secondary',"rounded":"sm"}},[_c('sw-icon',{attrs:{"icon":tab.icon}})],1),_c('div',{staticClass:"d-flex flex-column align-items-start text-nowrap",staticStyle:{"row-gap":".34rem"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t(tab.title))+" ")]),_c('small',{staticClass:"text-secondary"},[_vm._v(" "+_vm._s(_vm.$t(tab.description))+" ")])])],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }