// eslint-disable-next-line import/no-cycle
import axiosIns from '@/libs/axios'
import { CONTACT_THREAD_LIST_BASE } from '@/@constants/mutations'
import * as fields from '@/@constants/fields'

const contactListBreadcrumb = {
  text: 'Contacts',
  active: false,
  to: '/contacts',
}

// eslint-disable-next-line camelcase
const contact_new = [
  // TODO:Make contact great again – new and edit
  {
    path: '/contacts/new',
    name: 'new-contacts',
    component: () => import('@/pages/contact.old/Create.vue'),
    meta: { autorize: true },
  },
  {
    path: '/contacts/edit/:contactId',
    name: 'edit-contacts',
    component: () => import('@/pages/contact.old/Create.vue'),
    meta: { autorize: true },
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: () => import('@/pages/contact.old/List.vue'),
    props: true,
    meta: {
      autorize: true,
      pageTitle: 'Contacts',
    },
  },
  {
    path: '/contact/:contactId',
    name: 'contact',
    component: () => import('@/pages/contact.old/Contact.vue'),
    meta: {
      autorize: true,
      pageTitle: 'Contact',
      breadcrumb: [
        contactListBreadcrumb,
        {
          active: true,
          request: {
            routeParam: 'contactId', endpoint: '/contacts/{contactId}', fields: ['firstName', 'lastName'], fields_load: fields.CONTACTS_SEARCH,
          },
        },
      ],
    },
  },
  {
    path: '/contact/:contactId/thread/:threadId?/:tab?/:itemId?',
    name: 'contact',
    component: () => import('@/pages/contact.old/Contact.vue'),
    meta: {
      autorize: true,
      pageTitle: 'Contact',
      breadcrumb: [
        contactListBreadcrumb,
        {
          active: true,
          request: {
            routeParam: 'contactId', endpoint: '/contacts/{contactId}', fields: ['firstName', 'lastName'], fields_load: fields.CONTACTS_SEARCH,
          },
        },
      ],
    },
  },

  {
    path: '/new_contact/:contactId',
    name: 'new_contact',
    component: () => import('@/pages/contact/Contact.vue'),
  },
  {
    path: '/new_contact/:contactId/thread/:threadId',
    name: 'new_contact',
    component: () => import('@/pages/contact/Contact.vue'),
    meta: { autorize: true },
    beforeEnter: async (to, from, next) => {
      try {
        const { contactId, threadId } = to.params

        const params = { fields_load: CONTACT_THREAD_LIST_BASE }

        const threads = await axiosIns.get(`1/contacts/${contactId}/threads`, { params })

        if (!threads?.data.data.items.length) next('/contacts')

        if (!threads?.data.data.items.find(({ id }) => id === threadId)) next(`/new_contact/${contactId}/thread/${threads.data.data.items[0].id}`)

        next()
      } catch (err) {
        next('/contacts')
      }
    },
  },
  {
    path: '/new_contact/:contactId/thread/:threadId/:tab',
    name: 'new_contact',
    component: () => import('@/pages/contact/Contact.vue'),
    meta: { autorize: true },
    beforeEnter: async (to, from, next) => {
      try {
        const { contactId, threadId, tab } = to.params

        const params = { fields_load: CONTACT_THREAD_LIST_BASE }

        const threads = await axiosIns.get(`1/contacts/${contactId}/threads`, { params })

        if (!threads?.data.data.items.length) next('/contacts')

        if (!threads?.data.data.items.find(({ id }) => id === threadId)) next(`/new_contact/${contactId}/thread/${threads.data.data.items[0].id}/${tab}`)

        next()
      } catch (err) {
        next('/contacts')
      }
    },
  },
]

// eslint-disable-next-line camelcase
export default contact_new
