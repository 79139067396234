// TODO:Events returns some id not returning id but object witch contains that id

/** Return { funnelProcess } */
const eChangeFunnelProcessStatus = 'eChangeFunnelProcessStatus'

/** Return { funnelProcessId } */
const eChangeFunnelProcess = 'eChangeFunnelProcess'

/** Return { threadId } */
const eNewThread = 'eNewThread'

/** Return { threadId } */
const eRemoveThread = 'eRemoveThread'

/** Return { thread } */
const eEditThread = 'eEditThread'

/** Return { thread } */
const eChangeThreadPrimaryStatus = 'eChangeThreadPrimaryStatus'

/** Return { threadId, ?taskId } */
const eNewThreadTask = 'eNewThreadTask'

/** Return { threadId, ?taskId } */
const eChangeThreadTaskStatus = 'eChangeThreadTaskStatus'

export {
  eChangeFunnelProcessStatus,
  eChangeFunnelProcess,
  eNewThread,
  eRemoveThread,
  eEditThread,
  eChangeThreadPrimaryStatus,
  eNewThreadTask,
  eChangeThreadTaskStatus,
}
