<template>
  <b-form @submit.prevent="validationForm">
    <div>
      <sw-select :name="$t('SelectForm')">
        <v-select
          v-model="selectedForm"
          :options="shortcuts"
          @input="selectForm"
        >
          <template #option="{ name, icon, shortcutSections }">
            <sw-icon class="mr-25"
                     :icon="icon"
            /> {{ $t(name) }} <b-badge variant="light-primary">
              {{ shortcutSections.length }}
            </b-badge>
          </template>
          <template #selected-option="{ name, icon, shortcutSections }">
            <sw-icon class="mr-25"
                     :icon="icon"
            /> {{ $t(name) }} <b-badge variant="light-primary">
              {{ shortcutSections.length }}
            </b-badge>
          </template>
          <template #no-options>
            {{ $t('NoOptions') }}
          </template>
        </v-select>
      </sw-select>
    </div>

    <validation-observer ref="newContactForm">
      <div v-if="isSectionAvailable('CONTACT_FORM')">
        <div class="divider text-primary divider-left">
          <div class="divider-text font-medium-2">
            {{ $t(getAvailableSection('CONTACT_FORM').name) }}
          </div>
        </div>

        <sw-select :name="$t('SelectContact')">
          <v-select
            v-model="selectedContact"
            :options="contactList"
            label="name"
            :filterable="false"
            @search="searchContacts"
            @option:selected="selectContact"
          >
            <template #no-options="{ search }">
              <span v-if="search.length">
                {{ $t('NoData') }}
              </span>
              <span v-else>
                {{ $t('TypeToSearch') }}
              </span>
            </template>

            <template #option="contact">
              <div class="d-flex align-items-center">
                <avatar
                  :user="contact"
                  class="mr-25"
                  show-name
                />
              </div>
            </template>

            <template #selected-option="contact">
              <div class="d-flex align-items-center">
                <avatar
                  :user="contact"
                  class="mr-25"
                  show-name
                />
              </div>
            </template>
          </v-select>
        </sw-select>

        <div>
          <div>
            <b-row>
              <!-- Name -->
              <b-col
                v-for="(field, fieldIndex) in getContactSectionFilteredFields(getAvailableSection('CONTACT_FORM').fields)"
                :key="`contact_form_${fieldIndex}`"
                :md="field === 'IDENTITY_DOCUMENT' ? 12 : 6"
                :lg="field === 'IDENTITY_DOCUMENT' ? 12 : 6"
              >
                <validation-provider
                  v-if="field === 'FIRST_NAME'"
                  v-slot="{ errors }"
                  :name="$t('contact.firstName')"
                >
                  <b-form-group :label="$t('contact.firstName')">
                    <b-form-input
                      v-model="contact.firstName"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>

                <div v-else-if="field === 'LAST_NAME'">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('contact.lastName')"
                  >
                    <b-form-group :label="$t('contact.lastName')">
                      <b-form-input
                        v-model="contact.lastName"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </div>

                <div v-if="field === 'EMAIL'">
                  <validation-provider
                    v-for="(email, index) in contact.mailAddresses"
                    v-slot="{ errors }"
                    :key="`email_address_${index}`"
                    :name="`${index + 1}.${$t('contact.email')}`"
                  >
                    <span>
                      {{ index + 1 }}. {{ $t('contact.email') }}
                      <feather-icon
                        v-if="index === 0"
                        v-b-tooltip
                        icon="AlertCircleIcon"
                        class="text-primary ml-25"
                        :title="$t('FirstMailIsPrimary')"
                      />
                    </span>

                    <b-input-group>
                      <b-form-input
                        v-model.trim="contact.mailAddresses[index]"
                        type="email"
                        :state="errors.length > 0 ? false:null"
                        @blur="checkExistedEmails"
                      />
                      <b-input-group-append v-if="index !== 0 || existedMail(email)">
                        <!-- Delete Email Buttons -->
                        <b-button
                          v-if="index !== 0"
                          variant="outline-danger"
                          class="btn-icon"
                          @click="$delete(contact.mailAddresses, index)"
                        >
                          <feather-icon
                            icon="TrashIcon"
                            size="12"
                          />
                        </b-button>
                        <!-- Email Exist Buttons -->
                        <b-button
                          v-if="existedMail(email)"
                          :id="`email_exist_button_${index}`"
                          variant="outline-warning"
                        >
                          <feather-icon
                            icon="AlertCircleIcon"
                            size="12"
                          />
                          <b-popover
                            :target="`email_exist_button_${index}`"
                            triggers="hover"
                          >
                            <template #title>
                              <span>{{ $t('TypedEmailExist') }}</span>
                            </template>
                            <div
                              v-for="existedContact in existedMail(email).contacts"
                              :key="`contact_${index}_${existedContact.id}`"
                            >
                              <router-link :to="{ name: 'contact', params: { contactId: existedContact.id } }">
                                {{ existedContact.firstName }} {{ existedContact.lastName }}
                              </router-link>
                            </div>
                            <!--                        test@mail-saleswizardapp.com-->
                          </b-popover>
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>

                  <b-button
                    size="sm"
                    class="mt-25"
                    variant="primary"
                    @click="contact.mailAddresses.push('')"
                  >
                    <feather-icon icon="PlusIcon" />
                    {{ $t('Add') }}
                  </b-button>
                </div>

                <div v-if="field === 'PHONE'">
                  <validation-provider
                    v-for="(phone, index) in contact.phoneNumbers"
                    v-slot="{ errors }"
                    :key="`phone_${index}`"
                    :name="`${index + 1}.${$t('contact.phone')}`"
                  >
                    <span
                      class="mt-1"
                    >{{ index + 1 }}. {{ $t('contact.phone') }} <feather-icon
                      v-if="index === 0"
                      v-b-tooltip
                      icon="AlertCircleIcon"
                      class="text-primary ml-25"
                      :title="$t('FirstPhoneIsPrimary')"
                    /></span>
                    <b-input-group>

                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-input-group>
                    <b-input-group>
                      <b-input-group-prepend>
                        <sw-select>
                          <v-select
                            v-model="contact.phoneNumbers[index].countryCode"
                            :options="countryCodes"
                            label="dial_code"
                            style="min-width: 125px"
                            class="mr-50"
                            :clearable="false"
                            @option:selected="checkExistedPhones"
                          />
                        </sw-select>
                      </b-input-group-prepend>
                      <cleave
                        v-model="contact.phoneNumbers[index].value"
                        :options="cleaveOptions.number"
                        class="form-control rounded"
                        :state="errors.length > 0 ? false:null"
                        @blur="checkExistedPhones"
                      />
                      <b-input-group-append v-if="index !== 0 || existedPhone(phone)">
                        <b-button
                          v-if="index !== 0"
                          variant="outline-danger"
                          class="btn-icon"
                          @click="$delete(contact.phoneNumbers, index)"
                        >
                          <feather-icon
                            icon="TrashIcon"
                            size="12"
                          />
                        </b-button>
                        <!-- Email Exist Buttons -->
                        <b-button
                          v-if="existedPhone(phone)"
                          :id="`phone_exist_button_${index}`"
                          variant="outline-warning"
                        >
                          <feather-icon
                            icon="AlertCircleIcon"
                            size="12"
                          />
                          <b-popover
                            :target="`phone_exist_button_${index}`"
                            triggers="hover"
                          >
                            <template #title>
                              <span>{{ $t('TypedPhoneExist') }}</span>
                            </template>
                            <div
                              v-for="existedContact in existedPhone(phone).contacts"
                              :key="`contact_phone_${index}_${existedContact.id}`"
                            >
                              <router-link :to="{ name: 'contact', params: { contactId: existedContact.id } }">
                                {{ existedContact.firstName }} {{ existedContact.lastName }}
                              </router-link>
                            </div>
                            <!--                        test@mail-saleswizardapp.com-->
                          </b-popover>
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>

                  <b-button
                    size="sm"
                    class="mt-25"
                    variant="primary"
                    @click="contact.phoneNumbers.push({
                      value: '',
                      countryCode: {
                        code: 'PL',
                        dial_code: '+48 ',
                        name: 'Poland',
                      },
                    })"
                  >
                    <feather-icon icon="PlusIcon" />
                    {{ $t('Add') }}
                  </b-button>

                </div>

                <div v-if="field.includes('IDENTITY_DOCUMENT')">
                  <b-row
                    v-for="(doc, index) in contact.personIdentityDocuments"
                    :key="`doc${index}`"
                    class="mt-1"
                  >
                    <b-col
                      v-if="getAvailableSection('CONTACT_FORM').fields.includes('IDENTITY_DOCUMENT_TYPE')"
                      md="6"
                      lg="4"
                      class="mb-50"
                    >

                      <validation-provider
                        v-slot="{ errors }"
                        :name="(index+1) + '. ' + $t('task.Type')"
                        :rules="isRequired('type')"
                      >
                        <sw-select :state="errors.length > 0 ? false:null"
                                   :name="$t('task.Type')"
                        >
                          <v-select
                            v-model="doc.type"
                            :options="documentTypes"
                            :state="errors.length > 0 ? false:null"
                          >
                            <template #selected-option="{ label }">
                              {{ $t(`contact.${label}`) }}
                            </template>
                            <template #option="{ label }">
                              {{ $t(`contact.${label}`) }}
                            </template>
                          </v-select>
                        </sw-select>

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>

                    <b-col
                      v-if="getAvailableSection('CONTACT_FORM').fields.includes('IDENTITY_DOCUMENT_NUMBER')"
                      md="6"
                      lg="4"
                      class="mb-50"
                    >

                      <validation-provider
                        v-slot="{ errors }"
                        :name="(index+1) + '. ' + $t('OrganizationDetails.addressNumber')"
                        :rules="isRequired('number')"
                      >
                        <b-form-group :label="$t('OrganizationDetails.addressNumber')">
                          <b-form-input
                            id="basicInput"
                            v-model="doc.number"
                            :state="errors.length > 0 ? false:null"
                          />
                        </b-form-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>

                    <b-col
                      v-if="getAvailableSection('CONTACT_FORM').fields.includes('IDENTITY_DOCUMENT_AUTHORITY_ISSUING')"
                      md="6"
                      lg="4"
                      class="mb-50"
                    >

                      <validation-provider
                        v-slot="{ errors }"
                        :name="(index+1) + '. ' + $t('contact.authorityIssuing')"
                        :rules="isRequired('authorityIssuing')"
                      >
                        <b-form-group :label="$t('contact.authorityIssuing')">
                          <b-form-input
                            id="basicInput"
                            v-model="doc.authorityIssuing"
                            :state="errors.length > 0 ? false:null"
                          />
                        </b-form-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>

                    <b-col
                      v-if="getAvailableSection('CONTACT_FORM').fields.includes('IDENTITY_DOCUMENT_RELEASE_DATE')"
                      md="6"
                      lg="4"
                      class="mb-50"
                    >

                      <validation-provider
                        v-slot="{ errors }"
                        :name="(index+1) + '. ' + $t('contact.releaseDate')"
                        :rules="isRequired('releaseDate')"
                      >
                        <sw-select :state="errors.length > 0 ? false:null"
                                   :name="$t('contact.releaseDate')"
                        >
                          <sw-select-fpr
                            :is-empty="doc.releaseDate"
                            @clear="doc.releaseDate = ''"
                          >
                            <flat-pickr
                              id="releaseDate"
                              v-model="doc.releaseDate"
                              :config="{ locale: getCalendarLocale($i18n.locale) }"
                              class="form-control"
                              :state="errors.length > 0 ? false:null"
                            />
                          </sw-select-fpr>
                        </sw-select>

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>

                    <b-col
                      v-if="getAvailableSection('CONTACT_FORM').fields.includes('IDENTITY_DOCUMENT_EXPIRATION_DATE')"
                      md="6"
                      lg="4"
                      class="mb-50"
                    >

                      <validation-provider
                        v-slot="{ errors }"
                        :name="(index+1) + '. ' + $t('contact.expirationDate')"
                      >
                        <sw-select :name="$t('contact.expirationDate')">
                          <sw-select-fpr
                            :is-empty="doc.expirationDate"
                            @clear="doc.expirationDate = ''"
                          >
                            <flat-pickr
                              id="expirationDate"
                              v-model="doc.expirationDate"
                              :config="{ locale: getCalendarLocale($i18n.locale) }"
                              class="form-control"
                              :state="errors.length > 0 ? false:null"
                            />
                          </sw-select-fpr>
                        </sw-select>

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </div>
              </b-col>

              <!-- Contact : end -->

            </b-row>

            <div v-if="isSectionAvailable('CONTACT_ADDITIONAL_FIELDS')">
              <div class="divider text-primary divider-left">
                <div class="divider-text font-medium-2">
                  {{ $t(getAvailableSection('CONTACT_ADDITIONAL_FIELDS').name) }}
                </div>
              </div>

              <b-alert show
                       variant="primary"
              >
                <div class="alert-body">
                  {{ $t('DisableField') }}
                </div>
              </b-alert>

              <b-row v-for="(field, index) in isSectionAvailable('CONTACT_ADDITIONAL_FIELDS').fields"
                     :key="`addon_field_${field}_${index}`"
              >
                <b-col sm="6"
                       md="6"
                >
                  <div v-if="options.CONTACT_ADDITIONAL_FIELDS.find(e => e.id === field)">
                    <b-form-group>
                      <b-input-group>
                        <b-input-group-append>
                          <b-form-checkbox v-model="options.CONTACT_ADDITIONAL_FIELDS.find(e => e.id === field).valueSet.isOn"
                                           variant="primary"
                                           class="mt-50 mr-75"
                          />
                        </b-input-group-append>
                        <b-form-input readonly
                                      :value="options.CONTACT_ADDITIONAL_FIELDS.find(e => e.id === field).name"
                        />
                      </b-input-group>
                    </b-form-group>
                  </div>
                </b-col>
                <b-col sm="6"
                       md="6"
                >
                  <addon-field-type v-if="options.CONTACT_ADDITIONAL_FIELDS.find(e => e.id === field) && options.CONTACT_ADDITIONAL_FIELDS.find(e => e.id === field).valueSet"
                                    :contact-addon-field-type="options.CONTACT_ADDITIONAL_FIELDS.find(e => e.id === field)"
                                    :value="options.CONTACT_ADDITIONAL_FIELDS.find(e => e.id === field).valueSet.value"
                                    :name="options.CONTACT_ADDITIONAL_FIELDS.find(e => e.id === field).name"
                                    :disabled="!options.CONTACT_ADDITIONAL_FIELDS.find(e => e.id === field).valueSet.isOn"
                                    :contact-addon-field-type-options="options.CONTACT_ADDITIONAL_FIELDS.find(e => e.id === field).valueSet.contactAddonFieldTypeOptions"
                                    @update-value="updateValue($event, options.CONTACT_ADDITIONAL_FIELDS.findIndex(e => e.id === field))"
                  />
                </b-col>
              </b-row>
            </div>

            <div v-if="isSectionAvailable('THREAD_FORM')">
              <div class="divider text-primary divider-left">
                <div class="divider-text font-medium-2">
                  {{ $t(getAvailableSection('THREAD_FORM').name) }}
                </div>
              </div>

              <!--              <sw-select :name="$t('SelectThread')">-->
              <!--                <v-select-->
              <!--                  id="offer-thread-1"-->
              <!--                  v-model="selectedThread"-->
              <!--                  :options="threadList"-->
              <!--                  label="name"-->
              <!--                  @option:selected="selectThread"-->
              <!--                >-->
              <!--                  <template #no-options>-->
              <!--                    <span>-->
              <!--                      {{ $t('NoData') }}-->
              <!--                    </span>-->
              <!--                  </template>-->
              <!--                </v-select>-->
              <!--              </sw-select>-->

              <b-row v-for="(thread, threadIndex) in threads"
                     :key="`thread_${threadIndex}`"
                     class="border p-75 mb-50 rounded"
              >
                <b-col sm="12">
                  <div class="font-medium-1 mb-50">{{ threadIndex + 1 }}. {{ $t('Thread') }}
                    <sw-icon class="text-danger cursor-pointer"
                             icon="TrashIcon"
                             @click="$delete(threads, threadIndex)"
                    />
                  </div>
                </b-col>
                <!-- Name -->
                <b-col md="6">
                  <sw-select :name="$t('Funnels')">
                    <v-select
                      v-model="thread.contactThreadFunnelType"
                      label="name"
                      :options="contactThreadFunnelTypes"
                      @option:selected="(option) => {
                        if (option && option.contactThreadFunnelTypePoints) {
                          const point = option.contactThreadFunnelTypePoints[0]
                          thread.contactThreadFunnelTypesPoint = point
                          thread.name = `${option.name} - ${point.name}`
                        }
                      }"
                    >
                      <template #no-options>
                        {{ $t('NoOptions') }}
                      </template>
                    </v-select>
                  </sw-select>
                </b-col>
                <b-col md="6">
                  <sw-select :name="$t('contact.contactThreadFunnelTypePoint')">
                    <v-select
                      v-model="thread.contactThreadFunnelTypesPoint"
                      :label="thread.contactThreadFunnelType ? 'name' : 'fullName'"
                      :options="thread.contactThreadFunnelType ? contactThreadFunnelTypesPoints.filter(e => e.typeId === getObjectId(thread.contactThreadFunnelType)) : contactThreadFunnelTypesPoints"
                      @option:selected="(option) => {
                        if (option) {
                          thread.name = option.fullName
                        }
                      }"
                    >
                      <template #no-options>
                        {{ $t('NoOptions') }}
                      </template>
                    </v-select>
                  </sw-select>
                </b-col>
                <b-col
                  v-for="(field, fieldIndex) in getAvailableSection('THREAD_FORM').fields"
                  :key="`thread_form_${fieldIndex}`"
                  md="12"
                >
                  <validation-provider
                    v-if="field.toUpperCase() === 'NAME'"
                    v-slot="{ errors }"
                    :name="$t('Name')"
                  >
                    <b-form-group :label="$t('Name')">
                      <b-form-input
                        v-model="thread.name"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col md="12">
                  <b-form-group>
                    <b-form-checkbox
                      v-model="thread.isPrimary"
                      variant="primary"
                      @change="changePrimaryThread($event, threadIndex)"
                    >{{ $t('SetAsPrimaryThread') }}</b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col sm="12"
                       class="text-center d-flex justify-content-center"
                >
                  <b-button variant="primary"
                            @click="threads.push({
                              name: defaultThreadPoint ? defaultThreadPoint.name : contactThreadFunnelTypesPoints[0].fullName,
                              isPrimary: false,
                              contactThreadFunnelType: getFunnelByPointId(defaultThreadPoint ? defaultThreadPoint : contactThreadFunnelTypesPoints[0].id),
                              contactThreadFunnelTypesPoint: defaultThreadPoint ? defaultThreadPoint : contactThreadFunnelTypesPoints[0],
                            })"
                  >
                    <sw-icon icon="PlusIcon"
                             class="mr-25"
                    />
                    {{ $t('Add') }}
                  </b-button>
                </b-col>
              </b-row>
            </div>

            <!-- Agreement -->
            <div v-if="isSectionAvailable('GENERATE_AGREEMENT')">
              <div class="divider text-primary divider-left">
                <div class="divider-text font-medium-2">
                  {{ $t(getAvailableSection('GENERATE_AGREEMENT').name) }}
                </div>
              </div>

              <b-row>
                <!-- Name -->
                <b-col md="12">
                  <sw-select>
                    <v-select
                      v-model="agreement.agreementTemplate"
                      :options="agreementTemplates"
                      label="name"
                      @option:selected="selectAgreement"
                    >
                      <template #no-options>
                        {{ $t('NoData') }}
                      </template>
                    </v-select>
                  </sw-select>
                </b-col>

                <b-col v-if="agreement.agreementTemplate"
                       sm="12"
                >
                  <b-form-radio
                    v-model="agreement.electronicSignatureType"
                    name="electronicSignatureType"
                    variant="primary"
                    value="WWW_SIGNATURE"
                    :disabled="!agreement.agreementTemplate.enableElectronicSignatureTypes.includes('WWW_SIGNATURE')"
                  >
                    {{ $t('EnableHandwrittenSignature') }}
                  </b-form-radio>
                </b-col>
              </b-row>

              <b-form-checkbox
                v-model="copyAgreementToAll"
                variant="primary"
              >
                {{ $t('CopyToAllThread') }}
              </b-form-checkbox>
            </div>
          </div>
        </div>

        <!-- Polls -->
        <div v-if="isSectionAvailable('GENERATE_POLL')">
          <div class="divider text-primary divider-left">
            <div class="divider-text font-medium-2">
              {{ $t(getAvailableSection('GENERATE_POLL').name) }}
            </div>
          </div>

          <b-alert show
                   variant="primary"
          >
            <div class="alert-body">
              {{ $t('DisableField') }}
            </div>
          </b-alert>

          <app-collapse v-for="(key, keyIndex) in Object.keys(getSortedPollFields)"
                        :key="`addon_field_container_${key}_${keyIndex}`"
                        class="mb-75"
          >
            <app-collapse-item :is-visible="true"
                               :title="key"
            >
              <b-row v-for="(field, index) in getSortedPollFields[key].mapKeys()"
                     :key="`addon_field_${field}_${index}`"
              >
                <b-col sm="6"
                       md="6"
                >
                  <div v-if="options.GENERATE_POLL.find(e => e.id === field)">
                    <b-form-group>
                      <b-input-group>
                        <b-input-group-append>
                          <b-form-checkbox v-model="options.GENERATE_POLL.find(e => e.id === field).valueSet.isOn"
                                           variant="primary"
                                           class="mt-50 mr-75"
                          />
                        </b-input-group-append>
                        <b-form-input readonly
                                      :value="options.GENERATE_POLL.find(e => e.id === field).label"
                        />
                      </b-input-group>
                    </b-form-group>
                  </div>
                </b-col>
                <b-col sm="6"
                       md="6"
                >
                  <poll-field-type v-if="options.GENERATE_POLL.find(e => e.id === field) && options.GENERATE_POLL.find(e => e.id === field).valueSet"
                                   :contact-addon-field-type="options.GENERATE_POLL.find(e => e.id === field)"
                                   :value="options.GENERATE_POLL.find(e => e.id === field).valueSet.value"
                                   :name="options.GENERATE_POLL.find(e => e.id === field).label"
                                   :disabled="!options.GENERATE_POLL.find(e => e.id === field).valueSet.isOn"
                                   :contact-addon-field-type-options="options.GENERATE_POLL.find(e => e.id === field).valueSet.contactAddonFieldTypeOptions"
                                   @update-value="updatePollValue($event, options.GENERATE_POLL.findIndex(e => e.id === field))"
                  />
                </b-col>
              </b-row>
            </app-collapse-item>

          </app-collapse>

          <b-form-checkbox
            v-model="copyPollToAll"
            variant="primary"
          >
            {{ $t('CopyToAllThread') }}
          </b-form-checkbox>
        </div>
        <!-- Note -->
        <div v-if="isSectionAvailable('GENERATE_NOTE')">
          <div class="divider text-primary divider-left">
            <div class="divider-text font-medium-2">
              {{ $t(getAvailableSection('GENERATE_NOTE').name) }}
            </div>
          </div>

          <b-row>
            <b-col
              v-for="(field, fieldIndex) in getAvailableSection('GENERATE_NOTE').fields"
              :key="`thread_form_${fieldIndex}`"
              lg="12"
            >
              <validation-provider
                v-if="field.toUpperCase() === 'TYPE'"
                :name="$t('note.Type')"
              >
                <sw-select
                  :name="$t('note.Type')"
                >
                  <v-select
                    v-model="note.type"
                    :options="noteTypes"
                    :reduce="nType => nType.id.toString()"
                    item-value="id"
                    item-text="name"
                    label="name"
                    :clearable="false"
                  >
                    <template #no-options>
                      {{ $t('NoOptions') }}
                    </template>
                    <template #option="{ name, icon }">
                      <feather-icon
                        :icon="icon"
                        size="16"
                        class="align-middle mr-50"
                      />
                      <span>{{ name }}</span>
                    </template>
                    <template #selected-option="{ name, icon }">
                      <feather-icon
                        :icon="icon"
                        size="16"
                        class="align-middle mr-50"
                      />
                      <span>{{ name }}</span>
                    </template>
                  </v-select>
                </sw-select>
              </validation-provider>

              <validation-provider
                v-else-if="field.toUpperCase() === 'CONTENT'"
                v-slot="{ errors }"
                :name="$t('Content')"
              >
                <b-form-group :label="$t('Content')">
                  <b-form-textarea
                    v-model="note.content"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-form-checkbox
            v-model="copyNoteToAll"
            variant="primary"
          >
            {{ $t('CopyToAllThread') }}
          </b-form-checkbox>
        </div>

        <!-- Note Agree -->
        <!--      <b-card>-->
        <!--        <b-overlay-->
        <!--          :show="loading"-->
        <!--          no-wrap-->
        <!--          spinner-variant="primary"-->
        <!--        />-->
        <!--      </b-card>-->
      </div>
    </validation-observer>

    <div class="pb-1">
      <b-button
        type="submit"
        variant="primary"
        class="mt-25"
      >
        {{ $t('Save') }}
      </b-button>
    </div>
  </b-form>
</template>

<script>
import {
  BForm, BInputGroupAppend, BPopover, VBTooltip, BInputGroup,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import { countryCodes } from '@/@data/countryCodes'
import Cleave from 'vue-cleave-component'
// import PhoneNumber from '@core/components/@sw/PhoneNumber.vue'
import axiosIns from '@/libs/axios'
import { documentTypes } from '@/@data/contact'
import flatPickr from 'vue-flatpickr-component'
import * as shortcutTypes from '@/@types/shortcutTypes'
import AddonFieldType from '@/views/core/singleContact/AddonFieldType.vue'
import PollFieldType from '@/views/core/polls/PollFieldType.vue'
import {
  CLOSE_MODAL,
  GET_CONTACT_ADDON_FIELDS, GET_NOTES,
  GET_PRIVATE_SETTINGS,
  GET_THREAD_TAGS,
} from '../../@constants/mutations'

export default {
  name: 'CreateContact',
  components: {
    AddonFieldType,
    PollFieldType,
    Cleave,
    vSelect,
    ValidationObserver,
    ValidationProvider,
    BForm,
    BInputGroup,
    BInputGroupAppend,
    BPopover,
    flatPickr,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  computed: {
    ...mapGetters({
      reloadContent: 'modal/getReloadContent',
      currentUser: 'auth/getCurrentUser',
    }),
    locale() {
      return this.$i18n.locale
    },
    getSortedPollFields() {
      const fieldIds = this.isSectionAvailable('GENERATE_POLL').fields
      if (!fieldIds?.length || !this.options.GENERATE_POLL?.length) {
        return {}
      }
      const items = fieldIds.map(field => this.options.GENERATE_POLL.find(e => e.id === field))

      const groupedItemsByPoll = {}

      items.forEach(pollField => {
        if (!groupedItemsByPoll[pollField?.pollName]) {
          groupedItemsByPoll[pollField?.pollName] = []
        }

        groupedItemsByPoll[pollField?.pollName].push(pollField)
      })

      Object.keys(groupedItemsByPoll).forEach(groupKey => {
        groupedItemsByPoll[groupKey].sort(((a, b) => a.position - b.position))
      })

      return groupedItemsByPoll
    },
  },
  props: {},
  data: () => ({
    selectedContact: '',
    selectedThread: '',
    contactList: [],
    noteTypes: [],
    selectedContactAddonFields: [],
    threadList: [],
    selectedForm: null,
    loading: true,
    shortcuts: [],
    documentTypes,
    options: {
      CONTACT_ADDITIONAL_FIELDS: [],
      GENERATE_POLL: [],
    },
    sections: shortcutTypes.shortcutTypes,
    note: {
      type: '',
      content: '',
    },
    // Contact fields
    existedPhones: [],
    existedEmails: [],
    contact: {
      locale: 'pl_pl',
      mailAddresses: [''],
      firstName: '',
      lastName: '',
      phoneNumbers: [
        {
          value: '',
          countryCode: {
            code: 'PL',
            dial_code: '+48 ',
            name: 'Poland',
          },
        },
      ],
      deliveryAddresses: [],
      position: '',
      status: '',
      userGroups: [],
      personIdentityDocuments: [
        {
          type: 'ID_CARD',
          number: '',
          authorityIssuing: '',
          releaseDate: '',
          expirationDate: '',
        },
      ],
      contactTypes: [],
    },
    countryCodes,
    cleaveOptions: {
      number: {
        delimiter: ' ',
        numeral: true,
        blocks: [3, 3, 3],
      },
    },
    tags: [],
    contactStatuses: [],
    // Contact Thread
    threads: [
      {
        name: '',
        isPrimary: true,
        contactThreadFunnelTypesPoint: '',
        contactThreadFunnelType: '',
      },
    ],
    contactThreadFunnelTypesPoint: '',
    contactThreadFunnelTypesPoints: [],
    contactThreadFunnelTypes: [],
    // Agreement
    agreement: {
      agreementTemplate: '',
      electronicSignatureType: '',
    },
    agreementTemplates: [],

    copyPollToAll: true,
    copyNoteToAll: true,
    copyAgreementToAll: true,
    defaultThreadPoint: null,
  }),
  watch: {
    reloadContent(newValue) {
      if (newValue) {
        console.log(newValue)
      }
    },
  },
  async mounted() {
    this.sections.forEach(section => {
      if (section?.request) {
        this.selectType(section)
      }
    })
    await Promise.all([
      this.loadNoteTypes(),
      this.loadTemplates(),
      this.loadContactStatuses(),
      this.loadThreadStatuses(),
      this.init(),
    ])
  },
  methods: {
    changePrimaryThread(value, index) {
      if (value) {
        this.threads.forEach((thread, threadIndex) => {
          if (index === threadIndex) {
            this.threads[threadIndex].isPrimary = true
          } else {
            this.threads[threadIndex].isPrimary = false
          }
        })
      }
    },
    async loadNoteTypes() {
      const noteTypes = await this.$store.dispatch(`typeSettings/${GET_NOTES}`)
      this.noteTypes = noteTypes
    },
    getContactSectionFilteredFields(fields = []) {
      const notAllowed = [
        'IDENTITY_DOCUMENT_TYPE',
        'IDENTITY_DOCUMENT_NUMBER',
        'IDENTITY_DOCUMENT_AUTHORITY_ISSUING',
        'IDENTITY_DOCUMENT_RELEASE_DATE',
        'IDENTITY_DOCUMENT_EXPIRATION_DATE',
      ]
      const allFields = fields.filter(field => !notAllowed.includes(field))
      const notAllowedFields = fields.filter(field => notAllowed.includes(field))

      return [...allFields, notAllowedFields.length ? 'IDENTITY_DOCUMENT' : ''].filter(Boolean)
    },
    selectAgreement(agreement) {
      if (agreement?.enableElectronicSignatureTypes?.includes('WWW_SIGNATURE')) {
        this.agreement.electronicSignatureType = 'WWW_SIGNATURE'
      } else {
        this.agreement.electronicSignatureType = ''
      }
    },
    async searchContacts(search, isLoading) {
      this.contactList = await this.selectSearch(search, isLoading, 'SINGLE_CONTACT', 'firstName,lastName', '1/contacts', 25)
    },
    async loadThreads(contactId) {
      const response = await axiosIns.get(`1/contacts/${contactId}/threads`, { params: { fields_load: 'THREADS_SEARCH' } })

      this.threadList = response.data?.data?.items
    },
    // async selectThread(thread) {
    //   if (thread) {
    //     this.thread.name = thread.name
    //     this.thread.isPrimary = thread.isPrimary
    //     this.contactThreadFunnelTypesPoint = thread.contactThreadFunnelProcessSelected.contactThreadFunnelTypePoint
    //   }
    // },
    getFunnelByPointId(pointId) {
      const point = this.contactThreadFunnelTypesPoints.find(e => e.id === pointId)
      const type = this.contactThreadFunnelTypes.find(e => e.id === point?.typeId)
      return type
    },
    async loadThreadStatuses() {
      try {
        const resp = await axiosIns.get('1/settings/contactThreadFunnelTypes', { params: { fields_load: this.$fields.SETTINGS_FUNNEL_TYPE, orderBy: 'position.asc' } })

        if (resp?.data?.data?.items) {
          this.contactThreadFunnelTypesPoints = (resp.data.data.items || []).flatMap(type => type.contactThreadFunnelTypePoints.map(point => ({ ...point, typeId: type.id, fullName: `${type.name} - ${point.name}` })))
          this.contactThreadFunnelTypes = (resp.data.data.items || [])

          // eslint-disable-next-line prefer-destructuring
          this.$nextTick(() => {
            const type = this.contactThreadFunnelTypes[0]
            const point = type.contactThreadFunnelTypePoints[0]
            this.$set(this.threads[0], 'contactThreadFunnelTypesPoint', point)
            this.$set(this.threads[0], 'contactThreadFunnelType', type)
            this.threads[0].name = `${type.name} - ${point.name}`
          })
        }
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    selectForm(e) {
      if (!e) return

      e.shortcutSections.forEach(section => {
        if (section.fieldsConfig?.defaultNote) {
          this.note.type = section.fieldsConfig.defaultNote
        }

        if (section.fieldsConfig?.defaultThreadPoint) {
          this.defaultThreadPoint = this.contactThreadFunnelTypesPoints.find(a => a.id === section.fieldsConfig.defaultThreadPoint)
          this.threads[0].contactThreadFunnelTypesPoint = this.defaultThreadPoint
          this.threads[0].name = this.defaultThreadPoint?.name
        }
      })
    },
    // eslint-disable-next-line consistent-return
    async loadTemplates() {
      if (!this.checkRequiredModule('agreementModule')) {
        return null
      }
      const params = {
        fields_load: 'SETTINGS_AGREEMENTS_TEMPLATES',
      }

      const response = await axiosIns.get('1/settings/agreementTemplates', { params })
      this.agreementTemplates = response?.data?.data?.items
      if (this.agreementTemplates?.length) {
        // eslint-disable-next-line prefer-destructuring
        this.agreement.agreementTemplate = this.agreementTemplates[0]
        this.selectAgreement(this.agreementTemplates[0])
      }
    },
    updateValue(data = { value: '', method: '', field: '' }, fieldIndex = -1) {
      if (fieldIndex > -1) {
        this.options.CONTACT_ADDITIONAL_FIELDS[fieldIndex].valueSet[data.field] = data.value
      }
    },
    updatePollValue(data = { value: '', method: '', field: '' }, fieldIndex = -1) {
      if (fieldIndex > -1) {
        this.options.GENERATE_POLL[fieldIndex].valueSet[data.field] = data.value
      }
    },
    async selectType(section) {
      if (section?.request) {
        const response = await axiosIns({
          url: section.request.url,
          params: section.request.params,
          method: 'GET',
        })
        if (section.key === 'GENERATE_POLL') {
          this.options[section.key] = response.data.data.items.map(e => e.pollFields.sort(((a, b) => a.position - b.position)).map(field => {
            const fieldName = field.translations[0]?.name
            return {
              ...field,
              label: `${fieldName}`,
              pollId: e.id,
              pollName: e.name,
              valueSet: {
                value: '',
                contactAddonFieldTypeOptions: [],
                contactAddonFieldTextOptions: [],
                isOn: true,
              },
            }
          })).flatMap(e => e)

          this.options.GENERATE_POLL.sort((a, b) => {
            const nameComparison = a.pollName.localeCompare(b.pollName)
            return nameComparison !== 0 ? nameComparison : a.position - b.position
          })
        } else {
          this.options[section.key] = response.data.data.items.map(e => ({
            ...e,
            label: e[section.request.label],
            valueSet: {
              value: '',
              contactAddonFieldTypeOptions: [],
              contactAddonFieldTextOptions: [],
              isOn: true,
            },
          }))
        }
      }
    },
    getAvailableSection(sectionKey) {
      // selectedForm => id, name, icon, shortcutSections => { type, field, name }
      if (this.selectedForm) {
        return this.selectedForm.shortcutSections.find(section => section.type === sectionKey)
      }

      return null
    },
    isSectionAvailable(sectionKey) {
      // selectedForm => id, name, icon, shortcutSections => { type, field, name }
      if (this.selectedForm) {
        return this.selectedForm.shortcutSections.find(section => section.type === sectionKey)
      }

      return false
    },
    existedPhone(phone) {
      return this.existedPhones.filter(ob => ob.contacts.find(obContact => obContact.id !== this.contact?.id)).find(contact => contact.phone === ((phone.countryCode?.dial_code || '') + phone.value))
    },
    existedMail(email) {
      return this.existedEmails.filter(ob => ob.contacts.find(obContact => obContact.id !== this.contact?.id)).find(contact => contact.mail === email)
    },
    checkExistedPhones() {
      this.existedPhones = []
      const { phoneNumbers } = this.contact
      const preparedPhones = phoneNumbers.filter(phone => phone.value).map(phone => (phone.countryCode?.dial_code || '') + phone.value)
      axiosIns.post('1/contacts/getExistsByPhones', preparedPhones)
        .then(res => {
          this.existedPhones = res.data.data?.exists || []
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
    checkExistedEmails() {
      this.existedEmails = []
      const { mailAddresses } = this.contact
      let preparedMail = mailAddresses.filter(mail => !!mail)
      preparedMail = preparedMail?.filter(Boolean)
      axiosIns.post('1/contacts/getExistsByEmails', preparedMail)
        .then(res => {
          this.existedEmails = res.data.data?.exists || []
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
    async getGlobalSettings() {
      try {
        const reponse = await this.$store.dispatch(`system/${GET_PRIVATE_SETTINGS}`)

        Object.assign(this, reponse.data.item)
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    /* eslint-disable */
    isRequired() {
      return 'required'
    },
    async selectContact(contact) {
      if (contact) {
        await this.loadThreads(contact.id)
        this.$store.dispatch(`addonFields/${GET_CONTACT_ADDON_FIELDS}`, contact.id)
          .then(res => {
            this.selectedContactAddonFields = res
            res.forEach(({
              contactAddonFieldType, id, value, contactAddonFieldTypeOptions,
            }) => {
              const index = this.options.CONTACT_ADDITIONAL_FIELDS.findIndex(e => e.id === contactAddonFieldType.id)
              if (index > -1) {
                this.options.CONTACT_ADDITIONAL_FIELDS[index].valueSet = {
                  ...this.options.CONTACT_ADDITIONAL_FIELDS[index].valueSet, value, contactAddonFieldTypeOptions, id, isOn: true,
                }
              }
            })
          })

        this.contact.firstName = contact.firstName
        this.contact.lastName = contact.lastName
        this.contact.status = contact.status

        if (contact.phoneNumbers?.length) {
          this.contact.phoneNumbers = contact.phoneNumbers.map(phoneObject => {
            const { phone } = phoneObject
            const phoneItem = {
              value: phone || '',
              countryCode: {
                code: 'PL',
                dial_code: '+48 ',
                name: 'Poland',
              },
            }
            const code = countryCodes.find(({ dial_code }) => phone.includes(dial_code.trim()))
            if (code) {
              phoneItem.value = phone.replace(code.dial_code.trim(), '')
              phoneItem.countryCode = code
            }
            phoneItem.isPrimary = contact.phone === phoneObject.phone

            return phoneItem
          })
          this.contact.phoneNumbers.sort((a, b) => Number(b?.isPrimary) - Number(a?.isPrimary))
        } else {
          this.contact.phoneNumbers = [
            {
              value: '',
              countryCode: {
                code: 'PL',
                dial_code: '+48 ',
                name: 'Poland',
              },
            },
          ]
        }
        if (contact.mailAddresses?.length) {
          this.contact.mailAddresses = contact.mailAddresses.filter(item => item.mail !== contact.email).map(email => email.mail)
          if (contact.email) this.contact.mailAddresses.unshift(contact.email)
        } else if (contact.email) this.contact.mailAddresses.unshift(contact.email)
        else this.contact.mailAddresses.push('')

        this.contact.personIdentityDocuments = contact.personIdentityDocuments.map(doc => {
          // eslint-disable-next-line
            const expirationDate = typeof doc.expirationDate === 'string' ? doc.expirationDate : (doc.expirationDate?.date || '')

          // eslint-disable-next-line
            const releaseDate = typeof doc.releaseDate === 'string' ? doc.releaseDate : (doc.releaseDate?.date || '')

          return { ...doc, expirationDate, releaseDate }
        })
      }
    },
    async init() {
      this.loading = true
      await this.onLoadShortcuts()
      // this.getGlobalSettings()
      // this.onGetContactTags()
      // this.loadGroups()
      //
      // await this.$store.dispatch(`typeSettings/${GET_CONTACT_STATUSES}`)
      //   .then(() => {
      //     // eslint-disable-next-line prefer-destructuring
      //     this.contact.status = this.statuses[0]?.id || ''
      //   })
    },
    async loadContactStatuses() {
      try {
        const resp = await axiosIns.get('1/settings/contactStatuses', { params: { fields_load: this.$fields.SETTINGS_CONTACTS_STATUSES, orderBy: 'position.asc' } })

        if (resp?.data?.data?.items) this.contactStatuses = resp.data.data.items || []
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    async onLoadShortcuts() {
      try {
        const params = {
          orderBy: 'id.desc',
          fields_load: 'SHORTCUTS',
        }
        const response = await axiosIns.get('1/shortcuts', { params })
        this.shortcuts = response.data.data.items
        if (this.shortcuts.length) {
          // eslint-disable-next-line prefer-destructuring
          this.selectedForm = this.shortcuts[0]
          this.selectForm(this.shortcuts[0])
        }
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    onGetContactTags() {
      this.$store.dispatch(`typeSettings/${GET_THREAD_TAGS}`)
        .then(res => {
          this.tags = res.data.items
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.newContactForm.validate().then(success => {
          if (success) {
            resolve(true)
            this.submitForm()
          } else {
            reject()
          }
        })
      })
    },
    async saveContactAddonFields(contactId) {
      // eslint-disable-next-line no-unreachable
      const contactAddonFieldPayload = this.options.CONTACT_ADDITIONAL_FIELDS
        .filter(a => this.isSectionAvailable('CONTACT_ADDITIONAL_FIELDS')?.fields.includes(a.id)).map(contactAddonFieldType => {
          const {
            value, contactAddonFieldTypeOptions, id, isOn,
          } = contactAddonFieldType.valueSet
          if (!isOn) {
            return false
          }

          const payload = {
            contactAddonFieldType: contactAddonFieldType.id,
            value,
            contactAddonFieldTypeOptions: [],
          }

          if (id) {
            payload.id = id
          }

          if (['CHECKBOX'].includes(contactAddonFieldType.type)) payload.value = String(Boolean(value))
          if (['TEXT_ARRAY'].includes(contactAddonFieldType.type)) payload.contactAddonFieldTypeOptions = Array.isArray(contactAddonFieldTypeOptions) ? contactAddonFieldTypeOptions.mapKeys('id') : [contactAddonFieldTypeOptions.id]
          if (['TEXT_MULTIPLE_ARRAY'].includes(contactAddonFieldType.type)) payload.contactAddonFieldTypeOptions = contactAddonFieldTypeOptions.map(o => o.id || o)
          if (['RADIAL'].includes(contactAddonFieldType.type)) payload.value = String(value?.name || value)
          if (['DATE'].includes(contactAddonFieldType.type)) {
            if (!String(value).includes('-')) {
              const year = value.slice(0, 4)
              const month = value.length > 4 ? value.slice(4, 6).padStart(2, '0') : '01'
              const day = value.length > 6 ? value.slice(6, 8).padStart(2, '0') : '01'

              payload.value = `${year}-${month}-${day}`
            } else {
              payload.value = String(value)
            }
          }

          if (payload.value === '-01-01') {
            payload.value = ''
          }
          // if (['TOOLTIP'].includes(contactAddonFieldType.type)) payload.contactAddonFieldTextOptions = contactAddonFieldTextOptions

          return payload
        }).filter(Boolean)

      // eslint-disable-next-line no-unreachable
      const update = contactAddonFieldPayload.filter(e => e?.id)
      const create = contactAddonFieldPayload.filter(e => !e?.id)

      if (create.length) {
        await axiosIns({
          method: 'POST',
          data: create,
          url: `1/contact/${contactId}/addonFields`,
        })
      }

      if (update.length) {
        await axiosIns({
          method: 'PATCH',
          data: update,
          url: `1/contact/${contactId}/addonFields`,
        })
      }

      // Return contactId
      return true
    },
    async saveThread(contactId) {
      const threadsPayload = this.threads.map(thread => ({
        contact: contactId,
        name: thread.name,
        assignedLeadUser: this.getObjectId(this.currentUser),
        predictedValueCurrency: 'PLN',
        assignedUsers: [this.getObjectId(this.currentUser)],
        predictedValue: 0,
        threadFunnelPoint: this.getObjectId(thread.contactThreadFunnelTypesPoint) || this.getObjectId(this.contactThreadFunnelTypesPoints[0]),
        checklistList: [],
        contactThreadAssignedContacts: [],
        isPrimary: thread.isPrimary,
      }))

      // let threadPayload =

      const method = 'POST'
      // if (this.selectedThread?.id) {
      //   threadPayload = {
      //     contact: contactId,
      //     name: this.thread.name,
      //     isPrimary: this.thread.isPrimary,
      //     threadFunnelPoint: this.getObjectId(this.contactThreadFunnelTypesPoint) || this.getObjectId(this.contactThreadFunnelTypesPoints[0]),
      //   }
      //   threadPayload.id = this.getObjectId(this.selectedThread)
      //   method = 'PATCH'
      // }

      const response = await axiosIns({
        method,
        data: threadsPayload,
        url: `1/contacts/${contactId}/threads`,
      })

      if (!this.selectedThread) {
        await Promise.all(response.data.data.items.map((e, index) => axiosIns.post(`1/contacts/threads/${e.id}/funnelProcesses`, {
            contactThreadFunnelTypePoint: threadsPayload[index].threadFunnelPoint,
            status: 'OPEN',
        })))
      }

      const items = response.data?.data?.items?.map(respItem => respItem?.id).filter(Boolean)
      const primaryIndex = threadsPayload.findIndex(e => e.isPrimary) || 0

      // Return contactId
      return [items, primaryIndex]
    },
    async saveAgreement(contactId, threadIds = []) {
      const promises = threadIds.map(async id => {
        const agreementPayload = {
          agreementTemplate: this.getObjectId(this.agreement.agreementTemplate),
          contactThreadAgreementAssignedContacts: [{ contact: contactId, part: 100 }],
          contactThread: id,
          signerUser: this.getObjectId(this.currentUser),
        }

        if (this.agreement.electronicSignatureType) {
          agreementPayload.electronicSignatureType = this.agreement.electronicSignatureType
          agreementPayload.allowElectronicSignature = true
        }
        return axiosIns.post(`1/contacts/${contactId}/threads/${id}/agreements`, agreementPayload)
      })
      await Promise.all(promises)
    },
    async savePollFields(contactId, threadIds = []) {
      const requiredPolls = [
          ...new Set(this.options.GENERATE_POLL
            .filter(a => this.isSectionAvailable('GENERATE_POLL')?.fields.includes(a.id))
            .map(pollField => pollField.pollId))
      ]

        // eslint-disable-next-line no-unused-vars
        const contactAddonFieldPayload = this.options.GENERATE_POLL
            // eslint-disable-next-line array-callback-return
            .filter(a => this.isSectionAvailable('GENERATE_POLL')?.fields.includes(a.id)).map(contactAddonFieldType => {
                const {
                    value, contactAddonFieldTypeOptions, id, isOn,
                } = contactAddonFieldType.valueSet
                if (!isOn) {
                    return false
                }

                const payload = {
                    pollField: contactAddonFieldType.id,
                    value,
                    pollFieldOptions: [],
                    pollId: contactAddonFieldType.pollId,
                }

                if (id) {
                    payload.id = id
                }

                if (['CHECKBOX'].includes(contactAddonFieldType.type)) payload.value = String(Boolean(value) ? 1 : 0)
                if (['TEXT_ARRAY'].includes(contactAddonFieldType.type)) payload.pollFieldOptions = Array.isArray(contactAddonFieldTypeOptions) ? contactAddonFieldTypeOptions.mapKeys('id') : [contactAddonFieldTypeOptions.id]
                if (['TEXT_MULTIPLE_ARRAY'].includes(contactAddonFieldType.type)) payload.pollFieldOptions = contactAddonFieldTypeOptions.map(o => o.id || o)
                if (['RADIAL'].includes(contactAddonFieldType.type)) payload.value = String(value?.name || value)
                if (['DATE'].includes(contactAddonFieldType.type)) {
                    if (!String(value).includes('-')) {
                        const year = value.slice(0, 4)
                        const month = value.length > 4 ? value.slice(4, 6).padStart(2, '0') : '01'
                        const day = value.length > 6 ? value.slice(6, 8).padStart(2, '0') : '01'

                        payload.value = `${year}-${month}-${day}`
                    } else {
                        payload.value = String(value)
                    }
                }

                if (payload.value === '-01-01') {
                    payload.value = ''
                }

                return payload
            }).filter(Boolean)

        threadIds.forEach(async id => {
            const response = await axiosIns.post(`1/contacts/${contactId}/threads/${id}/polls`, requiredPolls.map(pollId => ({ poll: pollId })))

            const pollMapping = {}
            response.data.data.items.forEach((responseItem, index) => {
                pollMapping[requiredPolls[index]] = null
                pollMapping[requiredPolls[index]] = responseItem.id
            })

            await Promise.all(Object.keys(pollMapping).map(e => {
                return axiosIns.put(`1/contacts/${contactId}/threads/${id}/polls/${pollMapping[e]}/fields`, contactAddonFieldPayload.filter(a => a.pollId === e))
            }))
        })

      // eslint-disable-next-line no-unreachable
      // const update = contactAddonFieldPayload.filter(e => e?.id)
      // const create = contactAddonFieldPayload.filter(e => !e?.id)
    },
    async saveNote(contactId, threadIds = []) {
      const formatedContent = this.note.content.replaceAll('\n', '<br />')

      const promises = threadIds.map(async id => {
        const notePayload = {
          name: '',
          contactThreadNoteType: this.getObjectId(this.note.type),
          content: formatedContent,
          sharedRanks: [],
        }

        return axiosIns.post(`1/contacts/threads/${id}/notes`, notePayload)
      })
      await Promise.all(promises)
    },
    async saveContact() {
      const contactPayload = {
        firstName: this.contact.firstName,
        lastName: this.contact.lastName,
        mailAddresses: this.contact.mailAddresses,
        mailAddress: this.contact.mailAddresses[0] || null,
        phoneNumbers: this.contact.phoneNumbers.filter(phone => phone.value && !this.existedPhone(phone))
          .map(phone => ((phone.countryCode?.dial_code || '') + phone.value).replaceAll('  ', ' ')),
        personIdentityDocuments: this.contact.personIdentityDocuments.filter(doc => doc.expirationDate && doc.type && doc.number && doc.authorityIssuing && doc.releaseDate),
        isOrganization: this.selectedContact?.isOrganization || false,
        status: this.getObjectId(this.selectedContact.status || this.contactStatuses[0]),
        agreeMarketing: this.selectedContact?.agreeMarketing || false,
      }
      contactPayload.phoneNumber = contactPayload.phoneNumbers?.length ? (contactPayload.phoneNumbers[0]).replaceAll('  ', ' ') : null

      let method = 'POST'
      if (this.selectedContact) {
        contactPayload.id = this.getObjectId(this.selectedContact)
        method = 'PATCH'
      }

      const response = await axiosIns({
        method,
        data: contactPayload,
        url: '1/contacts',
      })

      // Return contactId
      return response?.data?.data?.items[0]?.id
    },
    // eslint-disable-next-line consistent-return
    async submitForm() {
      // console.log(999)
      // this.savePollFields(0, [])
      // return null
      // eslint-disable-next-line no-unreachable
      this.loading = true

      const contactId = await this.saveContact()
      if (!contactId) return null

      if (this.isSectionAvailable('CONTACT_ADDITIONAL_FIELDS')) {
        await this.saveContactAddonFields(contactId)
      }

      // eslint-disable-next-line no-unreachable
      let threadIds = []
      let primaryIndex = 0
      if (this.isSectionAvailable('THREAD_FORM')) {
        [threadIds, primaryIndex] = await this.saveThread(contactId)
        if (!threadIds) return null
      }

      if (this.isSectionAvailable('GENERATE_AGREEMENT')) {
        await this.saveAgreement(contactId, this.copyAgreementToAll ? threadIds : [threadIds[primaryIndex]])
      }

      if (this.isSectionAvailable('GENERATE_NOTE')) {
        await this.saveNote(contactId, this.copyNoteToAll ? threadIds : [threadIds[primaryIndex]])
      }

      if (this.isSectionAvailable('GENERATE_POLL')) {
        await this.savePollFields(contactId, this.copyPollToAll ? threadIds : [threadIds[primaryIndex]])
      }

      this.showToast('success', this.$i18n.t('success.contactUpdated'))

      if (contactId) {
        this.$router.push(`/contact/${contactId}`)
      }
      this.$emit('close-modal', null)
      this.$store.commit(`modal/${CLOSE_MODAL}`)
    },
    closeModal() {
            this.selectedContact = ''
            this.selectedThread = ''
            this.contactList = []
            this.noteTypes = []
            this.selectedContactAddonFields = []
            this.threadList = []
            this.selectedForm = null
            this.loading = true
            this.shortcuts = []
            this.options = {
              CONTACT_ADDITIONAL_FIELDS: [],
              GENERATE_POLL: [],
            }
            this,note = {
              type: '',
              content: '',
            }
        // Contact fields
        this.existedPhones = []
            this.existedEmails = []
            this.contact = {
            locale: 'pl_pl',
                mailAddresses: [''],
                firstName: '',
                lastName: '',
                phoneNumbers: [
                {
                    value: '',
                    countryCode: {
                        code: 'PL',
                        dial_code: '+48 ',
                        name: 'Poland',
                    },
                },
            ],
                deliveryAddresses: [],
                position: '',
                status: '',
                userGroups: [],
                personIdentityDocuments: [
                {
                    type: 'ID_CARD',
                    number: '',
                    authorityIssuing: '',
                    releaseDate: '',
                    expirationDate: '',
                },
            ],
                contactTypes: [],
        },
            this.cleaveOptions = {
            number: {
                delimiter: ' ',
                    numeral: true,
                    blocks: [3, 3, 3],
            },
        }
        this.tags = [],
            this.contactStatuses = []
            // Contact Thread
            this.threads = [
            {
                name: '',
                isPrimary: true,
                contactThreadFunnelTypesPoint: '',
                contactThreadFunnelType: '',
            },
        ],
            this.contactThreadFunnelTypesPoint = ''
            this.contactThreadFunnelTypesPoints = []
            this.contactThreadFunnelTypes = []
            // Agreement
            this.agreement = {
            agreementTemplate: '',
                electronicSignatureType: '',
        },
        this.agreementTemplates = []

            this.copyPollToAll = true
            this.copyNoteToAll = true
            this.copyAgreementToAll = true
            this.defaultThreadPoint = null
    },
  },
}
</script>

<style scoped>

</style>
