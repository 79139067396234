<template>
  <validation-observer
    ref="newScheduleForm"
    v-slot="{invalid}"
    tag="form"
  >
    <!--      Thread      -->
    <validation-provider
      v-slot="{ errors }"
      rules="required"
      :name="$t('SelectContacts')"
    >
      <sw-select
        :name="$t('SelectContacts')"
      >
        <v-select
          v-model="newSchedule.payer"
          :options="contactThreadList"
          label="name"
          :filterable="false"
          :clearable="false"
          :state="errors.length > 0 ? false:null"
          @search="loadThreads"
        >
          <template #no-options="{ search }">
            <span v-if="search.length">
              {{ $t('NoData') }}
            </span>
            <span v-else>
              {{ $t('TypeToSearch') }}
            </span>
          </template>

          <template #option="{ name, contact, offersTotal }">
            <div class="d-flex align-items-center">
              <avatar
                :user="contact"
                class="mr-25"
              />  - {{ name }}
              <feather-icon
                v-if="offersTotal >= system.contactThreadOffersLimit"
                v-b-tooltip
                icon="AlertCircleIcon"
                class="ml-50"
                :title="$tc('ThreadOfferCountIsToBig', system.contactThreadOffersLimit, { limit: system.contactThreadOffersLimit })"
              />
            </div>
          </template>

          <template #selected-option="{ name, contact }">
            <div class="d-flex">
              <avatar
                :user="contact"
                class="mr-25"
              /> – {{ name }}
            </div>
          </template>
        </v-select>
      </sw-select>

      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>

    <!--      Type      -->
    <validation-provider
      v-slot="{ errors }"
      rules="required"
      :name="$t('ChooseType')"
    >
      <sw-select
        :name="$t('ChooseType')"
      >
        <v-select
          v-model="newSchedule.type"
          :options="paymentTypeList"
          :clearable="false"
          :state="errors.length > 0 ? false:null"
        >
          <template #no-options>
            {{ $t('NoOptions') }}
          </template>

          <template #option="{ label }">
            <span>{{ $t(paymentTypes[label]) }}</span>
          </template>

          <template #selected-option="{ label }">
            <span>{{ $t(paymentTypes[label]) }}</span>
          </template>
        </v-select>
      </sw-select>

      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>

    <!--      Price      -->
    <b-form-group :label="$t(newSchedule.type !== 'LOYALTY_POINTS' ? 'Offer.Price' : 'LoyaltyPoints')">
      <b-input-group>
        <cleave
          v-model="newSchedule.value"
          :placeholder="$t(newSchedule.type !== 'LOYALTY_POINTS' ? 'Offer.Price' : 'LoyaltyPoints')"
          :options="{
            delimiter: ' ',
            numeral: true,
            numeralThousandsGroupStyle: 'thousand',
          }"
          class="form-control"
        />

        <b-input-group-append v-if="newSchedule.type !== 'LOYALTY_POINTS'">
          <b-button
            variant="outline-primary"
            disabled
          >
            PLN
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>

    <!--      Date      -->
    <b-form-group :label="$t('PaymentDate')">
      <validation-provider
        v-slot="{ errors }"
        rules="required"
        :name="$t('PaymentDate')"
      >
        <flat-pickr
          v-model="newSchedule.deadlineDate"
          class="form-control"
          :config="{ locale: getCalendarLocale($i18n.locale) }"
          :state="errors.length > 0 ? false:null"
        />

        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>

    <b-form-group :label="$t('Note')">
      <validation-provider
        v-slot="{ errors }"
        :name="$t('Note')"
      >
        <b-form-textarea v-model="newSchedule.note"
                         rows="4"
        />

        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>

    <!--      Save      -->
    <b-button
      type="button"
      variant="primary"
      :disabled="invalid"
      size="sm"
      @click="saveSchedule"
    >
      {{ $t('Save') }}
    </b-button>
  </validation-observer>
</template>

<script>
import { ADD_PAYMENT, CLOSE_MODAL, RELOAD_CONTENT } from '@/@constants/mutations'
import { BInputGroupAppend } from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { THREADS_SEARCH } from '@/@constants/fields'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import Cleave from 'vue-cleave-component'
import { Polish as pl } from '@/libs/i18n/locales/flatpickr/pl'
import { english as en } from '@/libs/i18n/locales/flatpickr/en'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    vSelect,
    Cleave,
    flatPickr,
  },
  data: () => ({
    paymentTypes: {
      ADVANCE: 'AdvancePayment',
      INSTALLMENT: 'Installment',
      BANK_INSTALLMENT: 'Bank installment',
      TRANSFER: 'Przelew',
      CASH: 'Cash',
      LOYALTY_POINTS: 'LoyaltyPoints',
      TOTAL_PAYMENT: 'TotalAmount',
    },
    paymentTypeList: [
      'ADVANCE',
      'INSTALLMENT',
      'BANK_INSTALLMENT',
      'TRANSFER',
      'CASH',
      'LOYALTY_POINTS',
      'TOTAL_PAYMENT',
    ],

    flatPickerConfig: {
      mode: 'range',
      locale: [pl, en],
    },

    newSchedule: {
      payer: '',
      type: '',
      note: '',
      value: 0,
      deadlineDate: '',
    },
    contactThreadList: [],
  }),
  computed: {
    ...mapGetters({
      system: 'system/getSettings',
      modalConfig: 'modal/getModalState',
      thread: 'singleContact/getSelectedThread',
    }),
  },
  mounted() { this.ini() },
  methods: {
    ini() {
      const { contactId } = this.modalConfig

      if (this.thread) {
        this.newSchedule.payer = { ...this.thread }
      } else if (contactId) this.loadThread(contactId)
      else {
        this.newSchedule.payer = null
      }
    },

    clearNewSchedule() {
      this.newSchedule = {
        payer: '',
        type: '',
        value: 0,
        note: '',
        deadlineDate: '',
      }
    },

    getNewSchedulePayload() {
      const payload = JSON.parse(JSON.stringify(this.newSchedule))

      const thread = payload.payer.id
      const contact = payload.payer.contact.id

      payload.value = parseFloat(`${payload.value || 0}`)
      payload.payer = contact
      payload.contactThread = thread
      payload.status = 'NEW'
      payload.part = 0
      payload.note = String(payload.note) || null

      return { payload, contact, thread }
    },

    saveSchedule() {
      const { payload, contact, thread } = this.getNewSchedulePayload()

      if (payload) {
        this.$store.dispatch(`payments/${ADD_PAYMENT}`, { payload, contact, thread })
          .then(res => {
            this.$store.commit(`modal/${RELOAD_CONTENT}`, res)

            this.$nextTick(() => this.close())
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          })
          .finally(() => this.clearNewSchedule())
      }
    },

    close() { this.$store.commit(`modal/${CLOSE_MODAL}`) },

    async loadThreads(search, isLoading) {
      this.contactThreadList = await this.selectSearch(search, isLoading, THREADS_SEARCH, 'name,contact-firstName,contact-lastName', '1/contacts/threads')
    },

    async loadThread(id, isLoading = () => {}) {
      const contact = await this.selectSearch(id, isLoading, THREADS_SEARCH, 'contact-id', '1/contacts/threads')

      // eslint-disable-next-line prefer-destructuring
      this.newSchedule.payer = contact[0]
    },
  },
}
</script>
