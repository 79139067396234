import {
  CREATE_NEW_THREAD,
  CREATE_POLL,
  DELETE_CONTACT_NOTE,
  DELETE_CONTACT_TASK,
  DELETE_POLL,
  DELETE_THREAD,
  GET_CONTACT_CHECKLIST,
  GET_CONTACT_DOCUMENTS_FOLDER,
  GET_CONTACT_OFFERS,
  GET_CONTACT_SINGLE_TASK,
  GET_CONTACT_SINGLE_THREAD,
  GET_CONTACT_TASKS,
  GET_CONTACT_THREADS,
  GET_CONTACT_TIMELINE,
  GET_EDITED_CONTACT,
  GET_POLLS,
  GET_SINGLE_CONTACT,
  SAVE_POLL_OPTION,
  TEST_SINGLE_CONTACT,
  UPDATE_CONTACT_TASK,
  UPDATE_CONTACT_THREAD,
} from '@/@constants/mutations'
// eslint-disable-next-line import/no-cycle
import axiosIns from '@/libs/axios'
import * as fields from '@/@constants/fields'
import moment from 'moment'

const user = {}

export default {
  namespaced: true,
  state: {
    reloadThread: {
      flag: false,
      threadId: null,
      contactId: null,
    },
    contact: user,
    contactThreads: [],
    selectedThread: null,
    timeline: null,
    checklist: null,
    // Tasks
    tasks: null,
    tasksFilters: {
      types: [],
      status: 'O',
    },
    tasksLoading: false,
    // Notes
    notes: null,
    notesLoading: false,
    notesFilters: [],
    // Documents
    documents: null,
    openedFolder: null,
    offers: null,
  },
  getters: {
    getContact: state => state.contact,
    getContactThreads: state => state.contactThreads,
    getSelectedThread: state => state.selectedThread,
    getContactChecklist: state => state.checklist,
    // Tasks
    getContactTasks: state => state.tasks,
    getContactTasksFilters: state => state.tasksFilters,
    getContactTasksLoading: state => state.tasksLoading,
    // Notes
    getContactNotes: state => state.notes,
    getContactNotesLoading: state => state.notesLoading,
    getContactNoteFilters: state => state.notesFilters,
    //
    getContactDocumentsOpenedFolder: state => state.openedFolder,
    getContactOffers: state => state.offers,
    getReloadThread: state => state.reloadThread,
  },
  mutations: {
    SET_RELOAD_THREAD(state, payload) {
      state.reloadThread = payload
    },
    [GET_SINGLE_CONTACT](state, payload) {
      // eslint-disable-line no-console
      state.contact = payload
    },
    [GET_CONTACT_THREADS](state, payload) {
      state.contactThreads = payload
    },
    [GET_CONTACT_SINGLE_THREAD](state, payload) {
      state.selectedThread = payload
    },
    [GET_CONTACT_TIMELINE](state, payload) {
      alert(`${payload} ${state}`)
    },
    [GET_CONTACT_CHECKLIST](state, payload) {
      state.checklist = payload
    },
    [GET_CONTACT_TASKS](state, payload) {
      state.tasks = payload
    },
    SET_TASKS_FILTERS(state, payload) {
      const {
        field,
        value,
      } = payload
      state.tasksFilters[field] = value
    },
    [GET_CONTACT_DOCUMENTS_FOLDER](state, payload) {
      alert(`${payload} ${state}`)
    },
    [GET_CONTACT_OFFERS](state, payload) {
      alert(`${payload} ${state}`)
    },
  },
  actions: {
    [GET_SINGLE_CONTACT]: ({ commit }, id) => new Promise((resolve, reject) => {
      const params = {
        fields_load: fields.SINGLE_CONTACT,
      }

      axiosIns.get(`1/contacts/${id}`, { params })
        .then(contact => {
          commit(GET_SINGLE_CONTACT, contact.data.data.item)
          resolve()
        })
        .catch(err => {
          commit(GET_SINGLE_CONTACT, user)
          reject(err)
        })
    }),

    [TEST_SINGLE_CONTACT]: (_, level = 0) => new Promise((resolve, reject) => {
      const params = {}
      params.fields_info = level

      axiosIns.get('1/contacts/0', { params })
        .then(res => resolve(res.data.data))
        .catch(err => reject(err))
    }),

    // eslint-disable-next-line no-unused-vars
    [GET_EDITED_CONTACT]: ({ commit }, id) => new Promise((resolve, reject) => {
      const params = {
        fields_load: fields.SINGLE_CONTACT,
      }

      axiosIns.get(`1/contacts/${id}`, { params })
        .then(res => {
          resolve(res.data.data.item)
        })
        .catch(err => {
          reject(err)
        })
    }),
    [GET_CONTACT_THREADS]: ({ commit }, contactId) => new Promise((resolve, reject) => {
      axiosIns.get(`1/contacts/${contactId}/threads`, { params: { fields_load: 'BASE_CONTACT_THREAD' } })
        .then(res => {
          commit(GET_CONTACT_THREADS, res.data.data.items)
          resolve(res.data.data.items)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [GET_CONTACT_SINGLE_THREAD]: ({ commit, dispatch }, threadId) => new Promise((resolve, reject) => {
      if (!threadId) {
        commit(GET_CONTACT_SINGLE_THREAD, null)
        resolve()
        return
      }

      if (!threadId.skip) commit(GET_CONTACT_SINGLE_THREAD, null)

      axiosIns.get(`1/contacts/threads/${threadId?.id || threadId}`, { params: { fields_load: fields.CONTACT_SINGLE_THREAD } })
        .then(({ data }) => data.data)
        .then(async ({ item }) => {
          // eslint-disable-next-line no-underscore-dangle
          let _item = { ...item }
          if (item.contactThreadFunnelProcessSelected) {
            _item = {
              ..._item,
              ...item?.contactThreadFunnelProcessSelected,
              openedId: item.contactThreadFunnelProcessSelected?.id,
              id: _item.id,
            }
          } else if (item.contactThreadFunnelProcessLast) {
            _item = {
              ..._item,
              ...item?.contactThreadFunnelProcessLast,
              openedId: item.contactThreadFunnelProcessLast?.id,
              id: _item.id,
            }
          }
          commit(GET_CONTACT_SINGLE_THREAD, _item)

          resolve()
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [DELETE_THREAD]: ({ commit }, payload) => new Promise((resolve, reject) => {
      axiosIns.delete(`1/contacts/${payload.contactId}/threads`, { data: [payload.threadId] })
        .then(() => {
          commit(GET_CONTACT_SINGLE_THREAD, null)
          resolve()
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [CREATE_NEW_THREAD]: ({ dispatch, commit, rootGetters }, payload) => new Promise((resolve, reject) => {
      const {
        predictedValueCurrency,
        contactId,
        threadName,
        assignedUsers,
        id,
        isPrimary,
        threadFunnelPoint,
        checklistList,
        contactThreadAssignedContacts,
        predictedValue,
        assignedLeadUser,
      } = payload

      const thread = {
        name: threadName,
        predictedValue: parseFloat(predictedValue),
        predictedValueCurrency: predictedValueCurrency || 'PLN',
        assignedLeadUser,
        assignedUsers,
        isPrimary,
        contactThreadAssignedContacts,
        contact: contactId,
      }

      thread.contactThreadAssignedContacts = contactThreadAssignedContacts.map(contactThreadAssignedContact => {
        // eslint-disable-next-line no-underscore-dangle
        const _contactThreadAssignedContact = { ...contactThreadAssignedContact }

        if (!_contactThreadAssignedContact?.id) delete _contactThreadAssignedContact.id
        if (!_contactThreadAssignedContact?.contactType) delete _contactThreadAssignedContact.contactType

        return _contactThreadAssignedContact
      })

      if (Array.isArray(checklistList) && checklistList.length) {
        thread.contactThreadChecklistTypes = checklistList
      }

      if (!threadFunnelPoint || threadFunnelPoint.length === 0) thread.contactThreadFunnelTypePoint = null

      if (id) {
        thread.id = id.toString()
        delete thread.contactThreadFunnelTypePoint
        delete thread.contactThreadFunnelProcesses

        axiosIns.patch(`1/contacts/${contactId}/threads`, thread)
          .then(res => {
            dispatch(GET_CONTACT_SINGLE_THREAD, res.data.data.items[0].id)
            dispatch(GET_CONTACT_THREADS, contactId)
            commit('SET_RELOAD_THREAD', { flag: true, threadId: res.data.data.items[0].id, contactId })
            resolve(res.data.data.items[0].id)
          })
          .catch(err => {
            reject(err)
          })
          .finally(() => {
            commit('SET_RELOAD_THREAD', { flag: false, threadId: null, contactId: null })
          })
      } else {
        axiosIns.post(`1/contacts/${contactId}/threads`, thread)
          .then(async res => {
            if (threadFunnelPoint) {
              await axiosIns.post(`1/contacts/threads/${res.data.data.items[0].id}/funnelProcesses`, {
                contactThreadFunnelTypePoint: typeof threadFunnelPoint === 'object' ? threadFunnelPoint.id.toString() : threadFunnelPoint.toString(),
                status: 'OPEN',
              })
            }

            dispatch(GET_CONTACT_SINGLE_THREAD, res.data.data.items[0].id)
            dispatch(GET_CONTACT_THREADS, contactId)
            commit('SET_RELOAD_THREAD', { flag: true, threadId: res.data.data.items[0].id, contactId })
            resolve(res.data.data.items[0].id)
          })
          .catch(err => {
            reject(err)
          })
          .finally(() => {
            commit('SET_RELOAD_THREAD', { flag: false, threadId: null, contactId: null })
          })
      }
    }),
    /* eslint-disable */
    [UPDATE_CONTACT_THREAD]: ({ dispatch }, payload) => new Promise((resolve, reject) => {
      const { contactId, forceOld, data } = payload

      const toSend = { ...data, id: data.openedId }
      delete toSend.openedId

      axiosIns.patch(`1/contacts/threads/${data.id}/funnelProcesses`, toSend)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [GET_CONTACT_TIMELINE]: ({ commit }, payload) => new Promise(async (resolve, reject) => {
      const {
        contactId, threadId, page, limit, types,
      } = payload

      const params = {
        limit,
        page,
        orderBy: 'createdAt.desc',
      }

      if (types && types.length) {
        params.eqArr_type = JSON.stringify(types)
      }

      params.fields_load = fields.GLOBAL_TIMELINE

      axiosIns.get(`1/contacts/${contactId}/threads/${threadId}/timeline`, {params})
          .then(res => {
            resolve({
              totalItemCount: res.data.data.totalItemCount,
              items: res.data.data.items,
            })
          })
          .catch(err => {
            reject(err)
          })
    }),
    // Tasks
    // eslint-disable-next-line no-unused-vars
    [UPDATE_CONTACT_TASK]: ({ state }, payload) => new Promise((resolve, reject) => {
      const { updateTaskData, threadId } = payload
      axiosIns.patch(threadId ? `1/contacts/threads/${threadId}/tasks/changeStatus` : `1/contacts/threads/tasks/changeStatus`, updateTaskData)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [DELETE_CONTACT_TASK]: ({ state }, payload) => new Promise((resolve, reject) => {
      const { taskId, threadId } = payload
      axiosIns.delete(threadId ? `1/contacts/threads/${threadId}/tasks` : `1/contacts/threads/tasks`, { data: [taskId.toString()] })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    }),
    [GET_CONTACT_TASKS]: ({ commit, state }, payload) => new Promise((resolve, reject) => {
      state.tasksLoading = true
      const { threadId } = payload
      const params = {}

      if (state.tasksFilters.status) {
        params.eq_status = state.tasksFilters.status
      }

      if (state.tasksFilters && state.tasksFilters.types.length) {
        params.eqArr_contactThreadTaskType = JSON.stringify(state.tasksFilters.types)
      }

      params.orderBy = 'startAt.asc'
      params.fields_load = fields.TASKS

      axiosIns.get(`1/contacts/threads/${threadId}/tasks`, { params })
        .then(res => {
          commit(GET_CONTACT_TASKS, res.data.data.items)
          setTimeout(() => {
            state.tasksLoading = false
          }, 250)
          resolve(res.data.data.totalItemCount)
        })
        .catch(err => {
          setTimeout(() => {
            state.tasksLoading = false
            reject(err)
          }, 250)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [GET_CONTACT_SINGLE_TASK]: ({ commit }, payload) => new Promise((resolve, reject) => {
      const { threadId, itemId } = payload
      const params = {}
      // eslint-disable-next-line camelcase
      params.fields_load = fields.TASKS
      axiosIns(`1/contacts/threads/${threadId}/tasks/${itemId}`, { params })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    [GET_CONTACT_CHECKLIST]: ({ commit, state }, payload) => new Promise((resolve, reject) => {
      const checklist = payload?.thread ? [...payload.thread.contactThreadChecklistTypes.map(type => ({
        id: type.id,
        name: type.name,
        position: type.position || 0,
        list: [],
      }))] : [...state.selectedThread.contactThreadChecklistTypes.map(type => ({
        id: type.id,
        name: type.name,
        position: type.position || 0,
        list: [],
      }))];

      (payload.thread?.contactThreadChecklistTypes || state.selectedThread?.contactThreadChecklistTypes || []).forEach(type => {
        const checklistId = type.id
        const threadId = state.selectedThread?.id || payload.thread?.id
        const { contactId } = payload
        const params = {
          fields_load: fields.CHECKLIST_POINT_DATUM,
        }

        axiosIns.get(`1/contacts/${contactId}/threads/${threadId}/checklistType/${checklistId}/pointDatum`, { params })
          .then(res => {
            type.contactThreadChecklistTypePoints.forEach(point => {
              // eslint-disable-next-line no-underscore-dangle
              const _point = { ...point }
              const datumItem = res.data.data.items.find(item => item.contactThreadChecklistTypePoint.id === point.id)
              if (datumItem) {
                _point.datumId = datumItem.id
                _point.checked = datumItem.checked
                _point.updatedBy = datumItem.updatedBy
                // eslint-disable-next-line prefer-destructuring
                _point.doneAt = datumItem.doneAt ? datumItem.doneAt.date.split('.')[0] : moment().format('YYYY-MM-DD hh:mm:ss')
              } else {
                _point.datumId = null
                _point.checked = false
                _point.doneAt = moment().format('YYYY-MM-DD HH:mm:ss')
              }

              checklist.find(el => el.id === type.id).list.push(_point)
            })
          })
          .catch(err => {
            reject(err)
          })
      })

      commit(GET_CONTACT_CHECKLIST, checklist)
      resolve(checklist)
    }),
    // eslint-disable-next-line no-unused-vars
    [DELETE_CONTACT_NOTE]: ({ state }, payload) => new Promise((resolve, reject) => {
      const { noteId, threadId } = payload

      axiosIns.delete(`1/contacts/threads/${threadId}/notes`, { data: [noteId.toString()] })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    }),
    [GET_CONTACT_DOCUMENTS_FOLDER]: ({ commit }) => new Promise(() => {
      commit(GET_CONTACT_DOCUMENTS_FOLDER)
    }),
    [GET_CONTACT_OFFERS]: ({ commit }) => new Promise((() => {
      commit(GET_CONTACT_OFFERS)
    })),
    // POLLS
    // eslint-disable-next-line no-unused-vars
    [GET_POLLS]: ({ commit }, { contactId, threadId }) => new Promise((resolve, reject) => {
      const params = {
        fields_load: fields.POLLS
      }
      axiosIns.get(`1/contacts/${contactId}/threads/${threadId}/polls`, { params })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [CREATE_POLL]: ({ commit }, { contactId, threadId, pollId }) => new Promise((resolve, reject) => {
      axiosIns.post(`1/contacts/${contactId}/threads/${threadId}/polls`, { poll: pollId })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [DELETE_POLL]: ({ commit }, { contactId, threadId, pollId }) => new Promise((resolve, reject) => {
      axiosIns.delete(`1/contacts/${contactId}/threads/${threadId}/polls`, { data: [pollId] })
        .then(() => {
          resolve()
        })
        .catch(err => {
          reject(err)
        })
    }),
    /* eslint-disable */
    [SAVE_POLL_OPTION]: ({ commit }, {
      contactId, threadId, pollId, options,
    }) => new Promise((resolve, reject) => {
      options.forEach(option => {
        delete option.updatedAt
        delete option.createdAt
        delete option.deletedAt
        delete option.updatedBy
        delete option.createdBy
        delete option.deletedBy
      })
      axiosIns.put(`1/contacts/${contactId}/threads/${threadId}/polls/${pollId}/fields`, options)
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
  },
}
