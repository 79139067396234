import * as ranks from '@/helpers/permissions'
import {
  SHOW_SYSTEM_MENU_AGREEMENTS,
  SHOW_SYSTEM_MENU_APPLICATIONS,
  SHOW_SYSTEM_MENU_CHAT,
  SHOW_SYSTEM_MENU_CONTACTS,
  SHOW_SYSTEM_MENU_DASHBOARD,
  SHOW_SYSTEM_MENU_FUNNELS,
  SHOW_SYSTEM_MENU_LOYALTY_PLANS,
  SHOW_SYSTEM_MENU_MARKETING_PLANS,
  SHOW_SYSTEM_MENU_RANKING,
  SHOW_SYSTEM_MENU_MATERIALS,
  SHOW_SYSTEM_MENU_OFFERS,
  SHOW_SYSTEM_MENU_ORDERS,
  SHOW_SYSTEM_MENU_ORGANIZATIONS,
  SHOW_SYSTEM_MENU_PAYMENTS,
  SHOW_SYSTEM_MENU_PROJECTS,
  SHOW_SYSTEM_MENU_SETTINGS,
  SHOW_SYSTEM_MENU_SHOP,
  SHOW_SYSTEM_MENU_TASKS,
  SHOW_SYSTEM_MENU_TIMELINE,
  SHOW_SYSTEM_MENU_USERS,
} from '@/helpers/viewPermissions'
import { PHONE_CALLS_VIEW_ALL } from '@/helpers/permissions'

export default [
  // Dashboard
  {
    position: 1,
    hide: false,
    key: 'dashboard',
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'HomeIcon',
    viewPermissions: [
      [SHOW_SYSTEM_MENU_DASHBOARD],
    ],
  },
  {
    position: 2,
    hide: false,
    key: 'applications',
    title: 'Applications',
    route: 'applications',
    icon: 'ArchiveIcon',
    requiredPermissions: [
      [ranks.APPLICATION_VIEW_ALL, ranks.APPLICATION_VIEW_ASSIGNED_CONTACT_THREAD],
      [ranks.CONTACT_ADD],
      [ranks.CONTACT_THREAD_ADD],
    ],
    requiredModule: ['applicationModule'],
    viewPermissions: [
      [SHOW_SYSTEM_MENU_APPLICATIONS],
    ],
  },
  // Tasks
  {
    position: 3,
    hide: false,
    key: 'tasks',
    title: 'Tasks',
    route: 'tasks',
    icon: 'ListIcon',
    requiredPermissions: [
      [ranks.CONTACT_VIEW_ALL, ranks.CONTACT_VIEW_ASSIGNED, ranks.CONTACT_VIEW_STRUCTURE, ranks.CONTACT_VIEW_STRUCTURE_FIRST],
      [ranks.CONTACT_THREAD_VIEW_ALL, ranks.CONTACT_THREAD_VIEW_ASSIGNED, ranks.CONTACT_THREAD_VIEW_STRUCTURE, ranks.CONTACT_THREAD_VIEW_STRUCTURE_FIRST],
      [ranks.CONTACT_THREAD_TASK_VIEW_ALL, ranks.CONTACT_THREAD_TASK_VIEW_ASSIGNED, ranks.CONTACT_THREAD_TASK_VIEW_STRUCTURE],
    ],
    requiredModule: [],
    viewPermissions: [
      [SHOW_SYSTEM_MENU_TASKS],
    ],
  },
  // Contacts
  {
    position: 4,
    hide: false,
    key: 'contacts',
    title: 'Contacts',
    route: 'contacts',
    icon: 'UsersIcon',
    requiredPermissions: [[ranks.CONTACT_VIEW_ALL, ranks.CONTACT_VIEW_ASSIGNED, ranks.CONTACT_VIEW_STRUCTURE, ranks.CONTACT_VIEW_STRUCTURE_FIRST]],
    requiredModule: [],
    viewPermissions: [
      [SHOW_SYSTEM_MENU_CONTACTS],
    ],
  },
  {
    position: 5,
    hide: false,
    key: 'complaints',
    title: 'Complaints',
    icon: 'FrownIcon',
    route: 'complaints',
    requiredModule: ['complaintModule'],
    requiredPermissions: [[
      ranks.CONTACT_THREAD_COMPLAINT_VIEW_ALL,
      ranks.CONTACT_THREAD_ASSIGNED_COMPLAINT_VIEW_ALL,
      ranks.CONTACT_THREAD_ASSIGNED_COMPLAINT_VIEW_SELF,
    ]],
  },
  {
    position: 6,
    hide: false,
    key: 'clients',
    title: 'MobileClients',
    route: 'clients',
    icon: 'SmartphoneIcon',
    requiredPermissions: [[ranks.CLIENT_VIEW_ALL]],
    requiredModule: ['clientMobileModule'],
    viewPermissions: [],
  },
  {
    position: 7,
    hide: false,
    key: 'yourFunnels',
    title: 'YourFunnels',
    route: 'sales-funnel',
    icon: 'FilterIcon',
    requiredPermissions: [
      [ranks.CONTACT_VIEW_ALL, ranks.CONTACT_VIEW_ASSIGNED, ranks.CONTACT_VIEW_STRUCTURE, ranks.CONTACT_VIEW_STRUCTURE_FIRST],
      [ranks.CONTACT_THREAD_VIEW_ALL, ranks.CONTACT_THREAD_VIEW_ASSIGNED, ranks.CONTACT_THREAD_VIEW_STRUCTURE, ranks.CONTACT_THREAD_VIEW_STRUCTURE_FIRST],
    ],
    requiredModule: [],
    and: true,
    viewPermissions: [
      [SHOW_SYSTEM_MENU_FUNNELS],
    ],
  },
  // Campaigns
  {
    position: null,
    key: 'le',
    title: 'Campaigns',
    icon: 'SendIcon',
    requiredModule: ['campaignModule'],
    route: 'campaigns',
    // children: [
    //   {
    //     position: null,
    //     key: ' title',
    //     title: 'Sms',
    //     route: 'sms',
    //     requiredModule: [],
    //   },
    //   {
    //     position: null,
    //     key: ' title',
    //     title: 'Mailing',
    //     route: 'mailing',
    //     requiredModule: [],
    //   },
    // ],
  },
  // Reports
  {
    position: 8,
    hide: true,
    key: 'reports',
    title: 'Reports1',
    icon: 'PieChartIcon',
    route: 'reports',
    requiredPermissions: [[ranks.REPORT_VIEW_ALL]],
  },
  // Organizations
  {
    position: 9,
    hide: false,
    key: 'organizations',
    title: 'Organizations',
    route: 'organizations',
    icon: 'GlobeIcon',
    requiredPermissions: [[ranks.ORGANIZATION_VIEW_ALL]],
    viewPermissions: [
      [SHOW_SYSTEM_MENU_ORGANIZATIONS],
    ],
  },
  // Timeline
  {
    position: 10,
    hide: false,
    key: 'timeline',
    title: 'contactTabs.Timeline',
    route: 'timeline',
    icon: 'GitPullRequestIcon',
    requiredPermissions: [[ranks.CONTACT_THREAD_TIMELINE_VIEW_ALL]],
    viewPermissions: [
      [SHOW_SYSTEM_MENU_TIMELINE],
    ],
  },
  {
    position: 11,
    hide: false,
    key: 'offerList',
    title: 'OfferList',
    icon: 'hi-badge-check',
    // icon: 'bi-patch-check',
    requiredModule: ['offerModule'],
    route: 'offr/offerList',
    requiredPermissions: [[ranks.CONTACT_THREAD_VIEW_ALL, ranks.CONTACT_THREAD_VIEW_ASSIGNED, ranks.CONTACT_THREAD_VIEW_STRUCTURE, ranks.CONTACT_THREAD_VIEW_STRUCTURE_FIRST], [ranks.CONTACT_THREAD_OFFER_VIEW_ALL, ranks.CONTACT_THREAD_ASSIGNED_OFFER_VIEW_ALL, ranks.CONTACT_THREAD_ASSIGNED_OFFER_VIEW_SELF]],
    viewPermissions: [
      [SHOW_SYSTEM_MENU_OFFERS],
    ],
  },
  {
    position: 12,
    hide: false,
    key: 'agreements',
    title: 'AgreementsCount',
    route: 'agreements',
    icon: 'hi-document-text',
    requiredModule: ['agreementModule'],
    requiredPermissions: [[ranks.CONTACT_THREAD_VIEW_ALL, ranks.CONTACT_THREAD_VIEW_ASSIGNED, ranks.CONTACT_THREAD_VIEW_STRUCTURE, ranks.CONTACT_THREAD_VIEW_STRUCTURE_FIRST], [ranks.CONTACT_THREAD_AGREEMENT_VIEW_ALL, ranks.CONTACT_THREAD_ASSIGNED_AGREEMENT_VIEW_ALL, ranks.CONTACT_THREAD_ASSIGNED_AGREEMENT_VIEW_SELF]],
    viewPermissions: [
      [SHOW_SYSTEM_MENU_AGREEMENTS],
    ],
  },
  {
    position: 13,
    hide: false,
    key: 'orders',
    title: 'Orders',
    route: 'orders',
    icon: 'ShoppingBagIcon',
    requiredPermissions: [[ranks.CONTACT_THREAD_VIEW_ALL, ranks.CONTACT_THREAD_VIEW_ASSIGNED, ranks.CONTACT_THREAD_VIEW_STRUCTURE, ranks.CONTACT_THREAD_VIEW_STRUCTURE_FIRST], [ranks.CONTACT_THREAD_ASSIGNED_ORDER_VIEW_ALL, ranks.CONTACT_THREAD_ASSIGNED_ORDER_VIEW_SELF, ranks.CONTACT_THREAD_ORDER_VIEW_ALL]],
    viewPermissions: [
      [SHOW_SYSTEM_MENU_ORDERS],
    ],
    requiredModule: ['orderModule'],
  },
  // Build
  {
    position: 14,
    hide: false,
    key: 'schedules',
    title: 'contactTabs.Payments',
    route: 'schedules',
    icon: 'DollarSignIcon',
    requiredModule: ['paymentModule'],
    requiredPermissions: [[ranks.CONTACT_THREAD_VIEW_ALL, ranks.CONTACT_THREAD_VIEW_ASSIGNED, ranks.CONTACT_THREAD_VIEW_STRUCTURE, ranks.CONTACT_THREAD_VIEW_STRUCTURE_FIRST], [ranks.CONTACT_THREAD_PAYMENT_VIEW_ALL, ranks.CONTACT_THREAD_ASSIGNED_PAYMENT_VIEW_ALL, ranks.CONTACT_THREAD_ASSIGNED_PAYMENT_VIEW_SELF]],
    viewPermissions: [
      [SHOW_SYSTEM_MENU_PAYMENTS],
    ],
  },
  {
    position: 15,
    hide: true,
    key: 'structure',
    title: 'Structure',
    route: 'structure',
    icon: 'LayersIcon',
    requiredPermissions: [
      [
        ranks.USER_STRUCTURE_VIEW_ALL,
        ranks.USER_STRUCTURE_VIEW_STRUCTURE,
        ranks.USER_STRUCTURE_VIEW_STRUCTURE_FIRST,
      ],
    ],
    viewPermissions: [],
    requiredModule: ['orderModule'],
  },
  {
    position: 16,
    hide: false,
    key: 'ranking',
    title: 'Ranking',
    route: 'ranking',
    icon: 'BarChartIcon',
    requiredPermissions: [
      [ranks.USER_RANKING_MARKETING_VIEW_ALL, ranks.USER_RANKING_LOYALTY_VIEW_ALL],
    ],
    viewPermissions: [
      [SHOW_SYSTEM_MENU_RANKING],
    ],
    requiredModule: ['marketingModule'],
  },
  {
    position: 17,
    hide: true,
    key: 'marketingPlan',
    title: 'MarketingPlan',
    icon: 'HexagonIcon',
    route: 'marketingPlansPlans',
    viewPermissions: [
      [SHOW_SYSTEM_MENU_MARKETING_PLANS],
    ],
    requiredPermissions: [[ranks.MARKETING_PLAN_VIEW_ALL]],
    requiredModule: ['marketingModule'],
  },
  {
    position: 18,
    hide: true,
    key: 'marketingPlansCommissions',
    title: 'Commissions',
    icon: 'bi-cash-coin',
    route: 'marketingPlansCommissions',
    viewPermissions: [
      [SHOW_SYSTEM_MENU_MARKETING_PLANS],
    ],
    requiredPermissions: [[ranks.MARKETING_PLAN_POINT_VIEW_ALL, ranks.MARKETING_PLAN_POINT_VIEW_SELF, ranks.MARKETING_PLAN_POINT_VIEW_STRUCTURE]],
    requiredModule: ['marketingModule'],
  },
  {
    position: 19,
    hide: true,
    key: 'loyaltyPlan',
    title: 'LoyaltyPlan',
    icon: 'StarIcon',
    route: 'loyaltyPlansLoyaltyPlans',
    viewPermissions: [
      [SHOW_SYSTEM_MENU_LOYALTY_PLANS],
    ],
    requiredPermissions: [[ranks.LOYALTY_PLAN_VIEW_ALL]],
    requiredModule: ['loyaltyModule'],
  },
  // {
  //   position: null,
  //   hide: false,
  //   key: 'construction',
  //   title: 'Construction',
  //   icon: 'LayersIcon',
  //   requiredModule: ['buildingModule'],
  // },
  {
    position: 20,
    hide: true,
    key: 'loyaltyPlansLoyaltyPoints',
    title: 'LoyaltyPoints',
    icon: 'hi-sparkles',
    route: 'loyaltyPlansLoyaltyPoints',
    viewPermissions: [
      [SHOW_SYSTEM_MENU_LOYALTY_PLANS],
    ],
    requiredPermissions: [[
      // ranks.USER_LOYALTY_PLAN_VIEW_ALL,
      // ranks.USER_LOYALTY_PLAN_VIEW_SELF,
      // ranks.USER_LOYALTY_PLAN_VIEW_STRUCTURE,
      // ranks.USER_LOYALTY_PLAN_VIEW_STRUCTURE_FIRST,
      ranks.LOYALTY_PLAN_POINT_VIEW_ALL,
      ranks.LOYALTY_PLAN_POINT_VIEW_SELF,
      ranks.LOYALTY_PLAN_POINT_VIEW_STRUCTURE,
      ranks.LOYALTY_PLAN_POINT_VIEW_STRUCTURE_FIRST,
    ]],
    requiredModule: ['loyaltyModule'],
  },
  {
    position: 21,
    hide: false,
    key: 'shop',
    title: 'ranks.sections.investment',
    route: 'shop',
    icon: 'ShoppingCartIcon',
    requiredModule: ['offerModule'],
    requiredPermissions: [[ranks.SHOP_PRODUCT_VIEW_ALL, ranks.SHOP_PRODUCT_VIEW_GROUP, ranks.SHOP_CATEGORY_MODIFY, ranks.SHOP_ATTRIBUTE_MODIFY]],
    viewPermissions: [
      [SHOW_SYSTEM_MENU_SHOP],
    ],
  },
  {
    position: 22,
    hide: true,
    key: 'projects',
    title: 'Projects',
    icon: 'BookmarkIcon',
    route: 'projectsPanel',
    viewPermissions: [
      [SHOW_SYSTEM_MENU_PROJECTS],
    ],
    requiredPermissions: [[ranks.PROJECT_VIEW_ALL]],
    requiredModule: ['projectModule'],
  },
  {
    position: 23,
    hide: true,
    key: 'news',
    title: 'News',
    icon: 'GridIcon',
    route: 'newsList',
    requiredPermissions: [
      [ranks.NEWS_POST_VIEW_ALL],
    ],
    requiredModule: ['newsModule'],
  },
  {
    position: 24,
    hide: true,
    key: 'globalEmails',
    title: 'GlobalEmails',
    route: 'emails',
    icon: 'MailIcon',
    requiredModule: ['mailboxModule'],
    viewPermissions: [],
  },
  {
    position: 25,
    hide: false,
    key: 'chat',
    title: 'Chat',
    route: 'chat',
    icon: 'MessageCircleIcon',
    requiredPermissions: [
      [ranks.CHAT_VIEW_ALL, ranks.CHAT_VIEW_RANK],
    ],
    viewPermissions: [
      [SHOW_SYSTEM_MENU_CHAT],
    ],
  },
  {
    position: 26,
    hide: false,
    key: 'client-chat',
    title: 'ClientChat',
    route: 'client-chat',
    icon: 'MessageSquareIcon',
    requiredPermissions: [[
      ranks.CLIENT_CHAT_MOBILE_VIEW_ALL,
      ranks.CLIENT_CHAT_WHATSAPP_VIEW_ALL,
      ranks.CLIENT_CHAT_MESSENGER_VIEW_ALL,
      ranks.CLIENT_CHAT_MOBILE_VIEW_ASSIGNED_CONTACT_THREAD,
      ranks.CLIENT_CHAT_WHATSAPP_VIEW_ASSIGNED_CONTACT_THREAD,
      ranks.CLIENT_CHAT_MESSENGER_VIEW_ASSIGNED_CONTACT_THREAD,
    ]],
    requiredModule: ['clientMobileModule', 'whatsappModule', 'clientBrowserModule'],
    viewPermissions: [],
  },
  {
    position: 27,
    hide: true,
    key: 'documents',
    title: 'Documents',
    route: 'documents',
    icon: 'HardDriveIcon',
    viewPermissions: [
      [SHOW_SYSTEM_MENU_MATERIALS],
    ],
    requiredPermissions: [[
      ranks.DOCUMENT_VIEW_ALL,
      ranks.DOCUMENT_VIEW_SHARED,
    ]],
  },
  {
    position: 28,
    hide: true,
    key: 'videos',
    title: 'Videos',
    route: 'videos',
    icon: 'HardDriveIcon',
    viewPermissions: [
      [SHOW_SYSTEM_MENU_MATERIALS],
    ],
    requiredPermissions: [[
      ranks.DOCUMENT_VIEW_ALL,
      ranks.DOCUMENT_VIEW_SHARED,
    ]],
  },
  {
    position: 29,
    hide: false,
    key: 'users',
    title: 'Users',
    route: 'users',
    icon: 'UsersIcon',
    requiredPermissions: [
      [ranks.USER_VIEW_ALL, ranks.USER_VIEW_STRUCTURE],
    ],
    viewPermissions: [
      [SHOW_SYSTEM_MENU_USERS],
    ],
  },
  {
    position: 30,
    hide: false,
    key: 'onboarding',
    title: 'Onboarding',
    route: 'onboarding',
    icon: 'AwardIcon',
    requiredPermissions: [
      [ranks.ONBOARDING_VIEW_ALL],
    ],
    viewPermissions: [
      [SHOW_SYSTEM_MENU_MATERIALS],
    ],
  },
  {
    position: 31,
    hide: false,
    key: 'trainings',
    title: 'Trainings',
    route: 'trainings',
    icon: 'AirplayIcon',
    requiredPermissions: [
      [ranks.TRAINING_VIEW_ALL],
    ],
    viewPermissions: [
      [SHOW_SYSTEM_MENU_MATERIALS],
    ],
  },
  {
    position: 32,
    hide: false,
    key: 'settings',
    title: 'Settings',
    icon: 'SettingsIcon',
    route: 'settings',
    requiredPermissions: [
      [ranks.SETTINGS],
    ],
    viewPermissions: [
      [SHOW_SYSTEM_MENU_SETTINGS],
    ],
  },
  // Calculators
  {
    position: 33,
    hide: false,
    key: 'calculators',
    title: 'Calculators',
    route: 'calculators',
    icon: 'ColumnsIcon',
    requiredPermissions: [],
    requiredModule: ['calculatorModule'],
    viewPermissions: [],
  },

  {
    position: 34,
    hide: false,
    key: 'calls',
    title: 'Calls',
    route: 'calls',
    icon: 'PhoneIcon',
    requiredPermissions: [[PHONE_CALLS_VIEW_ALL]],
    requiredModule: ['callModule'],
    viewPermissions: [],
  },
]

// Ofert
// - strony www
// - oferty - limit
// - komponenty
// - typy umów
