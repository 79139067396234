import {
  CREATE_CONTACT, EDIT_CONTACT, GET_USER_CONTACTS, REMOVE_CONTACTS,
} from '@/@constants/mutations'
// eslint-disable-next-line import/no-cycle
import axiosIns from '@/libs/axios'

import * as fields from '@/@constants/fields'
import axios from 'axios'

const getCurrentDate = () => {
  const date = new Date()
  const month = (date.getMonth() + 1) < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
  const hour = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
  const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
  const seconds = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds()
  return `${date.getFullYear()}-${month}-${day} ${hour}:${minutes}:${seconds}`
}
/* eslint-disable */
const getAdditionalFieldsFilters = (filters, params) => {
  let count = 0
  let indexes = []
  filters.forEach((filter, index) => {
    index++
    if (['TIME', 'DATE'].includes(filter.type) && filter?.value?.length) {
      const value = filter.value.dateRange()
      if (value.length) {
        params[`eq_${index}_contactAddonFields-contactAddonFieldType-id`] = filter.id
        if (value[0] || value[1]) {
          count++
          indexes.push(index)
        }

        if (value[0]) params[`gte_${index}_contactAddonFields-value`] = value[0]
        if (value[1]) params[`lte_${index}_contactAddonFields-value`] = value[1]
      }
    } else if (['TOOLTIP'].includes(filter.type) && filter.value) {
      params[`eq_${index}_contactAddonFields-contactAddonFieldType-id`] = filter.id
      params[`in_${index}_contactAddonFields-contactAddonFieldTextOptions`] = `%${filter.value}%`
      count++
      indexes.push(index)
    } else if (['PERCENTAGE', 'TEXT', 'METRIC', 'RADIAL'].includes(filter.type)) {
      if (filter.value && filter.value.length) {
        params[`eq_${index}_contactAddonFields-contactAddonFieldType-id`] = filter.id
        params[`like_${index}_contactAddonFields-value`] = `%${filter.value}%`
        count++
        indexes.push(index)
      }
    } else if (filter?.value && ((Array.isArray(filter.value) && filter.value.length) || (typeof filter.value === 'object' && filter.value?.id))) {
      if (['TEXT_ARRAY', 'TEXT_MULTIPLE_ARRAY'].includes(filter.type)) {
        // GET VALUES FROM ARRAY
        const value = Array.isArray(filter.value) ? (filter.value.map(item => (typeof item === 'string' ? item : item.id))) : (typeof filter.value === 'object' ? filter.value.id : filter.value)
        if (value) {
          params[`eq_${index}_contactAddonFields-contactAddonFieldType-id`] = filter.id
          params[`eqArr_${index}_contactAddonFields-contactAddonFieldTypeOptions-id`] = JSON.stringify(Array.isArray(value) ? [...value] : [value])
          if (Array.isArray(value)) count += value.length
          else count++

          indexes.push(index)
        }
      } else if (['DIGITAL'].includes(filter.type)) {
        if (filter.value[0] || filter.value[1]) {
          count++
          indexes.push(index)
        }
        // GET MIN RANGE VAL
        if (filter.value[0]) {
          params[`eq_${index}_contactAddonFields-contactAddonFieldType-id`] = filter.id
          params[`gte_${index}_contactAddonFields-value`] = filter.value[0]
        }
        // GET MAX RANGE VAL
        if (filter.value[1]) {
          params[`eq_${index}_contactAddonFields-contactAddonFieldType-id`] = filter.id
          params[`lte_${index}_contactAddonFields-value`] = filter.value[1]
        }
      }
    }
  })
  return [count, indexes]
}

const assignBaseFilters = (filters, params, indexes = [], search) => {
    indexes.forEach(index => {
      if (search?.length) {
      // const _search = search.replaceAll(' ', '%')
      //   params[`eq_${index}_firstName`]  = `%${_search}%`
      //   params[`eq_${index}_lastName`]  = `%${_search}%`
      //   params[`eq_${index}_mailAddresses-mail`]  = `%${_search}%`
      }

      if (filters.status) {
        params[`eq_${index}_status`] = filters.status
      }
      if (filters.assignedUsers && filters.assignedUsers.length) {
        params[`eqArr_${index}_contactAssignedUsers-user`] = JSON.stringify(
          filters.assignedUsers.map(
            user => (typeof user === 'object' ? user.id : user),
          ),
        )
      }
      if (filters.timeRange) {
        const splitedDate = filters.timeRange.dateRange()
        params[`gte_${index}_createdAt`] = `${splitedDate[0]} 00:00:00`
        if (splitedDate[1]) {
          params[`lte_${index}_createdAt`] = `${splitedDate[1]} 23:59:59`
        }
      }
      if (filters.structureContacts) {
        params['structure'] = true
      }

      if (filters.action) {
        params[`lt_${index}_statusDelayedAt`] = getCurrentDate()
      }

      if (filters.organizations && filters.organizations.length) {
        params[`eq_${index}_organization-id`] = filters.organizations
      }

      if (filters.withOrganization) {
        params[`isNotNull_${index}_organization`] = ''
      }

      if (filters.onlyNoOrganizationContacts) {
        params[`neq_${index}_mainOrganizationContact`] = 1
      }

      if (filters.tags && filters.tags.length) {
        const tags = filters.tags.map(tag => (typeof tag === 'string' ? tag : tag.id))
        if (filters.tagsSum) params[`inD_${index}_contactTypes-id`] = JSON.stringify(tags)
        else params[`eqArr_${index}_contactTypes-id`] = JSON.stringify(tags)
      }
    })
}

let source

export default {
  namespaced: true,
  state: {
    contacts: [],
    totalRecords: 10,
  },
  getters: {
    getContacts: state => state.contacts,
    getTotalRecords: state => state.totalRecords,
  },
  mutations: {
    [GET_USER_CONTACTS](state, payload) {
      state.contacts = payload.contacts
      state.totalRecords = payload.total
    },
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    [CREATE_CONTACT]: ({ commit }, contact) => new Promise((resolve, reject) => {
      axiosIns.post('1/contacts', contact)
        .then(res => {
          const contactId = res.data.data.items[0].id
          resolve(contactId)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [EDIT_CONTACT]: ({ commit }, contact) => new Promise((resolve, reject) => {
      axiosIns.patch('1/contacts', contact)
        // eslint-disable-next-line no-unused-vars
        .then(() => {
          const contactId = contact?.id
          resolve(contactId)
        })
        .catch(err => {
          reject(err)
        })
    }),
    [GET_USER_CONTACTS]: ({ commit }, payload) => new Promise((resolve, reject) => {
      if (typeof source !== typeof undefined) source.cancel('Operation canceled due to new request.')

      if (!payload.skip) source = axios.CancelToken.source()

      let params = {
        orderBy: 'createdAt.desc',
      }

      /* eslint-disable */
      let count = 0

      if (payload.additionalFieldsFilters && payload.additionalFieldsFilters.length) {
        const [count, indexes] = getAdditionalFieldsFilters(payload.additionalFieldsFilters, params, payload.filters)
        if (count) {
          if (payload?.filters) assignBaseFilters(payload.filters, params, indexes, payload.search)
          params.district_count = count
        }
      }

      if (payload.filters && !params.district_count) {
        if (payload.filters.status) {
          count++
          params.eq_status = payload.filters.status
        }
        if (payload.filters.assignedUsers && payload.filters.assignedUsers.length) {
          count++
          params['eqArr_contactAssignedUsers-user'] = JSON.stringify(
            payload.filters.assignedUsers.map(
              user => (typeof user === 'object' ? user.id : user),
            ),
          )
        }
        if (payload.filters.timeRange) {
          count++
          const splitedDate = payload.filters.timeRange.dateRange()
          params.gte_createdAt = `${splitedDate[0]} 00:00:00`
          if (splitedDate[1]) {
            params.lte_createdAt = `${splitedDate[1]} 23:59:59`
          }
        }
        if (payload.filters.structureContacts) {
          count++
          params.structure = true
        }

        if (payload.filters.action) {
          count++
          params.lt_statusDelayedAt = getCurrentDate()
        }

        if (payload.filters.organizations && payload.filters.organizations.length) {
          count++
          params['eq_organization-id'] = payload.filters.organizations
        }

        if (payload.filters.withOrganization) {
          count++
          params.isNotNull_organization = ''
        }

        if (payload.filters.onlyNoOrganizationContacts) {
          count++
          params['neq_mainOrganizationContact'] = 1
        }

        if (payload.filters.tags && payload.filters.tags.length) {
          const tags = payload.filters.tags.map(tag => (typeof tag === 'string' ? tag : tag.id))
          if (payload.filters.tagsSum) {
            params['inD_contactTypes-id'] = JSON.stringify(tags)
          } else {
            params['eqArr_contactTypes-id'] = JSON.stringify(tags)
          }
        }
      }

      if (payload.search && !params.district_count) {
        const search = payload.search.split(' ').map(e => `%${e}%`)
        params = {
          searchArr: JSON.stringify(search),
          search_fields: 'firstName,lastName,mailAddresses-mail,phoneNumbers-phone,addressCity,addressCorrespondenceCity,organization-addressCorrespondenceCity,organization-addressCity,organization-vatId,organization-name',
        }
      }

      if (payload?.sorter) {
        const sortDir = payload?.sorter?.sortDesc ? 'desc' : 'asc'
        const sortBy = payload?.sorter?.sortBy || 'id'
        params.orderBy = `${sortBy}.${sortDir}`
      }

      if (payload.pagination) {
        // eslint-disable-next-line no-shadow
        const { pagination } = payload
        // params.offset = (pagination.currentPage - 1) * pagination.perPage
        params.page = pagination.currentPage
        params.limit = pagination.perPage
      }

      params.fields_load = fields.CONTACTS

      if (payload?.customFields) {
        params.fields_load = payload.customFields
      }

      const config = {
        params,
      }

      if (!payload.skip) {
        config.cancelToken = source.token
      }

      // config.params.neq_createdBy = 1
      // config.params.neq_status = 1002

      axiosIns.get('1/contacts', config)
        .then(res => {
          if (!payload.skip) commit(GET_USER_CONTACTS, { contacts: res.data.data.items, total: res.data.data.totalItemCount })
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    [REMOVE_CONTACTS]: ({ commit }, contacts) => new Promise((resolve, reject) => {
      axiosIns.delete('1/contacts', { data: contacts })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    }),
    // eslint-disable-next-line no-unused-vars
    GET_SELECT_CONTACTS: ({ commit }, { search, skipContacts }) => new Promise((resolve, reject) => {
      // eslint-disable-next-line no-underscore-dangle
      const _search = search.replaceAll(' ', '%')
      const params = {
        search: `%${_search}%`,
        search_fields: 'firstName,lastName,mailAddresses-mail,phoneNumbers-phone',
        fields_load: fields.BASIC_CONTACT,
      }

      if (skipContacts && skipContacts?.length) {
        params.neqArr_id = JSON.stringify(skipContacts || [])
      }

      axiosIns.get('1/contacts', { params })
        .then(res => {
          resolve(res.data.data.items)
        })
        .catch(err => {
          reject(err)
        })
    }),
  },
}
